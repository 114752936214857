import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";

// Customizable Area Start
import { apiCall } from '../../../components/src/ApiCall.web';
import { CourseAnalyticsData, CourseDetail, OverallStats , Meta, CourseData, UserData} from "./types";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentPage:number
  currentPageUserInfo:number
  currentCourseId:number
  courseList: CourseDetail[]
  overallStats: OverallStats
  anchorSearch:HTMLDivElement | null
  anchorFilter:HTMLButtonElement | null
  courseAnalyticsMeta: Meta
  isLoadingTable:boolean
  isLoadingPage:boolean
  isLoadingTableUserInfo:boolean
  isOpenCourseUserInfoModal:boolean
  courseUserInfo: CourseData
  sortConfig:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CourseAnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCourseAnalyticsApiCall:string=''
  getCourseUserDataApiCall:string=''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
      , getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      currentPage:1,
      currentCourseId:0,
      currentPageUserInfo:1,
      courseList:[],
      courseUserInfo:{
        course_name: "",
        user_data: [],
        meta: {
          current_page: 0,
          next_page: null,
          prev_page: null,
          total_pages: 0,
          total_count: 0
        }
      },
      overallStats:{
        total_assigned_users: 0,
        total_completed_users: 0,
        avg_time_to_complete: 0,
        total_learning_hours: 0,
        location_counts: [],
        device_counts: [],
        average_attempts_per_course: 0,
        average_assessment_score_per_course: 0
      },
      anchorSearch:null,
      anchorFilter:null,
      courseAnalyticsMeta:{
        current_page: 0,
        next_page: null,
        prev_page: null,
        total_pages: 0,
        total_count: 0
      },
      isLoadingTable:false,
      isLoadingPage:false,
      isLoadingTableUserInfo:false,
      isOpenCourseUserInfoModal:false,
      sortConfig: {
        direction: "asc", 
        key: null,
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
    if (firebase.apps.length !== 0) {
      const defaultAnalytics = firebase.app().analytics();
      defaultAnalytics.logEvent("Analytics::Web::Load");
    }
  }
  async componentDidMount() {
    this.setState({isLoadingPage:true})
    this.handleGetCourseAnalytics(1)
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.getCourseAnalyticsApiCall:
            return this.handleCourseAnalyticsResponse(responseJson)
          case this.getCourseUserDataApiCall:
            return this.handleCourseUserInfoResponse(responseJson)
          default:
            break;
        }
      }
    }
    // Customizable Area End
  }
  handleCourseUserInfoResponse = (res:CourseData)=>{
    if(res){
      this.setState({
        courseUserInfo:res, 
        isLoadingTableUserInfo:false
      })
    }
  }
  handleCourseAnalyticsResponse =(res:CourseAnalyticsData)=>{
    this.setState({
      courseAnalyticsMeta:res.meta,
      courseList:res.course_details,
      overallStats:res.overall_stats, 
      isLoadingTable:false,
      isLoadingPage:false
    })
  }
  handleGetCourseAnalytics = async (page:number)=>{
    this.getCourseAnalyticsApiCall = await apiCall({
      method: 'GET',
      contentType: 'application/json',
      endPoint: `bx_block_mixpanel_integration/course_analytics/courses_counts?page=${page}&per_page=10`,
      token: localStorage.getItem('token'),
    });
  }
  handleGetCourseUserInfo = async (courseId:number,page:number)=>{
    this.getCourseUserDataApiCall = await apiCall({
      method: 'GET',
      contentType: 'application/json',
      endPoint: `bx_block_mixpanel_integration/course_analytics/course_user_info?course_id=${courseId}&per_page=10&page=${page}`,
      token: localStorage.getItem('token'),
    });
  }
  handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number,)=>{
    this.setState({currentPage:newPage,isLoadingTable:true})
    this.handleGetCourseAnalytics(newPage)
  }
  handleChangePageUserInfo = (
    event: React.ChangeEvent<unknown>,
    newPage: number,)=>{
    this.setState({currentPageUserInfo:newPage,isLoadingTableUserInfo:true})
    this.handleGetCourseUserInfo(this.state.currentCourseId,newPage)
  }
 
  handleChangeTab=(value:string)=>{
    this.props.navigation.navigate(value)
  }

  handleSortUserInfoTable = (key: keyof UserData) => {
    this.setState((prevState) => {
      const { courseUserInfo, sortConfig } = prevState;
  
      if (!courseUserInfo?.user_data) return null;
  
      const direction =
        sortConfig?.key === key && sortConfig?.direction === "asc"
          ? "desc"
          : "asc";
  
      const sortedData = [...courseUserInfo.user_data].sort((a, b) => {
        const valueA = a[key] ?? ""; 
        const valueB = b[key] ?? "";
  
        if (valueA < valueB) {
          return direction === "asc" ? -1 : 1;
        }
        if (valueA > valueB) {
          return direction === "asc" ? 1 : -1;
        }
        return 0;
      });
  
      return {
        courseUserInfo: {
          ...courseUserInfo,
          user_data: sortedData,
        },
        sortConfig: { key, direction },
      };
    });
  };
  
  
}
