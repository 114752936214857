import React from "react";

// Customizable Area Start
import {
    Box,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Button,
    Typography,
    Drawer
} from "@mui/material";
import {
    StyledEngineProvider,
    styled
} from '@mui/material/styles';
import {
    imgDashboard,
    imgCourses,
    imgPackages,
    imgContentManagement,
    imgPhone,
    imgUser,
    imgClients,
    imgAnalytics,
    imgThinkspikeLogo,
    imgLogout,
    imgChevronRight,
    imgRightArrow,
    arrowBackIcon,
} from './assets';
import {Menu} from '@mui/icons-material';
import HtmlReactParser from 'html-react-parser';
import Loader from '../../../components/src/Loader.web';
import HelpCenterAdminPart from "./HelpCenterAdminPart.web";
import { EditorContent } from '@tiptap/react';
import Sidebar from "../../../components/src/sidebar/Sidebar.web"

interface MainPageItems {
    id: number,
    title: string,
    description: string,
    changePage: () => void
}

import TermsConditionsController, {
    Props,
} from "./TermsAndConditionsController";
import AdminSignupEmail from "./AdminSignupEmail.web";
import EditorToolbar from "./EditorToolbar.web";
// Customizable Area End

export default class TermsConditions extends TermsConditionsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderEditorPrivacyPolicySection = () => {
        return (
            <>
                {this.state.activeEditor === 'privacyPolicy' && (
                    <EditorToolbar
                        editor={this.editorPPText}
                        isEditing={this.state.isEditing}
                        toolbarPosition={this.state.toolbarPosition}
                        toolbarRef={this.toolbarRef}
                    />
                )}
                <div ref={this.editorPPRef}>
                    <StyledEditorTitle>
                        <EditorContent editor={this.editorPPText} />
                    </StyledEditorTitle>
                </div>
            </>
        );
    };

    renderEditorTermsCondsSection = () => {
        const isMobileView = (this.state.isMobile && this.state.currentView === 'TermsAndConditions');
        return (
            <>
                {this.state.activeEditor === "termsAndCondition" && (
                    <EditorToolbar
                        editor={this.editorTACText}
                        isEditing={this.state.isEditing}
                        toolbarPosition={this.state.toolbarPosition}
                        toolbarRef={this.toolbarRef}
                    />
                )}
                <div ref={this.editorTACRef}>
                    <StyledEditorTitle data-test-id="editor-title" isDisabled={isMobileView}>
                        <EditorContent editor={this.editorTACText} />
                    </StyledEditorTitle>
                </div>
            </>
        );
    };

    renderMainPage = () => {
        const items: MainPageItems[] = [
            { id: 1, title: "Admin sign up email", description: "Review and assign courses and packages", changePage: () => this.handleViewChange("adminEmail") },
            { id: 2, title: "Terms and conditions", description: "Edit text", changePage: () => this.handleViewChange("TermsAndConditions") },
            { id: 3, title: "Privacy policy", description: "Edit text", changePage: () => this.handleViewChange("privacyPolicy") },
            { id: 4, title: "Help centre content", description: "You can customize visual look of FAQ’s", changePage: () => this.handleViewChange("helpCentre") },
        ];

        return (
            <Box data-test-id="main-page-items" sx={styles.listElements}>
                {items.map(({ id, title, description, changePage }) =>
                    <Box data-test-id={`main-page-item-${title}`} onClick={changePage} key={id} sx={styles.listBlock}>
                        <Box sx={styles.blockLeft}>
                            <Box sx={styles.listBlockTitle}>{title}</Box>
                            <Box sx={styles.listBlockDesc}>{description}</Box>
                        </Box>
                        <Box sx={styles.blockRight}>
                            <img src={imgRightArrow}></img>
                        </Box>
                    </Box>
                )}
            </Box>
        )
    }

    renderTermsAndConditionsPage = () => {
        const isMobileView = (this.state.isMobile && this.state.currentView === 'TermsAndConditions');
        return (
            <Box
                data-test-id="terms-and-conditions-page"
                sx={{
                    ...styles.block,
                    ...(isMobileView ? styles.blockMobile : {})
                }}
            >
                <Box sx={styles.blockContent}>
                    <Box sx={styles.blockTitle}>
                        Terms and conditions
                    </Box>
                    <Box sx={styles.textContent}>
                        {this.renderEditorTermsCondsSection()}
                    </Box>
                </Box>
            </Box>
        )
    }

    renderPrivacyPolicyPage = () => {
        return (
            <Box data-test-id="privacy-policy-page" sx={styles.block}>
                <Box sx={styles.blockContent}>
                    <Box sx={styles.blockTitle}>
                        Privacy policy
                    </Box>
                    <Box sx={styles.textContent}>
                        {this.renderEditorPrivacyPolicySection()}
                    </Box>
                </Box>
            </Box>
        )
    }

    renderAdminSignupEmail = () => {
        return (
            <AdminSignupEmail
                isSaveDisabled={this.state.isSaveDisabled}
                onSetSaveDisabled={this.updateSaveDisabled}
                onUpdateLoading={this.updateLoading}
                saveRef={this.saveRef}
            />
        );
    }

    renderHelpCenter = () => {
        return (
            <HelpCenterAdminPart
                isSaveDisabled={this.state.isSaveDisabled}
                onSetSaveDisabled={this.updateSaveDisabled}
                onUpdateLoading={this.updateLoading}
                saveRef={this.saveRef}
            />
        );
    }

    definePageText = () => {
        switch (this.state.currentView) {
            case "TermsAndConditions":
                return <>
                    <img src={imgChevronRight} alt="rightangle" />
                    <Box sx={styles.currentNavigationPage}>Terms And Conditions</Box>
                </>
            case "privacyPolicy":
                return <>
                    <img src={imgChevronRight} alt="rightangle" />
                    <Box sx={styles.currentNavigationPage}>Privacy policy</Box>
                </>
            case "adminEmail":
                return <>
                    <img src={imgChevronRight} alt="rightangle" />
                    <Box sx={styles.currentNavigationPage}>Admin email sign up</Box>
                </>
            case "helpCentre":
                return <>
                    <img src={imgChevronRight} alt="rightangle" />
                    <Box sx={styles.currentNavigationPage}>Help Centre</Box>
                </>
            default:
                break;
        }
    }
    mobileHeaderTitle = ()=>{
        switch (this.state.currentView) {
            case "TermsAndConditions":
                return 'Terms And Conditions'
            case "privacyPolicy":
                return 'Privacy policy'
            case "adminEmail":
                return 'Admin email sign up'
            case "helpCentre":
                return 'Help Centre'
            default:
                return "Content Management"
        }
    }

    renderHeaderMobile = ( onBack: () => void) => {
        const { currentView } = this.state;
        const isSaveDisabled = currentView === 'helpCentre' || currentView === 'adminEmail'
            ? this.state.isSaveDisabled : false;
        return (
            <Box sx={styles.headerMobileContainer}>
                <Box data-test-id="menu-toggle-btn" sx={styles.headerMobileBox} onClick={()=>{this.setState({isToggleSidebar:true})}}>
                    <Menu />
                </Box>
                <Typography style={styles.headerMobileTitle}>
                    {this.mobileHeaderTitle()}
                </Typography>
                {this.state.currentView === "main" ?
                    <Box sx={{width:'48px',height:'48px'}}></Box>
                    :
                    <Box display={'flex'} justifyContent={'space-around'} alignItems={'center'}>
                       {!this.isMainView() && <SaveButton
                            isDisabled={isSaveDisabled}
                            data-test-id="save-button"
                            onClick={this.handleSave}
                        >
                            Save
                        </SaveButton>}
                        <Button
                            sx={styles.headerMobileBackBtn}
                            data-test-id="mobile-back-btn"
                            onClick={onBack}
                        >
                            <img style={styles.headerMobileBackIcon} src={arrowBackIcon} alt="back" />
                        </Button>
                    </Box>
                    }
                
            </Box>
        );
    }

    renderHeader = () => {
        const { currentView } = this.state;
        const isSaveDisabled = currentView === 'helpCentre' || currentView === 'adminEmail'
            ? this.state.isSaveDisabled : false;

        return (
            <Box sx={styles.contentHeader}>
                <Box sx={styles.navigation}>
                    <Box data-test-id="content-management-button" onClick={() => this.handleViewChange("main")} sx={!this.isMainView() ? styles.breadcrumbButton: styles.headerTitle}>Content management</Box>
                    {this.definePageText()}
                </Box>
                {!this.isMainView() &&  <SaveButton
                    isDisabled={isSaveDisabled}
                    data-test-id="save-button"
                    onClick={this.handleSave}
                >
                    Save
                </SaveButton>}
            </Box>
        );
    }

    renderHeaderSection = (isDesktop: boolean) => isDesktop
        ? this.renderHeader()
        : this.renderHeaderMobile(() => this.handleViewChange('main'));


    renderSidebar = (isDesktop: boolean) => isDesktop ?
        <Box>
            <Sidebar navigation={this.props.navigation} current='content_management' />
        </Box>
        : 
        <Drawer data-test-id='drawer-menu' open={this.state.isToggleSidebar} onClose={()=>{this.setState({isToggleSidebar:false})}}>
            <Sidebar navigation={this.props.navigation} current='content_management' />
        </Drawer>;

    Footer = () => {
        return (
            <Box sx={styles.footerBlock}>
                <Box sx={styles.leftFooter}>
                    <Box>Terms and Conditions</Box>
                    <Box>Pravicy Policy</Box>
                    <Box></Box>
                </Box>

                <Box>
                    <Box>@ 2024 Thinkspike. All Rights Reserved</Box>
                </Box>
            </Box>
        );
    }

    renderFooter = (isVisible: boolean) => isVisible ? this.Footer() : null;

    // Customizable Area End

    render() {
        // Customizable Area Start
        const isMobileView = (this.state.isMobile );
        return (
            <>
                <Loader loading={this.state.loading}></Loader>
                <Wrapper data-testid="mainWrapper">
                    <Box sx={styles.mainBlock}>

                        {this.renderSidebar(!isMobileView)}

                        <Box sx={styles.pageContent} data-testid="pageContent">
                            {this.renderHeaderSection(!isMobileView)}

                            <Box sx={{
                                ...styles.userContent,
                                ...(isMobileView ? styles.userContentMobile : {}),
                            }}>
                                {this.state.currentView === "main" && this.renderMainPage()}
                                {this.state.currentView === 'adminEmail' && this.renderAdminSignupEmail()}
                                {this.state.currentView === "TermsAndConditions" && this.renderTermsAndConditionsPage()}
                                {this.state.currentView === "privacyPolicy" && this.renderPrivacyPolicyPage()}
                                {this.state.currentView === 'helpCentre' && this.renderHelpCenter()}
                            </Box>
                        </Box>
                    </Box>

                    {this.renderFooter(!isMobileView)}
                </Wrapper>
            </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start

const SaveButton = styled(Button)<{ isDisabled: boolean }>(({ isDisabled }) => ({
    background: "#1C1C1C",
    fontFamily: "Heebo",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#FFFFFF",
    padding: "10px 16px 10px 16px",
    borderRadius: "4px",
    textTransform: "none",
    opacity: isDisabled ? '.7' : '1',
    pointerEvents: isDisabled ? 'none' : 'all',
}))

const StyledEditorTitle = styled('div')<{ isDisabled?: boolean }>(({ isDisabled }) => ({
    borderRadius: '8px',
    padding: isDisabled ? '0px' : '10px',
    boxSizing: 'border-box',
    pointerEvents: isDisabled ? 'none' : 'all',
    '& .ProseMirror-focused': {
        border: 'none',
        outline: 'none',
    },
    '& .ProseMirror > p': {
        margin: '0px',
    },
    "@media(max-width:900px)":{
        "ul,ol":{
        paddingInlineStart:'10px'
    }
    }
}));

const Wrapper = styled(Box)(({ theme }) => ({
    padding: "0",
    height:'100%',
    ".editor-container": {
        width: "auto",
        overflow: 'auto',
        maxHeight: "350px",
        position: 'absolute',
        background: '#FFFFFF',
        borderRadius: '4px',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        "& img": {
            width: "auto",
            height: "revert-layer",
        }
    }
}));

const styles = {
    mainBlock: {
        display: 'flex',
        flex: 1,
        backgroundColor: '#f6f6f6',
        height:'100%'
    },

    footerBlock: {
        position: "fixed",
        width: "calc(100% - 104px)",
        bottom: 0,
        display: 'flex',
        gap: '24px',
        justifyContent: 'space-between',
        padding: '24px 52px 24px 52px',
        backgroundColor: '#1C1C1C',
        color: '#fff'
    },
    leftFooter: {
        display: 'flex',
        gap: '24px'
    },
    rightFooter: {},

    sideBar: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '30px 16px 24px 40px',
        border: '1px solid #f5f5f5',
        backgroundColor: '#fff'
    },
    sideBarTop: {
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
    },
    sideNavList: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px'
    },
    sideNavItem: {
        height: '48px',
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        padding: '4px 16px',
        borderRadius: '8px'
    },
    logoutButton: {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        color: 'red',
        flexGrow: '1',
        justifyContent: 'start',
        fontSize: '16px',
        textTransform: 'none'
    },
    headerMobileContainer: {
        position: 'sticky',
        top: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '6px 16px',
        backgroundColor: '#FFFFFF',
        marginBottom:'20px',
        zIndex: '1000',
    },
    headerMobileBackBtn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '48px',
        height: '48px',
        minWidth: '48px',
        color: '#0F172A',
        borderRadius: '50%',
    },
    headerMobileBackIcon: {
        maxWidth: '24px',
        maxHeight: '24px',
    },
    headerMobileTitle: {
        fontFamily: 'Heebo-Regular',
        fontSize: 14,
        fontWeight: '700',
        textTransform: 'uppercase' as 'uppercase',
        color: '#0F172A',
        padding:' 0 14px'
    },
    headerMobileBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '48px',
        height: '48px',
    },
    pageContent: {
        display: 'flex',
        flexDirection: 'column',
        width: "100%",
    },
    contentHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '24px 48px'
    },
    navigation: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '16px'
    },
    currentNavigationPage: {
        fontWeight: '700',
        fontSize: '24px',
        padding: '4px 8px',
        fontFamily: "Heebo",
        lineHeight: "32px",
        letterSpacing: "-0.005em",
    },
    headerTitle: {
        fontFamily: 'Heebo',
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '32px',
        letterSpacing: '-0.005em',
        textAlign: 'left',
        padding: '4px 8px'
    },
    breadcrumbButton: {
        color: "#475569",
        fontSize: '24px',
        fontFamily: "Heebo",
        fontWeight: 400,
        lineHeight: "32px",
        letterSpacing: "-0.005em",
        padding: '4px 8px'
    },
    leftActions: {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        fontSize: '24px'
    },
    blackButton: {
        backgroundColor: 'black',
        color: 'white',
        '&:hover': {
            backgroundColor: '#333333',
        },
        textTransform: 'none'
    },
    saveButton: {
        backgroundColor: 'black',
        color: 'white',
        '&:hover': {
            backgroundColor: '#333333',
        },
        height: '44px',
        padding: '10px 16px 6px',
        fontWeight: '700'
    },
    moreButton: {
        color: 'black',
        padding: '10px'
    },

    userContent: {
        display:'flex',
        padding: "40px",
        backgroundColor:"rgb(246, 246, 246)",
        justifyContent:'center',
        alignItems:'center'
    },
    userContentMobile: {
        padding: "0px",
    },
    iconsStyle: {
        minWidth: '1px'
    },
    textRow: {
        whiteSpace: 'nowrap'
    },
    listElements: {
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        alignItems: "center",
        justifyContent: 'flex-start',
        padding:'0 30px'
    },
    listBlock: {
        //width: "100%",
        width:'740px',
        display: "flex",
        justifyContent: 'space-between',
        alignItems: "center",
        borderBottom: "1px solid #E2E8F0",
        backgroundColor: "#FFFFFF",
        padding: "16px 24px 16px 24px",
        borderRadius: "8px 8px 0px 0px",

    },
    blockLeft: {

    },
    blockRight: {

    },
    listBlockTitle: {
        fontFamily: "Heebo",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#0F172A",
    },
    listBlockDesc: {
        fontFamily: "Heebo",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        color: "#334155",
    },
    block: {
        display:'flex',
        width:'100%',
        padding: "0px 48px 0px 48px"
    },
    blockMobile: {
        padding: "0px",
    },
    blockContent: {
        background: "#FFFFFF",
        width:'100%',
        borderRadius: "8px",
        padding: "20px 24px 20px 24px"

    },
    blockTitle: {
        color: "#0F172A",
        fontFamily: "Heebo",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.005em",
    },
    textContent: {
        marginTop: "15px",
        fontFamily: "Heebo",
        fontSize: "16px",
        lineHeight: "24px",
        "& img": {
            maxWidth: '100%',
            width: "auto",
            height: "revert-layer",
        }
    }
}

const flexRow = {
    display: 'flex',
    flexDirection: 'row'
}


// Customizable Area End
