import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  userName:string,
  clientId:number,
  imageLink:string,
  courseName:string | null,
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ManageClientController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserDetailsApiID='';
  getClientDetailsApiID = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id:'0',
      userName:'',
      clientId:0,
      imageLink:'',
      courseName:'Course Name',
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    
    await Promise.all([
        this.getUserDetails(),
        this.getCourseName()
    ]);
}


  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getUserDetailsApiID) {
        this.handleUserRespose(responseJson);
      }
      if(apiRequestCallId === this.getClientDetailsApiID)
      {
        this.handleClientApiResponse(responseJson)
      }

    }
  };
  getUserDetails = () => {
    let token = localStorage.getItem('token')
    let userId = localStorage.getItem('id');
    const header = {
      "Content-Type": 'application/json',
      "token":token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage) 
    );
    this.getUserDetailsApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/user_managements/${userId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleUserRespose = (response:any) => {
    this.setState({userName: response?.data?.attributes?.first_name + ' '+ response?.data?.attributes?.last_name})
    this.setState({clientId:response?.data?.attributes?.client_id},)
    this.getClientDetails()
  }
  getClientDetails = () => {
    let token = localStorage.getItem('token')
    const header = {
      "Content-Type": 'application/json',
      "token":token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage) 
    );
    this.getClientDetailsApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_account_groups/clients/${this.state.clientId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleClientApiResponse = (response:any) => {
    this.setState({imageLink:response?.data?.attributes?.company_logo?.url})
  }
  getCourseName = () => {
    let coursename = localStorage.getItem("currentCourse")
    this.setState({courseName:coursename})
  }
  
  // Customizable Area End
}
