import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";

// Customizable Area Start
import { apiCall } from "../../../../packages/components/src/ApiCall.web";
import { SelectChangeEvent } from '@mui/material/Select';
import React from "react";
import { toast } from "react-toastify";

interface Course {
  id: string,
  name: string,
  feedbackForm:any,
}

interface Question {
  question: string,
  required: boolean,
  type: string
}

type QuestionField = 'question' | 'required' | 'type';

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  value: number,
  txtInputValue: string,
  reviewModal: boolean,
  courseData: Course[],
  selectedCourse: Course,
  filteredForms:any,
  feedbackFormName: string,
  feedbackDescription:string,
  questions: Question[],
  filterCategory: string,
  sortBy: string,
  difficulty: string,
  duration:string,
  feedbackForms: any,
  summaryModal: boolean,
  feedback:any,
  reviewSummary:any,
  feedbackId:any

  //ReportAnalytics
  anchorEl: HTMLElement | null,
  menuRowId: null | number,
  page: number,
  rowsPerPage: number,
  Reportdata:any,
  reportModal:any,
  isEdit:boolean,
  metaData: {
    total_pages: number;
    current_page: number;
    total_count: number;
  };
  openReportTable:boolean;

  //createReport
  title: string;
  reportingArea: string;
  selectedColumns: string[];
  availableColumns: string[];
  arrangedColumns: string[];
  draggedIndex: number | null;
  selectedFilter: string;
  subFilter: string[];
  availableFilters: string[];
  availableSubFilters: string[];
  emailOptions: string[],
  selectedEmails: string[],
   dateRange: string,
   reportPreview:any,
   openDialog:boolean,
   openTemplateDialog:boolean,
   openRunReport:boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCoursesApiCallId: string = ""
  saveApiCallId: string = ""
  getAllFeedbackApiCallId:string =""
  getSummaryFeedbackApiCallId:string =""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
      , getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      value: 3,
      txtInputValue: "",
      reviewModal: false,
      courseData: [],
      selectedCourse: {
        id: "0",
        name: "AllCourse (32)",
        feedbackForm:[]
      },
      filteredForms:[],
      feedbackFormName: "",
      feedbackDescription:"",
      questions: [{ question: '', required: true, type: 'rating' }],
      filterCategory: "All Courses",
      sortBy: "Sort by name",
      difficulty: "Beginner",
      duration:"Published",
      feedbackForms: [],
      feedbackId:"",
      summaryModal: false,
      feedback: [
      { question: "How would you rate the content quality", rating: "5/10" },
      { question: "Search Functionality", rating: "2/10" },
      { question: "Intuitive Design", rating: "8/10" },
      { question: "Lesson Clarity", rating: "7/10" },
    ],
    reviewSummary:
      "Easy-to-follow courses, making it perfect for both beginners and professionals looking to enhance their skills.",
     //Report Analytics
     anchorEl: null,
     menuRowId: null,
     page: 0,
     rowsPerPage: 10,
     metaData: {
      total_pages: 5, 
      current_page: 1,
      total_count: 50,
    },
    openReportTable:false,
     Reportdata: [
       {
         id: 1,
         name: "UX Course Report",
         area: "Courses",
         createdBy: "Melanie Craig",
         date: "06/19/2024",
       },
       {
         id: 2,
         name: "Marketing Course Report",
         area: "Clients",
         createdBy: "Jaime Barr",
         date: "06/30/2024",
       },
       {
         id: 3,
         name: "Business Administration Course Report",
         area: "Users",
         createdBy: "Kendall Duffy",
         date: "09/07/2024",
       },
       {
         id: 4,
         name: "All Course Report",
         area: "Courses",
         createdBy: "Paris Waller",
         date: "01/14/2024",
       },
       {
         id: 5,
         name: "Mu - Mu Group",
         area: "Clients",
         createdBy: "Rafael Farrell",
         date: "05/29/2024",
       },
       {
         id: 6,
         name: "Acme - Acme Corporation",
         area: "Courses",
         createdBy: "Leanna Tyler",
         date: "12/09/2024",
       },
     ],
     reportModal:false,
     isEdit:false,

     //CreateReport 
     title: "UX Designer",
     reportingArea: "Courses",
     selectedColumns: [
       "Average Total Time on the Platform",
       "Geolocation Breakdown",
       "Average Assessment Attempts",
       "Number of Users Who Completed the Course",
       "Number of Courses Assigned",
     ],
     availableColumns: [
       "Average Total Time on the Platform",
       "Device Usage Breakdown",
       "Geolocation Breakdown",
       "Average Assessment Attempts",
       "Number of Users Who Completed the Course",
       "Number of Users Currently Taking the Course",
       "Number of Courses Assigned",
       "Number of Courses Completed",
     ],
     arrangedColumns: [
       "Average Total Time on the Platform",
       "Geolocation Breakdown",
       "Average Assessment Attempts",
       "Number of Users Who Completed the Course",
       "Number of Courses Assigned",
     ],
     draggedIndex: null,
     selectedFilter: 'Devices',
     subFilter: ['Desktop', 'Mobile'],
     availableFilters: ['Devices', 'Users', 'Geolocation'],
     availableSubFilters: ['Desktop', 'Mobile', 'Tablet', 'Other'],
     emailOptions: ['Jahin Karim', 'Damodar Nagina'],
     selectedEmails: ['Jahin Karim', 'Damodar Nagina'],
    dateRange: '',
    reportPreview:[
      { client: "Johnson & Johnson", users: 52, courseAssigned: 6, courseCompleted: 5, accreditation: 11, avgCompletion: 60, totalLearning: "77%", totalHours: "20 Hours" },
      { client: "Bank of America", users: 54, courseAssigned: 6, courseCompleted: 5, accreditation: 11, avgCompletion: 60, totalLearning: "77%", totalHours: "20 Hours" },
      // Add more rows here...
    ],
    openDialog: false,
    openTemplateDialog:false,
    openRunReport:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
    if (firebase.apps.length !== 0) {
      const defaultAnalytics = firebase.app().analytics();
      defaultAnalytics.logEvent("Analytics::Web::Load");
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
   
    if (apiRequestCallId === this.getAllFeedbackApiCallId) {
      this.handleGetAllFeedbackResponse(responseJson)
    }
    else if (apiRequestCallId === this.getCoursesApiCallId) {
      this.handleGetCoursesResponse(responseJson)
    }
    else if (apiRequestCallId === this.getSummaryFeedbackApiCallId){
      this.handleGetSummaryFeedbackResponse(responseJson)
    }
    else if (apiRequestCallId === this.saveApiCallId) {
      this.handleReviewModalClose()
      if (responseJson.errors) {
        toast.error(responseJson.errors[0])
      } else {
        toast.success("Feedback form created successfully")
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  async doButtonPressed() {
    const defaultAnalytics = firebase.app().analytics();
    defaultAnalytics.logEvent("Analytics::Web::button_clicked");
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getCourses()
    this.getAllFeedbackforms()
  }
  
  /* istanbul ignore next */
  componentDidUpdate(prevProps: Props) {
    if (prevProps.navigation !== this.props.navigation) {
      this.setState({ reportModal: this.props.navigation });
    }
  }


  handleChange = (event: React.SyntheticEvent, value: any) => {
    this.setState({ value: value });
  };

  handlefilterChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    fieldName: string
  ) => {
    const { value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };
  
  handleReviewModalOpen = () => {
    this.setState({ reviewModal: true })
  }

  handleReviewModalClose = () => {
    this.setState({
      reviewModal: false,
      feedbackFormName: "",
      feedbackDescription:"",
      questions: [{ question: "", required: false, type: "rating" }],
      selectedCourse: { id: '0', name: "All Courses (32)",feedbackForm:[] }
    })
  }

  handleSummaryModalOpen = async(id:any) => {
        this.getSummaryFeedbackApiCallId = await apiCall({
        method: "GET",
        navigation: this.props.navigation,
        token: localStorage.getItem("token"),
        endPoint: `/bx_block_profile/feedback_forms/${id}/show_feedback_form`,
      });
  }
  handleSummaryModalClose = () => {
    this.setState({ summaryModal: false });
  };

  getAllFeedbackforms = async () => {
    this.getAllFeedbackApiCallId = await apiCall({
      method: "GET",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_profile/feedback_forms/user_review`,
    });
  }

 getTitleById = (id:any) => {
    const course = this.state.courseData.find((item) => item.id == id);
   return course?.name || "Title loading";
  };

  getCourses = async () => {
    this.getCoursesApiCallId = await apiCall({
      method: "GET",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_admin/courses`,
    });
  }

  handleGetCoursesResponse = (response: any) => {
    this.setState({
      courseData: response?.data?.map((course: any) => ({
        id: course.id,
        name: course.attributes.title,
        feedbackForm:course.attributes?.feedback_forms
      }))
    })
  }

  handleGetAllFeedbackResponse = (response: any) => {
    console.log(response,"response11");
    this.setState({ feedbackForms:response?.feedback_forms})
  }
  handleGetSummaryFeedbackResponse = (response: any) => {
    console.log(response,"responseresponse");
    this.setState({ summaryModal: true })
    // this.setState({ feedback :response?.feedback_form})
  }
  /* istanbul ignore next */
  handleFilterSelectedCourse = async (event: SelectChangeEvent<string>, child: React.ReactNode) => {
    const selectedId = event.target.value
    const selectedClient = this.state.courseData.find(c => c.id === selectedId);

    if (selectedClient) {
      this.setState({
        selectedCourse: {
          id: selectedClient.id,
          name: selectedClient.name,
          feedbackForm:selectedClient.feedbackForm
        },
      });
      const filteredForms = this.state.feedbackForms.filter((form:any) => selectedClient.feedbackForm.includes(form.id)); 
      this.setState({filteredForms:filteredForms})
    }else{
      this.setState({
        selectedCourse:  {
          id: "0",
          name: "AllCourse (32)",
          feedbackForm:[]
        },
        filteredForms:[]
      })
      this.getAllFeedbackforms()
    }
  };
  handleSelectedCourse = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
    const selectedId = event.target.value
    const selectedClient = this.state.courseData.find(c => c.id === selectedId);

    if (selectedClient) {
      this.setState({
        selectedCourse: {
          id: selectedClient.id,
          name: selectedClient.name,
          feedbackForm:selectedClient.feedbackForm
        },
      });
    }
  };

  handleFeedbackFormName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ feedbackFormName: event.target.value })
  }
  handleFeedbackFormDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ feedbackDescription: event.target.value })
  }

  handleAddQuestion = () => {
    this.setState(prevState => ({
      questions: [...prevState.questions, { question: '', required: true, type: 'rating' }]
    }));
  };
  handleQuestionChange = (index: number, field: keyof Question, value: string | boolean) => {
    const questions = [...this.state.questions];
    const newValue = value
    if (
      (field === "question" && typeof value === "string") ||
      (field === "type" && typeof value === "string")
    ) {
      questions[index][field] = value;
    } else if (
      (field === "required" && typeof value === "boolean")) {
      questions[index][field] = newValue as boolean;
    }

    this.setState({ questions });
  };

  handleDeleteQuestion = (index: number) => {
    const questions = this.state.questions.filter((_, i) => i !== index);
    this.setState({ questions });
  };

  getQuestionType = (type: string) => {
    const typeMapping: { [key: string]: number } = {
      'rating': 0,
      'free-text': 1,
    };
    return typeMapping[type];
  }

  handleSave = async () => {
    const { selectedCourse, feedbackFormName, questions , feedbackDescription } = this.state;
    // "admin_user": 1,

    const body = {
      "feedback_form": {
        "course_id": selectedCourse.id,
        "name": feedbackFormName,
        "description":feedbackDescription,
        "feedback_questions_attributes": questions.map((q) => ({
          "question": q.question,
          "question_type": this.getQuestionType(q.type),
        })),
      },
    }

    this.saveApiCallId = await apiCall({
      method: "POST",
      contentType: 'application/json',
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_profile/feedback_forms`,
      body: body
    });
  }

  //Report Analytics

  handleMenuOpen = (event: any, id: number) => {
    this.setState({ anchorEl: event.currentTarget, menuRowId: id });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null, menuRowId: null });
  };

  handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    this.setState({ page: newPage });
  };

  handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    const { rowsPerPage } = this.state;
    this.setState({
      metaData: {
        ...this.state.metaData,
        current_page: value,
      },
      page: value - 1,
    });
  };

  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };


  //createReports
   handleCreateReport = (data:any) => {
    if(data === "Edit"){
      this.setState({ reportModal :true,isEdit:true});
    }else{
      this.setState({ reportModal :true ,isEdit:false});
    }
  };

  handleCloseReport = () => {
      this.setState({ reportModal :false,isEdit:false});
  };

  handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ title: event.target.value });
  };

  handleFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ selectedFilter: event.target.value as string });
  };

  handleSubFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ subFilter: event.target.value as string[] });
  };

  handleEmailChange = (event:any, newValue :any) => {
    this.setState({ selectedEmails: newValue });
  };

  handleSelectColoumn =(e:any,column:any)=>{
    const isChecked = e.target.checked;
   
    /* istanbul ignore next */
    this.setState((prevState) => ({
      selectedColumns: isChecked
        ? [...prevState.selectedColumns, column]
        : prevState.selectedColumns.filter((c) => c !== column),
    }));
  }

  handleDragStart = (index: number) => {
    this.setState({ draggedIndex: index });
  };

  handleDragOver = (e: any) => {
    e.preventDefault();
  };

  handleDrop = (index: number) => {
    const { draggedIndex, arrangedColumns } = this.state;
    
    /* istanbul ignore next */
    if (draggedIndex !== null && draggedIndex !== index) {
      const newItems = [...arrangedColumns];
      const [movedItem] = newItems.splice(draggedIndex, 1);
      newItems.splice(index, 0, movedItem);
      this.setState({ arrangedColumns: newItems, draggedIndex: null });
    }
  };

  handleDragEnd = () => {
    this.setState({ draggedIndex: null });
  };

  handleDelete = (index: number) => {
    const newItems = this.state.arrangedColumns.slice();
    newItems.splice(index, 1);
    this.setState({ arrangedColumns: newItems });
  };
  /* istanbul ignore next */
  handleDateRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    this.setState({ dateRange: value });

    const dateParts = value.split(' to ');
    if (dateParts.length === 2) {
      const [startDate, endDate] = dateParts;
      if (this.isValidDate(startDate) && this.isValidDate(endDate)) {
        console.log('Selected Date Range:', startDate, endDate); // For demonstration
      }
    }
  };

  isValidDate = (date: string) => {
    // Check if the date matches the format YYYY-MM-DD
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(date);
  };

  handleOpenPreviewReport = () => {
    this.setState({ openDialog: true });
  };
  
  handleOpenRunReport = () => {
    this.setState({ openRunReport: true });
  };

  handleOpenTemplateModal = () => {
    this.setState({ openTemplateDialog: true ,openDialog:false});
  };

  handleCloseTemplateModal = () => {
    this.setState({ openTemplateDialog: false })
  };
  handleOpenreportTable =()=>{
    this.setState({openReportTable:true});
  }

  previewReportClose =()=>{
    this.setState({ openDialog: false,openRunReport:false })
  }
  // Customizable Area End
}
