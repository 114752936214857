import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
	getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import React from 'react';
import { Editor } from '@tiptap/react';
import { Position } from "./HelpCenterAdminPartTypes";
import { EditorTypes, handleEditorBlur, handleOutsideEditorClick, initEditor, updateToolbarPosition } from "./EditorUtils";
import { getStorageData } from "../../../../packages/framework/src/Utilities";
export const crypto = require('crypto');

export interface ApiCallInterface {
	contentType?: string,
	method?: string,
	endPoint?: string,
	body?: object
}

export interface Content {
	id: string;
	type: string;
	attributes: {
		subject: string;
		title: string;
		description: string;
		admin_id?: number;
		created_at?: Date;
		updated_at?: Date;
		your_email?: string;
		temporary_password?: string;
		welcome_logo: {
			id: number;
			date: Date;
			url: string;
		},
	};
};

export interface UpdateContent {
	title?: string;
	description?: string;
}

export type EditorType = 'title' | 'description';

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
	// Customizable Area Start
	navigation?: object;
	isSaveDisabled: boolean;
	saveRef: React.RefObject<{ handleSave: () => void }>;
	onSetSaveDisabled: (value: boolean) => void;
	onUpdateLoading: (value: boolean) => void;
	// Customizable Area End
}

interface S {
	// Customizable Area Start
	title: string,
	description: string;
	originalTitle: string;
	originalDescription: string;
	logo: string | null;
	isEditing: boolean;
	toolbarPosition: Position;
	activeEditor: EditorType | null;
	emailContentId:string;
	loading:boolean
	// Customizable Area End
}

interface SS {
	// Customizable Area Start
	id: any;
	// Customizable Area End
}

export default class AdminSignupEmailController extends BlockComponent<
	Props,
	S,
	SS
> {
	// Customizable Area Start
	apiGetEmailContentById: string = "";
	apiPatchEmailContentById: string = "";
	editorTitle: Editor | null = null;
	editorDescription: Editor | null = null;
	editorTitleRef = React.createRef<HTMLDivElement>();
	editorDescriptionRef = React.createRef<HTMLDivElement>();
	toolbarRef = React.createRef<HTMLDivElement>();
	// Customizable Area End

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);

		// Customizable Area Start
		// Customizable Area End
		this.subScribedMessages = [
			getName(MessageEnum.RestAPIResponceMessage),
			// Customizable Area Start
			// Customizable Area End
		];

		this.state = {
			// Customizable Area Start
			title: '',
			loading:false,
			description: ``,
			originalTitle: '',
			originalDescription: ``,
			logo: null,
			isEditing: false,
			toolbarPosition: { top: 0, left: 0 },
			emailContentId:'',
			activeEditor: null,
			// Customizable Area End
		};
		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

		// Customizable Area Start

		this.initEditors();

		this.handleOutsideClick = this.handleOutsideClick.bind(this);
		// Customizable Area End
	}

	initEditors() {
		this.editorTitle = initEditor(
			'title',
			this.handleEditorUpdate,
			this.handleSelectionUpdate,
			this.onFocus,
			this.handleTitleBlur,
		);

		this.editorDescription = initEditor(
			'description',
			this.handleEditorUpdate,
			this.handleSelectionUpdate,
			this.onFocus,
			this.handleDescriptionBlur,
			'Enter your description here',
		);
	}

	handleEditorUpdate = (type: EditorTypes, editor: Editor) => {
		const content = editor.getHTML();
		this.updateEditorContent(type, content);
	}

	handleSelectionUpdate = (type: EditorTypes, editor: Editor) => {
		updateToolbarPosition(
			editor,
			this.toolbarRef,
			(position) => this.setState({ toolbarPosition: position, isEditing: true, activeEditor: type as EditorType }),
			(isEditing) => {},
			this.state.isEditing
		);
	};

	handleTitleBlur = (editor: Editor) => {
		handleEditorBlur(editor, '<p>Untitled</p>', (content: string) =>
			this.updateEditorContent('title', content)
		);
		const selection = editor?.state?.selection;
		if(selection?.empty){
		this.setState({isEditing:false});
		}
	};

	handleDescriptionBlur = (editor: Editor) => {
		handleEditorBlur(editor, '<p></p>', (content: string) =>
			this.updateEditorContent('description', content)
		);
		const selection = editor?.state?.selection;
		if(selection?.empty){
		this.setState({isEditing:false});
		}
	};

	onFocus = (type: EditorTypes, editor: Editor) => {
		updateToolbarPosition(
			editor,
			this.toolbarRef,
			(position) => this.setState({ toolbarPosition: position, isEditing: true, activeEditor: type as EditorType }),
			(isEditing) => this.setState({ isEditing }),
			this.state.isEditing,
			true
		);
	}

	updateEditorContent = (type: EditorTypes, text: string) => {
		this.setState(prev => ({ ...prev, [type as EditorType]: text }), () => {
			this.props.onSetSaveDisabled(this.isSaveButtonDisabled());
		});
	}

	async receive(from: string, message: Message) {
		// Customizable Area Start
		if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
			const apiRequestCallId = message.getData(
				getName(MessageEnum.RestAPIResponceDataMessage)
			);

			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);

			if (responseJson && responseJson.data) {
				if (apiRequestCallId === this.apiGetEmailContentById) {
					this.getEmailContentByIdSuccesscallCallback(responseJson.data);
				}
				if (apiRequestCallId === this.apiPatchEmailContentById) {
					this.updateEmailContentDataSuccesscallCallback();
				}
				this.props.onUpdateLoading(false);
			}
		}
		// Customizable Area End
	}

	// Customizable Area Start

	async componentDidMount() {
		this.getEmailContentByIdApi();
		const saveRef = this.props.saveRef.current as { handleSave: () => void };
		saveRef.handleSave = this.handleSave;

		window.addEventListener('mousedown', this.handleOutsideClick);
	}

	async componentWillUnmount() {
		window.removeEventListener('mousedown', this.handleOutsideClick);
		this.editorTitle?.destroy();
		this.editorDescription?.destroy();
	}

	apiCall = async (data: ApiCallInterface) => {

		const { contentType, method, endPoint, body } = data;

		let token = await getStorageData("token");
		const header = {
			"Content-Type": contentType,
			token,
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			method
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			endPoint
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		body &&
			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestBodyMessage),
				JSON.stringify(body)
			);

		runEngine.sendMessage(requestMessage.id, requestMessage);
		return requestMessage.messageId;
	};

	getEmailContentByIdApi = async () => {
		this.props.onUpdateLoading(true);
		this.setState({loading:true})
		this.apiGetEmailContentById = await this.apiCall({
			contentType: configJSON.emailContentByIdApiContentType,
			method: configJSON.getEmailContentByMethod,
			endPoint: configJSON.getEmailContentByIdUrl,
		});
	};

	updateEmailContentData = async (content: UpdateContent) => {
		this.props.onUpdateLoading(true);
		this.apiPatchEmailContentById = await this.apiCall({
			contentType: configJSON.emailContentByIdApiContentType,
			method: configJSON.updateEmailContentByMethod,
			endPoint: `${configJSON.getEmailContentByIdUrl}/${this.state.emailContentId}`,
			body: {
				title: content.title,
				description: content.description,
			},
		});
	};

	getEmailContentByIdSuccesscallCallback = (data: Content[]) => {
		const emailContent = data[0];
		this.setState(prev => ({
			...prev,
			title: emailContent.attributes.title,
			description: emailContent.attributes.description,
			originalTitle: emailContent.attributes.title,
			originalDescription: emailContent.attributes.description,
			logo: emailContent.attributes.welcome_logo.url,
			emailContentId:emailContent.id,
			loading:false
		}), () => {
			this.editorTitle?.commands.setContent(this.state.title)
			this.editorDescription?.commands.setContent(this.state.description)
		});
	}

	updateEmailContentDataSuccesscallCallback = () => {
		this.getEmailContentByIdApi();
	}

	handleSave = () => {
		const { title, description } = this.state;
		this.updateEmailContentData({ title, description });
		this.props.onSetSaveDisabled(true);
	}

	isSaveButtonDisabled = () => {
		const { title, description, originalTitle, originalDescription } = this.state;

		const hasChanges = (JSON.stringify(title) !== JSON.stringify(originalTitle)
			|| JSON.stringify(description) !== JSON.stringify(originalDescription));

		return !hasChanges;
	};

	handleOutsideClick = (event: MouseEvent) => {
		handleOutsideEditorClick(
			event,
			[this.editorDescriptionRef, this.editorTitleRef],
			this.toolbarRef,
			() => this.setState({ activeEditor: null })
		);
	};
	onTitleChange = (title: string) => {
        this.setState({ title });
		this.props.onSetSaveDisabled(false)
    }

onDescriptionChange = (description: string) => {
    this.setState({ description });
	this.props.onSetSaveDisabled(false)
}
	// Customizable Area End
}