import React from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { styled } from "@mui/material/styles";
import {ArrowBackIosNew, ArrowForwardIos} from '@mui/icons-material';

function SampleNextArrow(props:any) {
    const { className, style, onClick } = props;
    return (
      <ArrowForwardIos
        className={className}
        style={{ ...style,     borderRadius:'50%',
            color:'black', 
            backgroundColor:"#fff",
            boxShadow: '0px 2px 20px 0px #00000014',
            padding:'15px'}}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props:any) {
    const { className, style, onClick } = props;
    return (
                <ArrowBackIosNew  
                onClick={onClick}
              className={className}
                style={{
                    ...style,
                    borderRadius:'50%',
                color:'black', 
                backgroundColor:"#fff",
                boxShadow: '0px 2px 20px 0px #00000014',
                padding:'15px',
                zIndex:10
}}/>
      
    );
  }

const CarouselCustom = ({children}:{children:React.ReactNode[]}) => {
 const settings = {
    customPaging: ()=> {
      return (
        <div className='dot-custom'></div>
      );
    },
    dots: true,
    infinite: children.length>1,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };
  return (
    <CarouselContainer>
      <Slider {...settings}>
        {children}
      </Slider>
    </CarouselContainer>
  );
}
const CarouselContainer = styled('div')({
  '& .dot-custom':{
    width:'16px',
    height:'16px',
    backgroundColor:"#E7E5E4",
    borderRadius:'50%'
  },
  '& .slick-active>.dot-custom':{
    backgroundColor:"#1C1C1C"
  },
  '& .slick-disabled':{
    color:'#E7E5E4 !important'
  }
})

export default CarouselCustom