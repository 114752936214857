import React from "react";
// Customizable Area Start
import {
  Container,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  TextField,
  FormControl,
  Modal,
  FormLabel,
  Typography,
  Box,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  InputAdornment,
  Pagination,
  Select,
  MenuItem,
  Popover,
  MenuList
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  styled
} from "@mui/material/styles";
import "@mui/styles";
const DatePicker = require("react-datepicker");

import "react-datepicker/dist/react-datepicker.css";
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FilterList, KeyboardArrowDown, KeyboardArrowRight, AddCircleOutline, Search, GroupAdd } from '@mui/icons-material';

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
      contrastText: "#fff",
    },
  },
});

import CustomisableUserProfilesController, {
  Props,
  IField,
} from "./CustomisableUserProfilesController";
import { exportIcon, fileIcon, uploadIcon } from "./assets";
import Sidebar from "../../../../packages/components/src/sidebar/Sidebar.web";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getFieldType = (field: IField) => {
    if (
      field.field_type === "integer" ||
      field.field_type === "number" ||
      field.field_type === "float"
    ) {
      return "number";
    }
    return "text";
  };

  renderField = (field: IField, index: number) => {
    if (
      field.field_type === "text" ||
      field.field_type === "string" ||
      field.field_type === "file" ||
      field.field_type === "textarea" ||
      field.field_type === "number" ||
      field.field_type === "integer" ||
      field.field_type === "float"
    ) {
      return (
        <div key={index + "renderField"}>
          <TextField
            data-test-id={field.name}
            label={field.title}
            multiline={field.field_type === "textarea"}
            value={this.state.profile[field.name]}
            onChange={(event) =>
              this.changeFormValue(
                field.name,
                event.target.value,
                field.field_type
              )
            }
            disabled={!field.is_enable}
            required={field.is_required}
            type={this.getFieldType(field)}
            onKeyPress={(event) => this.onKeyPress(event, field)}
            InputLabelProps={{
              shrink: true,
            }}
          // defaultValue='Enter Value'
          />
        </div>
      );
    }

    if (field.field_type === "date" || field.field_type === "datetime") {
      return (
        <div key={index + "renderField"}>
          <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
            {field.title + (field.is_required ? "*" : "")}
          </div>
          <div style={{ marginBottom: "5px", marginTop: "5px" }}>
            <DatePicker
              data-test-id={field.name}
              selected={
                new Date(
                  this.state.profile[field.name] as string
                ).toString() !== "Invalid Date"
                  ? new Date(this.state.profile[field.name] as string)
                  : null
              }
              onChange={(date: Date) =>
                this.changeFormValue(field.name, date, field.field_type)
              }
              disabled={!field.is_enable}
              required={field.is_required}
            />
          </div>
        </div>
      );
    }

    if (field.field_type === "checkbox") {
      return (
        <div key={index + "renderField"}>
          <Checkbox
            data-test-id={field.name}
            disabled={!field.is_enable}
            checked={this.state.profile[field.name] as boolean}
            onChange={(event) =>
              this.changeFormValue(
                field.name,
                event.target.checked,
                field.field_type
              )
            }
            color="primary"
          />
          {field.title}
        </div>
      );
    }
    if (field.field_type === "boolean") {
      return (
        <div key={index + "renderField"}>
          <FormControl>
            <FormLabel>{field.title}</FormLabel>
            <RadioGroup
              row
              data-test-id={field.name}
              onChange={(event) => {
                this.changeFormValue(
                  field.name,
                  event.target.value,
                  field.field_type
                );
              }}
              value={
                this.state.profile[field.name] === undefined
                  ? undefined
                  : String(this.state.profile[field.name])
              }
            >
              <FormControlLabel
                value={"true"}
                control={<Radio />}
                label="True"
              />
              <FormControlLabel
                value={"false"}
                control={<Radio />}
                label="False"
              />
            </RadioGroup>
          </FormControl>
        </div>
      );
    }
  };

  DataTable = () => {

    const TabCellHeader = styled(TableCell)({
      fontFamily: 'Heebo-Bold', color: '#475569', fontWeight: 700, fontSize: '14px', backgroundColor: 'white', zIndex: 1
    })

    const TabCells = styled(TableCell)({
      fontFamily: 'Heebo-Regular', color: '#0F172A', fontWeight: 400, fontSize: '14px'
    })

    return (
      <TableContainer component={Box} style={{ width: '100%', maxHeight: '430px', overflowY: 'scroll' }}>
        <style>
          {`
        .MuiTableCell-root{
          border="none"
        }
        `}
        </style>
        <Box style={{ width: '100%', overflowX: 'hidden' }}>
          <Table aria-label="sticky table" stickyHeader>
            <TableHead>
              <TableRow>
                <TabCellHeader>#</TabCellHeader>
                <TabCellHeader style={{ whiteSpace: 'nowrap' }}>First and Last Name</TabCellHeader>
                <TabCellHeader>Email</TabCellHeader>
                <TabCellHeader>Role</TabCellHeader>
                <TabCellHeader></TabCellHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.csvData.map((user, index) => (
                <TableRow key={index}>
                  <TabCells>{index + 1}</TabCells>
                  <TabCells style={{ whiteSpace: 'nowrap' }}>{`${user.firstName} ${user.lastName}`}</TabCells>
                  <TabCells>{user.email}</TabCells>
                  <TabCells>ADMIN</TabCells>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
    );
  };

  BulkUploadCsv = () => {
    return (
      <Modal open={this.state.csvModal} onClose={this.handleModalClose} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Container
          sx={{
            position: 'absolute',
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: "0px 8px 32px 0px #0000000F",
            width: '600px',
            padding: '24px',
            height: "80vh",
            borderRadius: '8px 8px 32px 8px',
            backgroundColor: '#FFFFFF',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            justifyContent: 'space-between',
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" padding="10px 0" borderBottom="1px solid #F0F0F0">
            <Typography
              style={{
                fontFamily: 'Heebo',
                fontSize: '24px',
                letterSpacing: '0.5%',
                lineHeight: '32px',
                fontWeight: '700',
              }}>
              CSV file upload
            </Typography>
            <IconButton data-testID="close-Modal" onClick={this.handleModalClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          {this.state.isDoneClicked ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%"
              }}
            >
              {this.DataTable()}
              <Box
                sx={{
                  backgroundColor: this.state.isValid ? '#D1FAE5' : '#FEE2E2',
                  borderLeft: `4px solid ${this.state.isValid ? '#059669' : '#DC2626'}`,
                  padding: '10px',
                  borderRadius: '4px',
                  marginBottom: '16px',
                }}
              >
                <Typography
                  style={{
                    fontFamily: 'Heebo',
                    fontSize: '16px',
                    fontWeight: '600',
                    color: this.state.isValid ? '#059669' : '#DC2626',
                  }}
                >
                  {this.state.isValid
                    ? "All data validated, and ready for upload"
                    : `Error in rows`}
                </Typography>
              </Box>
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  cursor: "pointer",
                  border: "1px dashed #CBD5E1",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: '16px',
                  textAlign: "center",
                  padding: '24px',
                }}
                onDragOver={this.handleDragOver}
                onDrop={this.handleDrop}
                data-testID="dragFile"
                className="upload"
              >
                <img src={uploadIcon} alt="" />
                <Typography
                  style={{
                    fontFamily: 'Heebo',
                    fontSize: '24px',
                    letterSpacing: '0.5%',
                    lineHeight: '32px',
                    fontWeight: '700',
                  }}>
                  Upload media
                </Typography>
                <Typography
                  style={{
                    fontFamily: 'Heebo',
                    fontSize: '16px',
                    color: '#334155',
                    lineHeight: '24px',
                    fontWeight: '400',
                  }}>
                  Drag and drop or add some files
                </Typography>
                <Button variant="text" component="label"
                  style={{
                    fontFamily: 'Heebo',
                    textTransform: 'none',
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: '700',
                    color: '#000000',
                    backgroundColor: '#F5F5F4',
                  }}>
                  Upload from your device
                  <input
                    type="file"
                    accept=".csv"
                    data-testID="fileInput"
                    onChange={this.handleFileChange}
                    style={{
                      position: 'absolute',
                      width: '1px',
                      height: '1px',
                      opacity: 0,
                      pointerEvents: 'none',
                    }}
                  />
                </Button>
                <Typography
                  style={{
                    fontFamily: 'Heebo',
                    fontSize: '12px',
                    lineHeight: '18px',
                    fontWeight: '400',
                    color: '#94A3B8',
                  }}>
                  Supported formats: CSV
                </Typography>
              </Box>

              {this.state.file && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  padding="16px 12px 16px 16px"
                  border="1px solid #ccc"
                  borderRadius="8px"
                  gap="16px"
                >
                  <Box display="flex" gap="16px">
                    <img src={fileIcon} alt="" />
                    <Box display="flex" flexDirection="column">
                      <Typography variant="body2">{this.state.file.name}</Typography>
                      <Typography variant="caption" color="textSecondary">
                        {(this.state.file.size / 1024).toFixed(2)} KB
                      </Typography>
                    </Box>
                  </Box>
                  <IconButton>
                    <MoreVertIcon />
                  </IconButton>
                </Box>
              )}
            </>
          )}

          <Box display="flex" justifySelf="end" gap="16px" justifyContent="end" borderTop="1px solid #F0F0F0">
            {this.state.isDoneClicked ? (
              <Button
                variant="contained"
                style={{ color: 'white', backgroundColor: 'black' }}
                disabled={!this.state.isValid}
                onClick={this.handleCSVFileUpload}
                data-testID="uploadValid-Btn"
              >
                Upload
              </Button>
            ) : (
              <>
                <Button variant="outlined"
                  style={{ color: 'black', backgroundColor: '#F0F0F0', border: 'none' }}
                  onClick={this.handleModalClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  style={{ color: 'white', backgroundColor: 'black' }}
                  disabled={!this.state.file}
                  onClick={this.handleDoneClick}
                  data-testID="done-btn"
                >
                  Done
                </Button>
              </>
            )}
          </Box>
        </Container>
      </Modal>
    )
  }

  AddNewUserModal = () => {
    const { newUserData } = this.state
    return (
      <Modal
        open={this.state.addNewUserModal}
        onClose={this.handleAddNewUserModalClose}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Container sx={{...webStyles.containerModal,minHeight:'95%',gap:'10px'}}>
          <Box display="flex" justifyContent="space-between" alignItems="center" padding="10px 0" borderBottom="1px solid #F0F0F0">
            <Typography
              style={{
                fontFamily: 'Heebo',
                fontSize: '24px',
                letterSpacing: '0.5%',
                lineHeight: '32px',
                fontWeight: '700',
              }}>
              Add Admin
            </Typography>
            <IconButton data-testID="close-Modal" onClick={this.handleAddNewUserModalClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box height={"80%"} width={"100%"} display={'flex'} flexDirection={"column"} justifyContent={"space-between"} gap={"10px"}>
            <FormControl fullWidth>
              
            </FormControl>
            <Box>
              <Typography style={webStyles.modalText}>First Name</Typography>
              <TextField
                fullWidth
                value={newUserData.firstName}
                data-testID="addFirstName"
                onChange={this.handleUserDataChange("firstName")}
              />
            </Box>
            <Box>
              <Typography style={webStyles.modalText}>Last Name</Typography>
              <TextField
                fullWidth
                data-testID="addLastName"
                value={newUserData.lastName}
                onChange={this.handleUserDataChange("lastName")}
              />
            </Box>
            <Box>
            </Box>
            <Box>
              <Typography style={webStyles.modalText}>Email</Typography>
              <TextField
                fullWidth
                value={newUserData.email}
                data-testID="addEmail"
                onChange={this.handleUserDataChange("email")}
              />
            </Box>
            <Box>
              <Typography style={webStyles.modalText}>Role</Typography>
              <Typography style={webStyles.modalText}>ADMIN</Typography>
            </Box>
          </Box>

          <Box display="flex" justifySelf="end" gap="16px" paddingTop={'20px'} justifyContent="end" borderTop="1px solid #F0F0F0">
            <Button
              variant="outlined"
              style={{ color: 'black', backgroundColor: '#F0F0F0', border: 'none' }}
              onClick={this.handleAddNewUserModalClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{ color: 'white', backgroundColor: 'black' }}
              onClick={this.handleAddNewUserDoneClick}
              data-testID="handleAddNewUserDoneClick"
            >
              Done
            </Button>
          </Box>
        </Container>
      </Modal>
    )
  }

  ViewUserModal = () => {
    const { currentUser } = this.state
    return (
      <Modal
        open={this.state.viewUserModal}
        onClose={this.handleViewUserModalClose}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Container sx={{ ...webStyles.containerModal, justifyContent: 'normal' }}>
          <Box display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="10px 0px"
            borderBottom="1px solid #F0F0F0">
            <Typography
              style={{
                fontFamily: 'Heebo',
                letterSpacing: '0.5%',
                fontSize: '24px',
                fontWeight: '700',
                lineHeight: '32px',
              }}>
              View Admin
            </Typography>
            <IconButton data-testID="closeViewModal" onClick={this.handleViewUserModalClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box height={"80%"} width={"100%"} display={'flex'} flexDirection={"column"} justifyContent={"space-between"} gap={"10px"}>
            <FormControl fullWidth>
              
            </FormControl>
            <Box>
              <Typography style={webStyles.modalText}>First Name</Typography>
              <TextField value={currentUser?.attributes.first_name} fullWidth />
            </Box>
            <Box>
              <Typography style={webStyles.modalText}>Last Name</Typography>
              <TextField value={currentUser?.attributes.last_name} fullWidth />
            </Box>
            
            <Box>
              <Typography style={webStyles.modalText}>Email</Typography>
              <TextField value={currentUser?.attributes.email} fullWidth />
            </Box>
            <Box>
              <Typography style={webStyles.modalText}>Role</Typography>
              <Typography style={webStyles.modalText}>ADMIN</Typography>
            </Box>
          </Box>

        </Container>
      </Modal>
    )
  }

  EditUserModal = () => {
    const { editUser } = this.state
    return (
      <Modal
        open={this.state.editUserModal}
        onClose={this.handleEditUserModalClose}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Container sx={{ ...webStyles.containerModal, height: "95%", gap: '10px' }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            padding="10px 0"
            borderBottom="1px solid #F0F0F0"
          >
            <Typography
              style={{
                fontSize: '24px',
                fontFamily: 'Heebo',
                lineHeight: '32px',
                letterSpacing: '0.5%',
                fontWeight: '700',
              }}
            >
              Edit Admin
            </Typography>
            <IconButton data-testID="closeEditModal" onClick={this.handleEditUserModalClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box
            height="80%"
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            gap="10px"
          >
            <FormControl fullWidth>
              
            </FormControl>
            <Box>
              <Typography style={webStyles.modalText}>First Name</Typography>
              <TextField
                fullWidth
                data-testID="editFirstName"
                value={editUser.firstName}
                onChange={this.handleInputChange("firstName")}
              />
            </Box>
            <Box>
              <Typography style={webStyles.modalText}>Last Name</Typography>
              <TextField
                data-testID="editLastName"
                fullWidth
                value={editUser.lastName}
                onChange={this.handleInputChange("lastName")}
              />
            </Box>
            <Box>
              
            </Box>
            <Box>
              <Typography style={webStyles.modalText}>Email</Typography>
              <TextField
                data-testID="editEmail"
                fullWidth
                value={editUser.email}
                onChange={this.handleInputChange('email')}
              />
            </Box>
            <Box>
              <Typography style={webStyles.modalText}>Role</Typography>
              <Typography style={webStyles.modalText}>{editUser.role}</Typography>
            </Box>
          </Box>

          <Box
            display="flex"
            justifySelf="end"
            gap="16px"
            paddingTop="20px"
            justifyContent="end"
            borderTop="1px solid #F0F0F0"
          >
            <Button
              variant="outlined"
              style={{ color: 'black', backgroundColor: '#F0F0F0', border: 'none' }}
              onClick={this.handleEditUserModalClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{ color: 'white', backgroundColor: 'black' }}
              onClick={this.handleEditUserDoneClick}
              data-testID="editDoneBtn"
            >
              Done
            </Button>
          </Box>
        </Container>
      </Modal>
    )
  }

  DeleteModal = () => {
    const { currentUser } = this.state

    return (
      <Modal open={this.state.deleteUserModal} onClose={this.handleDeleteUserModalClose}>

        <Container sx={{ ...webStyles.containerModal, height: '70%' }}>
          <Box display="flex" justifyContent="end" alignItems="center" padding="10px 0">
            <IconButton data-testID="close-Modal" onClick={this.handleDeleteUserModalClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box display={"flex"} gap={"10px"} flexDirection={"column"} justifyContent={'space-between'}>
            <Typography style={{ fontFamily: 'Heebo', fontSize: '24px', fontWeight: '700', lineHeight: '32px', color: '#0F172A' }} >
              Are you sure you want to delete  {currentUser?.attributes.first_name}<br />{currentUser?.attributes.last_name}'s account?
            </Typography>
            <Typography style={{ fontFamily: 'Heebo', fontWeight: '400', fontSize: '20px', lineHeight: '32px', color: '#475569' }}>
              This action can't be undone and all the data stored in this <br /> account won't be accessible anymore.
            </Typography>
          </Box>

          <Box
            display="flex"
            justifySelf="end"
            paddingTop="20px"
            gap="16px"
            borderTop="1px solid #F0F0F0"
            justifyContent="end"
          >
            <Button
              variant="outlined"
              style={{
                color: 'black', backgroundColor: '#F0F0F0', border: 'none',
                padding: "10px 35px",
                textTransform: "none",
                borderRadius: '10px',
                fontSize: "16px",
                fontWeight: "700"
              }}
              onClick={this.handleDeleteUserModalClose}
            >
              No
            </Button>
            <Button
              variant="contained"
              style={{
                color: 'white', backgroundColor: 'black', padding: "10px 35px",
                textTransform: "none",
                borderRadius: '10px',
                fontSize: "16px",
                fontWeight: "700"
              }}
              onClick={() => this.handleDeleteUser(this.state.currentUser?.id ?? "")}
              data-testID="deleteBtn"
            >
              Yes
            </Button>
          </Box>
        </Container>
      </Modal>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Box width={"100%"} display={"flex"} >
            <Box >
              <Sidebar navigation={this.props.navigation} current='user_management' />
            </Box>
            <Box display={"flex"} padding={"30px"} width={"80%"} flexDirection={"column"} gap={"20px"}>
              <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                <Box display={"flex"} gap={"10px"} alignItems={"center"}>
                  <Typography style={{ fontFamily: 'Heebo', fontWeight: '400', fontSize: '24px', lineHeight: '32px', color: '#475569' }}>User management</Typography>
                  <KeyboardArrowRight />
                  <Typography style={{ fontFamily: 'Heebo', fontSize: '24px', fontWeight: '700', lineHeight: '32px', color: '#0F172A' }} >Admin</Typography>
                </Box>
                <Box display={"flex"} justifyContent={"space-between"} gap={"8px"}>
                  <Button
                    sx={{ fontFamily: 'Heebo', fontSize: '16px', textTransform: 'none', fontWeight: '400', color: '#000000', padding: '10px 16px', borderRadius: '8px', border: '1px solid black' }}
                    data-testID="template-btn" >
                    Download Template
                  </Button>
                  <Button
                    sx={{ fontFamily: 'Heebo', fontSize: '16px', textTransform: 'none', fontWeight: '400', color: '#000000', padding: '10px 16px', display: 'flex', gap: '8px' }}
                    data-testID="export-btn" >
                    <img src={exportIcon} alt="" />
                    <span>
                      Export
                    </span>
                  </Button>
                  <Button
                    sx={{ fontFamily: 'Heebo', fontSize: '16px', textTransform: 'none', fontWeight: '400', color: '#000000', padding: '10px 16px', borderRadius: '8px', border: '1px solid black' }}
                    data-testID="upload-btn" >
                    Bulk upload CSV
                  </Button>
                  <AddNewUserButton
                    startIcon={<GroupAdd />}
                    onClick={this.handleAddNewUserModalOpen}
                    data-testID="AddNewUser"
                  >Add new user
                  </AddNewUserButton>
                </Box>
              </Box>
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%'
              }} >
               
                <Box style={{width:'70%'}}>
                  <TextField
                    variant="outlined"
                    placeholder="Search by user"
                    style={{
                      borderRadius: '4px',
                      border: "#E7E7E7 1px solid"
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                          height: '40px', 
                          '& fieldset': {
                              borderRadius: '4px',
                          },
                          '& input': {
                              padding: '4px 8px',
                          },
                      },
                  }}
                    value={this.state.searchQuery}
                    onChange={this.handleSearchQuery}
                    data-testID="searchQuery"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                </Box>
                <Box display={'flex'} gap={"5px"} alignItems={'center'}>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '5px',
                    alignItems: 'center'
                  }}>

                    <Typography
                      style={{ fontFamily: 'Heebo', fontWeight: '700', fontSize: '14px', color: '#334155' }}
                    >Filter</Typography>
                    <IconButton data-test-id='btnFilter'
                    >
                      <FilterList />
                    </IconButton>
                  </Box>
                  

                  <Box>Sort by :</Box>
                  <Box
                    data-testID='btnSort'
                    onClick={this.handlePopoverClick}
                    style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}
                  >
                    {this.state.currentSortKey}
                    <KeyboardArrowDown />
                  </Box>

                  <Popover
                    data-test-id='btnPopover'
                    open={this.isSortPopoverOpen()}
                    anchorEl={this.state.anchorElSort}
                    onClose={this.handlePopoverClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    style={{ boxShadow: 'none' }}
                  >
                    {this.sortKeys.map((item, index) => (
                      <MenuItem
                        key={'sortId' + index}
                        data-testID={`btnOptions${index}`}
                        onClick={() => this.handleSort(item)}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Popover>
                </Box>
              </Box>

              {
                this.state.deleteArr.length > 0 &&
                <Box display={"flex"} justifyContent={"space-between"} width={"25%"} alignItems={"center"}>
                  <Custombox
                    checked={this.state.deleteArr.length > 0}
                    data-testID="deselectAll"
                    onChange={this.handleDeselectAll}
                  />
                    <Typography>
                      {this.state.deleteArr.length}{" "}
                      {this.state.deleteArr.length === 1 ? "item" : "items"} selected
                    </Typography>
                  <Button
                  style={{
                    fontFamily:'Heebo',
                    fontWeight:'700',
                    textTransform:'none',
                    lineHeight:'24px',
                    color:'#DC2626',
                    border:"#F87171 1px solid",
                    borderRadius:'4px',
                    padding:'8px',
                  }} 
                  onClick={this.handleBulkDelete}
                  data-testID="bulkDelete"
                  >
                    Delete
                  </Button>
                </Box>
              }
              <Box>
                {this.state.userData.length > 0 ? 
                <TableContainer component={Box} style={{ width: '100%', maxHeight: '800px', overflowY: 'scroll' }}>
                  <style>
                    {`
                    .MuiTableCell-root{
                      border="none"
                    }
                    `}
                  </style>
                  <Box style={{ width: '100%', overflowX: 'hidden' }}>
                    <Table aria-label="sticky table" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TabCellHeader></TabCellHeader>
                          <TabCellHeader>First and Last Name</TabCellHeader>
                          <TabCellHeader>Email</TabCellHeader>
                          <TabCellHeader>Role</TabCellHeader>
                          <TabCellHeader>Date Active</TabCellHeader>
                          <TabCellHeader></TabCellHeader>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.userData.map((user, i) => (
                          <TableRow 
                          key={i}
                          style={{
                            backgroundColor: this.isUserSelected(user.id)  ? '#f8fafc' : 'white',
                          }}
                          >
                            <TabCells>
                              <CustomCheckbox
                                checked={this.isUserSelected(user.id)}
                                onChange={() => { this.handleCheckboxChange(user.id) }}
                                color="info"
                                data-testID={`checkbox${i}`}
                              />
                            </TabCells>
                            <TabCells style={{ whiteSpace: 'nowrap' }}>{`${user.attributes.first_name} ${user.attributes.last_name}`}</TabCells>
                            <TabCells>{user.attributes.email}</TabCells>
                            <RoleTabCell>
                              <Typography
                                style={{
                                  fontFamily: 'Heebo', padding: '2px 8px', color: '#ffffff'
                                  , textTransform: 'uppercase',
                                  textAlign: 'center', fontSize: '12px', borderRadius: '40px', backgroundColor: '#000000', width: '55px',fontWeight:'700'
                                }}
                              >
                                ADMIN
                              </Typography>
                            </RoleTabCell>
                            <TabCells>{this.convertDate(user.attributes.created_at)}</TabCells>
                            <TabCells>
                              <IconButton data-testID="userOptions" onClick={(event) => this.handleUserOptionClick(event, user)}>
                                <MoreVertIcon />
                              </IconButton>
                              <Box>
                                <Popover
                                  sx={{
                                    "& .MuiPaper-root": {
                                      boxShadow: "0px 2px 8px 0px #0000000F",
                                      borderRadius: "4px", 
                                      width:'200px'
                                    },
                                  }}
                                  onClose={this.handlePopoverClose}
                                  anchorEl={this.state.userAnchorEl}
                                  open={this.handleCloseUserOption()}
                                  anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'left',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                >
                                  <MenuList>
                                    <MenuItem data-testID="viewModal" onClick={this.handleViewUserModalOpen}>View</MenuItem>
                                    <MenuItem data-testID="editModal" onClick={this.handleEditUserModalOpen}>Edit</MenuItem>
                                    <MenuItem sx={{color:'#DC2626'}} data-testID="deleteModal" onClick={this.handleDeleteUserModalOpen}>Delete</MenuItem>
                                  </MenuList>
                                </Popover>
                                
                              </Box>
                            </TabCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </TableContainer>
                : 
                <Box display={"flex"} justifyContent={"center"}>
                  No record found.
                </Box>
                }

              </Box>
              <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'center' }}>
                <Pagination
                  count={this.state.totalPages}
                  page={this.state.currentPage}
                  onChange={this.handlePageChange}
                  shape="rounded"
                  data-testID="pageChange"
                />
              </Box>
            </Box>
            {this.BulkUploadCsv()}
            {this.AddNewUserModal()}
            {this.EditUserModal()}
            {this.DeleteModal()}
            {this.ViewUserModal()}
          </Box>
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const AddNewUserButton = styled(Button)({
  fontFamily: 'Heebo',
  fontSize: '16px',
  textTransform: 'none',
  fontWeight: '400',
  color: '#fff',
  padding: '10px 16px',
  borderRadius: '8px',
  backgroundColor: '#000000',
  "&:hover": {
    backgroundColor: "black",
  },
});

const TabCellHeader = styled(TableCell)({
  fontFamily: 'Heebo-Bold',
  color: '#475569',
  fontWeight: 700,
  fontSize: '14px',
  backgroundColor: 'white', zIndex: 1
})

const TabCells = styled(TableCell)({
  fontFamily: 'Heebo-Regular',
  color: '#0F172A',
  fontWeight: 400,
  fontSize: '14px'
})

const RoleTabCell = styled(TableCell)({
  fontWeight: 700, lineHeight: '18px'
})

const CustomCheckbox = styled(Checkbox)({
  '& .MuiSvgIcon-root': {
    width: '20px',
    backgroundColor: 'white',
    height: '20px',
    borderRadius: '4px',
    border: '1px solid black',
  },
  '&:not(.Mui-checked) .MuiSvgIcon-root': {
    color: 'transparent',
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      borderColor: 'black',
      backgroundColor: 'black',
    },
    '& .MuiSvgIcon-root path': {
      d: 'path("M5 12l5 5L20 7")',
      strokeWidth: 2,
      stroke: 'white',
      fill: 'none',
    },
  },
});

const Custombox = styled(Checkbox)({
  '&:not(.Mui-checked) .MuiSvgIcon-root': {
    color: 'transparent',
  },
  '& .MuiSvgIcon-root': {
    width: '20px',
    height: '20px',
    backgroundColor: 'white',
    border: '1px solid black',
    borderRadius: '4px',
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      borderColor: 'black',
      backgroundColor: 'black',
    },
    '& .MuiSvgIcon-root path': {
      d: 'path("M6 12h12")',
      strokeWidth: 2,
      stroke: 'white',
      fill: 'none',
    },
  },
});

const webStyles = {
  containerModal: {
    position: 'absolute',
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 8px 32px 0px #0000000F",
    width: '600px',
    padding: '24px',
    height: "85vh",
    borderRadius: '8px 8px 32px 8px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    justifyContent: 'space-between',
  },
  modalText: {
    font: 'Heebo',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#334155'
  }
}
// Customizable Area End
