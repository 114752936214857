// Customizable Area Start
import React from "react";
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { createTheme, styled, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import {
  Box,
  Button,
} from "@mui/material";
import Palette from '../palette';
import IconButton from '@mui/material/IconButton';
import { EditorContent } from '@tiptap/react';
import EditorToolbar from './EditorToolbar.web';
import {
  addIcon,
  removeIcon,
  arrowBottomIcon,
} from "./assets";
// Customizable Area End

// Customizable Area Start
import { Card } from "./HelpCenterAdminPartTypes";
import HelpCenterAdminPartController, {
  Props,
} from "./HelpCenterAdminPartController";

const ids = {
  navbarLink: 'navbar-link',
  navbarLogout: 'navbar-logout',
  saveBtn: 'save-btn',
  faqItem: 'faq-item',
  faqButton: 'faq-button',
  faqToggler: 'faq-toggler',
  faqRemove: 'faq-remove',
  faqAdd: 'faq-add',
  faqTitle: 'faq-title',
  faqButtons: 'faq-buttons',
  faqDetails: 'faq-details',
  editorTitleContainer: 'editor-title-container',
  editorTitle: 'editor-title',
  editorContent: 'editor-content',
};

const theme = createTheme(
  {
    palette: {
      backgrounds: {
        main: "#FAFAFA",
        light: "#FFFFFF",
        dark: "#000000",
      },
      texts: {
        main: "#0F172A",
        light: "#475569",
        dark: "#334155",
        contrastText: "#1C1C1C",
      },
      danger: {
        main: "#FF7468",
      },
      borders: {
        main: "#D6D3D1",
        light: "#F5F5F5",
      },
    },
  });
// Customizable Area End


export default class HelpCenterAdminPart extends HelpCenterAdminPartController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTitleForFAQ = (htmlContent: string) => {
    return (
      <StyledTitle
        data-test-id={ids.faqTitle}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
    );
  };

  renderFAQTitle = (isActiveCard: boolean, title: string) => isActiveCard ? this.renderEditorTitleSection(isActiveCard) : this.renderTitleForFAQ(title);

  EditorSection = () => {
    return (
      <>
        {this.state.activeEditor === 'content' && (
          <EditorToolbar
            editor={this.editorContent}
            isEditing={this.state.isEditing}
            toolbarPosition={this.state.toolbarPosition}
            toolbarRef={this.toolbarRef}
          />
        )}
        <div ref={this.editorContentRef}>
          <StyledEditorContent>
            <EditorContent data-test-id={ids.editorContent} editor={this.editorContent} />
          </StyledEditorContent>
        </div>
      </>
    );
  };

  EditorTitleSection = () => {
    return (
      <div data-test-id={ids.editorTitleContainer} onClick={(event) => event.stopPropagation()}>
        {this.state.activeEditor === 'title' && (
          <EditorToolbar
            editor={this.editorTitle}
            isEditing={this.state.isEditing}
            toolbarPosition={this.state.toolbarPosition}
            toolbarRef={this.toolbarRef}
          />
        )}
        <div ref={this.editorTitleRef}>
          <StyledEditorTitle>
            <EditorContent data-test-id={ids.editorTitle} editor={this.editorTitle} />
          </StyledEditorTitle>
        </div>
      </div>
    );
  };

  renderEditorSection = (isActiveCard: boolean) => isActiveCard ? this.EditorSection() : null;
  renderEditorTitleSection = (isActiveCard: boolean) => isActiveCard ? this.EditorTitleSection() : null;

  renderFAQItem = (card: Card) => {
    const isActiveCard = this.state.activeCardId === card.id;
    return (
      <FAQItem
        data-test-id={ids.faqItem}
        key={card.id}
        isEdit={isActiveCard}
        expanded={isActiveCard}
      >
        <FAQSummary
          data-test-id={ids.faqToggler}
          onClick={() => this.handleCardClick(card.id)}
        >
          {this.renderFAQTitle(isActiveCard, card.attributes.title)}
          <FAQButtons data-test-id={ids.faqButtons}>
            <IconButton disabled>
              <FAQToggler
                isActive={isActiveCard}
                src={arrowBottomIcon}
                alt="toggler"
              />
            </IconButton>
            {isActiveCard && (
              <FAQRemoveButton data-test-id={ids.faqRemove} onClick={() => this.removeFAQ(card.id)}>
                <FAQRemoveIcon src={removeIcon} alt="remove" />
              </FAQRemoveButton>
            )}
          </FAQButtons>
        </FAQSummary>
        <AccordionDetails
          data-test-id={ids.faqDetails}
          sx={{
            '&.MuiAccordionDetails-root': {
              padding: '0px',
            }
          }}
        >
          {this.renderEditorSection(isActiveCard)}
        </AccordionDetails>
      </FAQItem>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End

    return (
      // Customizable Area Start
      <div id="main_block" style={{display:'flex',width:'100%'}}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Container>
              <Main>
                <FAQList>
                  {this.state.cards.map((card) => (
                    this.renderFAQItem(card)
                  ))}
                  <AddFAQButton
                    data-test-id={ids.faqAdd}
                    startIcon={<AddFAQIcon src={addIcon} alt="add" />}
                    onClick={this.addFAQ}
                  >
                    Add new
                  </AddFAQButton>
                </FAQList>
              </Main>
            </Container>
          </ThemeProvider>
        </StyledEngineProvider>
      </div>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const Main = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  padding: '0px 16px 48px',
});

const FAQList = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

const FAQItem = styled(Accordion)<{ isEdit: boolean }>(({ isEdit }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  backgroundColor: theme.palette.backgrounds.light,
  padding: '16px 20px 16px 24px',
  borderRadius: '8px',
  borderLeft: isEdit ? `4px solid ${theme.palette.backgrounds.dark}` : 'none',
  '&.MuiPaper-root.MuiAccordion-root': {
    position: 'static',
    boxShadow: 'none',
  }
}));

const FAQSummary = styled(AccordionSummary)({
  minHeight: '24px',
  margin: '0px',

  '& .MuiAccordionSummary-content': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '24px',
    flexGrow: 1,
    minHeight: '24px',
    maxHeight: 'max-content',
    margin: '0px',
  },
  '&.MuiAccordionSummary-root.Mui-focusVisible': {
    backgroundColor: theme.palette.backgrounds.light,
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '0px'
  },
  '&.MuiButtonBase-root.MuiAccordionSummary-root': {
    position: 'static',
    padding: '0px',
  },
  '&.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded': {
    minHeight: '24px',
    marginBottom: '16px',
  },
});

const FAQButtons = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

const FAQToggler = styled('img')<{ isActive: boolean }>(({ isActive }) => ({
  maxWidth: '24px',
  maxHeight: '24px',
  transform: `rotate(${isActive ? '180deg' : '0deg'})`,
  transition: 'transform .3s ease',
}));

const FAQRemoveButton = styled(IconButton)({
  color: theme.palette.texts.main,
  backgrond: 'transparent',
  boxShadow: 'none',
  padding: '0px',
  borderRadius: '8px',
  maxWidth: '24px',
  maxHeight: '24px',
  '& .css-1d6wzja-MuiButton-startIcon': {
    margin: 0,
  },
});

const FAQRemoveIcon = styled('img')({
  maxWidth: '24px',
  maxHeight: '24px',
});

const StyledEditorTitle = styled('div')({
  fontSize: '16px',
  fontWeight: '700',
  borderRadius: '8px',
  paddingRight: '16px',
  maxWidth: '100%',
  boxSizing: 'border-box',
  '& .ProseMirror-focused': {
    border: 'none',
    outline: 'none',
  },
  '& .ProseMirror > p': {
    margin: '0px',
  },
});

const StyledTitle = styled('div')({
  fontSize: '16px',
  fontWeight: '700',
  color: theme.palette.texts.main,
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
  '& > p': {
    margin: '0px',
  }
});

const StyledEditorContent = styled('div')({
  border: `1px solid ${theme.palette.borders.main}`,
  borderRadius: '8px',
  padding: '10px',
  boxSizing: 'border-box',
  maxWidth: '100%',
  '& .ProseMirror-focused': {
    border: 'none',
    outline: 'none',
  },
  '& .ProseMirror > p': {
    margin: '0px',
  },
  '& .ProseMirror > .is-editor-empty::before': {
    content: 'attr(data-placeholder)',
    color: '#aaa',
    float: 'left',
    height: '0',
    pointerEvents: 'none',
  },
});

const AddFAQButton = styled(Button)({
  fontSize: '16px',
  fontWeight: '700',
  padding: '16px 24px',
  color: theme.palette.texts.main,
  textTransform: 'none',
  maxWidth: 'max-content',
});

const AddFAQIcon = styled('img')({
  maxWidth: '24px',
  maxHeight: '24px',
});
// Customizable Area End
