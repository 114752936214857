import React from 'react'
import UserAnalyticsController, { Props } from "./UserAnalyticsController.web";
import {
  Button,
  Typography,
  Box,
  LinearProgress,
  TableRow,
  Grid,
  Table,
  TableHead,
  TableContainer,
  Paper,
  Tabs, Tab,
  AppBar,
  TableBody,
  Pagination,
  TextField,
  InputAdornment
} from "@mui/material";
import {
  FilterList,
  Search,
  KeyboardArrowDown
} from '@mui/icons-material';
import Sidebar from "../../../../packages/components/src/sidebar/Sidebar.web";
import AdminFooter from '../../../../packages/components/src/AdminFooter.web';
import Chart from "react-google-charts";
import { CardOverView, LegendBox, LegendLine, MapContainer, PieChartBox, StyledTableCell, StyledTableRow, webStyles } from "./CourseAnalytics.web";
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import {ImageSorting} from './assets'

const theme2 = createTheme({
  palette: {
    secondary: {
      main: '#fff'
    },
    primary: {
      contrastText: "#fff",
      main: "#000",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
    h6: {
      fontWeight: 500,
    },
  },
});
export default class UserAnalytics extends UserAnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  renderHeaderWebUser = () => {
    return (
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} minHeight={"90px"}>
        <Box display={"flex"} gap="10px" alignItems={"center"}>
          <Typography
            style={{ fontFamily: 'Heebo',
              lineHeight: '32px', 
              fontSize: '24px', 
              fontWeight: '700', 
               color: '#1C1C1C'
               }}
          >Analytics
          </Typography>
          <>
            <Typography
              style={{ 
                fontWeight: '700', 
                fontFamily: 'Heebo', 
                color: '#475569', 
                fontSize: '16px', 
                lineHeight: '24px', 
              }}
            >
              Jul 30 - Aug 5
              </Typography>
            <KeyboardArrowDown />
          </>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"} gap={"8px"}>
          <Button
            style={{ fontFamily: 'Heebo', 
              padding: '10px 16px', 
              lineHeight: '24px',
              borderRadius: '4px', 
              fontWeight: '700', 
              fontSize: '16px', 
              color: '#fff', 
               backgroundColor: '#000', 
               textTransform: 'none'
               }}
          >Export data
          </Button>
        </Box>
      </Box>
    )
  }
 
  renderSearchSectionUser = () => {
    return (
      <Box display={"flex"} gap={"20px"} alignItems={"center"} height={"60px"}>
        <Box style={{
          width: '100%', height: '100%'
        }}>
            <TextField
              variant="outlined"
              placeholder="Search users"
              style={{
                borderRadius: '4px',
                backgroundColor:'#fff',
                border: "#E7E7E7 1px solid",
              }}
              sx={{
                '& input::placeholder':{
                  opacity:'1'
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderRadius: '4px',
                  },
                },
              }}
              value={""}
              data-testID="searchQuery"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />

        </Box>
        <Box height={"100%"}>
          <Button
            data-testID="filter-button"
            style={{
              borderRadius: '4px',
              padding: '10px 16px', 
              fontWeight: '700',
              fontFamily: 'Heebo', 
              alignItems: 'center', 
              height: '100%',
              display: 'flex', 
              textAlign: 'center', 
              gap: '10px',
              fontSize: '16px', lineHeight: '24px',
              textTransform: 'none',
              backgroundColor: 'transparent', color: '#000',
            }}
            onClick={() => { }}
          >
            <Typography
              style={{ fontFamily: 'Heebo', 
                fontSize: '14px', 
                fontWeight: '700', 
                color: '#000' }}
            >
              Filter
            </Typography>
            <FilterList />
          </Button>
        </Box>
      </Box>
    )
  }
  renderPieChartUser = () => {
    const dataPie = [
      ["Devices", "Number"],
      ["Other", 25],
      ["Mobile", 40],
      ["Desktop", 90,],
      ["Tablet", 50],
    ];
    const optionsPie = {
      is3D: false,
      colors: ['#7B61FF', '#0099FF', '#F59E0B', '#9D9D9D'],
      pieHole: 0.7,
      legend: "none",
      pieSliceText: 'none',

    };
    const renderLegend2 = () => {
      return (
        <LegendBox flexDirection={'column'} flex={1} justifyContent={'center'} display={'flex'} >
          {dataPie.slice(1).map(legend =>{
            return (
              <LegendLine>
                <div>
                  <div className={`legend-icon ${legend[0]}`}></div>
                  <p className="legend-title">{legend[0]}</p>
                </div>
                <p className="legend-value">{legend[1]}</p>
              </LegendLine>
            )
          })}

        </LegendBox>
      )
    }
    return (
      <CardOverView sx={{ display: 'flex', 
      flexDirection: 'column', 
      }}>
        <Box display={'flex'} flexDirection={'column'} >
          <Typography style={webStyles.titleOverview}>Devices breakdown</Typography>
          <Typography style={webStyles.contentOverview}>200 Devices</Typography>
        </Box>
        <PieChartBox 
        height={'100%'} 
        display={'flex'}
         flex={2} 
         overflow={'hidden'}>
          <Box flex={1} >
            <Chart 
            chartType="PieChart" 
            width="100%" 
            height={'100%'}
             data={dataPie} 
             options={optionsPie} />
          </Box>
          {renderLegend2()}
        </PieChartBox>
      </CardOverView>
    )
  }
  renderAnalyticsOverviewUser = () => {

    return (
      <Grid   spacing={2} container sx={{  height: '300px' ,marginTop: '24px',}}>
        <Grid 
        style={{ maxHeight: '150px' }} 
        item 
        xs={3}>
          <CardOverView display={'flex'} flexDirection={'column'}>
            <Typography style={webStyles.titleOverview}>Courses Assigned</Typography>
            <Typography style={webStyles.contentOverview}>1000</Typography>

          </CardOverView>
        </Grid>
        <Grid 
        style={{ maxHeight: '150px' }} 
        item 
        xs={3}>
          <CardOverView display={'flex'} flexDirection={'column'}>
            <Typography style={webStyles.titleOverview}>Courses Completed</Typography>
            <Typography style={webStyles.contentOverview}>700</Typography>

          </CardOverView>
        </Grid>
        <Grid 
        item
        style={{ maxHeight: '150px' }} 
         xs={3}>
          <CardOverView display={'flex'} flexDirection={'column'}>
            <Typography style={webStyles.titleOverview}>Courses Left to Complete</Typography>
            <Typography style={{ ...webStyles.contentOverview, color: '#F59E0B' }}>300</Typography>
          </CardOverView>
        </Grid>
        <Grid style={{ maxHeight: '150px' }} 
        xs={3}
        item 
        >
          <CardOverView flexDirection={'column'} display={'flex'} >
            <Typography style={webStyles.titleOverview}>Total Learning Time</Typography>
            <Typography style={webStyles.contentOverview}>124h</Typography>

          </CardOverView>
        </Grid>
        <Grid
         item 
         style={{ maxHeight: '150px' }} 
         xs={4}>
          <CardOverView display={'flex'} flexDirection={'column'}>
            <Typography style={webStyles.titleOverview}>Assessment Attempts</Typography>
            <Typography style={{ ...webStyles.contentOverview, color: '#F59E0B' }}>120</Typography>

          </CardOverView>
        </Grid>
        <Grid style={{ maxHeight: '150px' }} item xs={4}>
          <CardOverView display={'flex'} flexDirection={'column'}>
            <Typography style={webStyles.titleOverview}>Average Assessment Score</Typography>
            <Typography style={{ ...webStyles.contentOverview, color: "#FC5555" }}>34%</Typography>

          </CardOverView>
        </Grid>
        <Grid style={{ maxHeight: '150px' }} item xs={4}>
          <CardOverView display={'flex'} flexDirection={'column'}>
            <Typography style={webStyles.titleOverview}>Average Time To Complete a Course</Typography>
            <Typography style={{ ...webStyles.contentOverview, color: "#059669" }}>2h</Typography>

          </CardOverView>
        </Grid>
      </Grid>
    )
  }

  renderWorldMapChart = () => {
    const mapData = [
      ["Country", "User"],
      ["Spain", 16],
      ["United States", 22],
      ["England", 25],
      ["Amsterdam", 31],
      ["Argentina", 48],
    ];
    const userData = [
      { location: 'SP, Madrid', value: 16 },
      { location: 'UK, London', value: 22 },
      { location: 'USA, Florida', value: 25 },
      { location: 'NL, Amsterdam', value: 31 },
      { location: 'USA, New York', value: 48 },
    ]
    return (
      <Grid  style={{ boxSizing: 'border-box', marginTop: '10px',marginBottom: '20px', overflow: 'hidden',  }} container spacing={2}>
        <Grid item xs={8} >
          <CardOverView >
            <Typography style={webStyles.titleOverview}>Location</Typography>
            <Typography style={webStyles.contentOverview}>142 <span style={{fontSize:'16px'}}>Users</span></Typography>
            <Box display={'flex'} height={'100%'} maxHeight={'250px'} >
              <MapContainer style={{ flex: '1', overflow:'scroll' }}>
                <Chart
                  chartType="GeoChart"
                  width="100%"
                  height='100%'
                  data={mapData}
                  options={{ 
                    legend: 'none',
                    colorAxis: { colors: ["#BABABA", "#BFBFBF", "#B1B1B1",'#AAAAAA','#969696','#626262'] }, 
                    datalessRegionColor:'#BABABA' }}
                />
              </MapContainer>
              <Box flex={1} px={'20px'}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} pb={'10px'} mb={'10px'}  borderBottom={'1px solid #F0F0F0'} sx={{color:'#6A6A6A'}}>
                  <Typography fontSize={12}>Top 5 users locations</Typography>
                  <Typography fontSize={12}>Users</Typography>
                </Box>
                {userData.map(item=>{
                  return (
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} key={item.location}>
                      <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                        <Typography sx={{ mb: 1 }}>
                          {item.location}
                        </Typography>
                        <LinearProgress
                          variant="determinate"
                          value={item.value / 50 * 100}
                          sx={{
                            height: '5px',
                              borderRadius:'5px',
                              backgroundColor: 'white',
                            '& .MuiLinearProgress-bar': {
                              backgroundColor: 'black',
                              borderRadius:'5px'
                            },
                          }} />
                      </Box>
                      <Typography>{item.value}</Typography>
                    </Box>
                  )
                })}
                </Box>
              </Box>
          </CardOverView>
        </Grid>
        <Grid item xs={4} >
          {this.renderPieChartUser()}
        </Grid>
      </Grid>
    )
  }
  renderTableData = () => {
   
    
    return (
      <TableContainer component={Paper}>
        <Box overflow={'scroll'}>
          <Table
          aria-label="customized table"
          sx={{ minWidth: 1050,
            borderRadius: '5px' ,
            }}
            >
            <TableHead>
              <TableRow>
                <StyledTableCell><div style={{ ...webStyles.cellHeader, minWidth: '120px' }}>First and Last Name <img src={ImageSorting} onClick={() => this.handleSort("fullName")} alt={`sort-btn`} /></div> </StyledTableCell>
                <StyledTableCell >
                  <div style={webStyles.cellHeader}>Course
                    <img src={ImageSorting} onClick={() => this.handleSort("course")} alt={`sort-btn`} />
                  </div>
                </StyledTableCell>
                <StyledTableCell ><div style={webStyles.cellHeader}>Active Time
                  <img src={ImageSorting} onClick={() => this.handleSort("activeTime")} alt={`sort-btn`} />
                </div>
                </StyledTableCell>
                <StyledTableCell ><div style={webStyles.cellHeader}>Completed
                  <img src={ImageSorting} onClick={() => this.handleSort("completed")} alt={`sort-btn`} />
                </div>
                </StyledTableCell>
                <StyledTableCell ><div style={webStyles.cellHeader}>Device
                  <img src={ImageSorting} onClick={() => this.handleSort("device")} alt={`sort-btn`} />
                </div>
                </StyledTableCell>
                <StyledTableCell ><div style={webStyles.cellHeader}>Location
                  <img src={ImageSorting} onClick={() => this.handleSort("location")} alt={`sort-btn`} />
                </div>
                </StyledTableCell>
                <StyledTableCell style={{minWidth:'130px'}}><div style={webStyles.cellHeader}>Client
                  <img src={ImageSorting} onClick={() => this.handleSort("client")} alt={`sort-btn`} />
                </div>
                </StyledTableCell>
                <StyledTableCell style={{minWidth:'130px'}}><div style={webStyles.cellHeader}>Date started
                  <img src={ImageSorting} alt={`sort-btn`} onClick={() => this.handleSort("dateStarted")}/>
                </div>
                </StyledTableCell>
                <StyledTableCell style={{minWidth:'130px'}}><div style={webStyles.cellHeader}>Date Completed
                  <img src={ImageSorting} alt={`sort-btn`} onClick={() => this.handleSort("dateCompleted")}/>
                </div>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.tableData.slice(this.state.currentPage * 10, this.state.currentPage * 10 + 10)
                .map((data:any) => (
                  <StyledTableRow key={data.id}>
                    <StyledTableCell >{data.fullName}</StyledTableCell>
                    <StyledTableCell style={{minWidth:'150px'}}>
                      <div style={webStyles.courseCell}> {data.course}</div>
                    </StyledTableCell>
                    <StyledTableCell >{data.activeTime}</StyledTableCell>
                    <StyledTableCell >{data.completed}</StyledTableCell>
                    <StyledTableCell >{data.device}</StyledTableCell>
                    <StyledTableCell >{data.location}</StyledTableCell>
                    <StyledTableCell >{data.client}</StyledTableCell>
                    <StyledTableCell >{data.dateStarted}</StyledTableCell>
                    <StyledTableCell >
                        {data.dateCompleted}
                      </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        <Box style={{ display: 'flex',
          justifyContent: 'space-between',
          width: '100%', 
          alignItems: 'center', 
          padding: '20px', 
           boxSizing: 'border-box' }}>
          <Box>
          </Box>
          <Pagination
            color="primary"
            count={Math.ceil(this.state.tableData.length / 10)}
            onChange={this.handleChangePageUserTable}
            sx={{
              '& .MuiPaginationItem-root':{
                textDecoration:'underline'
              },
              '& .MuiPaginationItem-ellipsis':{
                textDecoration:'unset ',
              },
              '& .Mui-selected, .Mui-selected:hover': {
                color: '#6200EA',
                textDecoration:'unset ',
                backgroundColor: 'transparent',
              },
            }}
            page={this.state.currentPage + 1}
            size="large"
          />
          <Typography>
            {`1 - ${Math.ceil(this.state.tableData.length / 10)} of ${this.state.tableData.length} results`}
          </Typography>
        </Box>
      </TableContainer>
    )
  }
  navConfig = [
    { label: 'User Analytics', value: 'UserAnalytics' },
    { label: 'Client-Wide Analytics', value: 'ClientWideAnalytics' },
    { label: 'Course Analytics', value: 'CourseAnalytics' },
    { label: 'Feedback Aggregation', value: "Analytics" },
    { label: 'Reports', value: '' },
  ];

  render() {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme2}>
          <Box display={"flex"} overflow={'hidden'}>
            <Box>
              <Sidebar navigation={this.props.navigation} current="analytics" />
            </Box>
            <Box
              gap={"16px"}
              flexDirection={"column"}
              padding={"24px 48px"}
              display={"flex"}
              width={"100%"}
              sx={{ backgroundColor: '#F0F0F0', overflow: 'auto' }}>
              {this.renderHeaderWebUser()}
              <Box width={"fit-content"}>
                <AppBar position="static" style={{ maxHeight:'60px',padding:'10px' ,width: "60vw", boxShadow: 'none', borderRadius: '4px' }} color="secondary">
                  <Tabs
                    data-testID="Tabs"
                    textColor="secondary"
                    value="UserAnalytics"
                    aria-label="analytics tabs"
                    TabIndicatorProps={{
                      style: {
                        display: 'none'
                      },
                    }}
                    style={{ gap: '10px' }}
                  >
                    {this.navConfig.map((tab, index) => (
                      <Tab
                      data-test-id={`tab-${index}`}
                        key={index}
                        onClick={() => this.handleChangeTabUserAnalytics(tab.value)}
                        label={
                          <Typography
                              sx={{
                                textTransform: 'none',
                                fontSize: '12px',
                                fontFamily: 'Heebo-bold',
                                lineHeight: '18px',
                                padding: '12px 0',
                                width:'max-content',
                                fontWeight: '700',
                              }}
                            >
                              {tab.label}
                            </Typography>
                        }
                        value={tab.value}
                        sx={{
                          '&.Mui-selected': {
                            backgroundColor: 'black',
                            color: 'white',
                            borderBottom: 'none',
                          },
                          borderRadius: '4px',
                          color: tab.value === 'UserAnalytics' ? 'white' : '#6A6A6A',
                          backgroundColor: tab.value === 'UserAnalytics' ? 'black' : 'inherit',
                          boxSizing:'border-box',
                          flex:1,
                          padding:'0 16px',
                          minHeight:0,
                          height:'100%',
                          maxHeight:'60px',
                        }}
                      />
                    ))}
                  </Tabs>
                </AppBar>
              </Box>
              <Box paddingTop={"20px"}>
                <Box width={"100%"} 
                flexDirection={'column'} 
                display={'flex'} 
                boxSizing={'border-box'} >
                  {this.renderSearchSectionUser()}
                  {this.renderAnalyticsOverviewUser()}
                  {this.renderWorldMapChart()}
                  {this.renderTableData()}
                </Box>
              </Box>
            </Box>
          </Box>
          <AdminFooter/>
        </ThemeProvider>
      </StyledEngineProvider>

    )
  }
}  
