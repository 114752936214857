import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentPage:number
  tableData:any
  sortConfig:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UserAnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
      ,getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      currentPage:0,
      tableData :[
        { 
          id: 1, 
          fullName: 'John Doe', 
          course: 'Data analytics', 
          activeTime: 70, 
          completed: 140, 
          device: 'Desktop', 
          location: 'UK', 
          client: 'Company A', 
          dateStarted: '01.12.24 09:00', 
          dateCompleted: '05.12.24 10:00' 
        },
        { 
          id: 4, 
          fullName: 'Jane Smith', 
          course: 'User research', 
          activeTime: 30, 
          completed: 54, 
          device: 'Desktop', 
          location: 'India', 
          client: 'Company B', 
          dateStarted: '06.12.24 08:00', 
          dateCompleted: '09.12.24 14:00' 
        },
        { 
          id: 2, 
          fullName: 'Alice Johnson', 
          course: 'UiUX', 
          activeTime: 30, 
          completed: 124, 
          device: 'Desktop', 
          location: 'Vietnam', 
          client: 'Company C', 
          dateStarted: '10.12.24 07:00', 
          dateCompleted: '15.12.24 16:00' 
        },
        { 
          id: 5, 
          fullName: 'Bob Brown', 
          course: 'User testing', 
          activeTime: 30, 
          completed: 3, 
          device: 'Mobile', 
          location: 'Spain', 
          client: 'Company D', 
          dateStarted: '06.12.24 12:00', 
          dateCompleted: '07.12.24 09:00' 
        },
        { 
          id: 3, 
          fullName: 'Charlie Green', 
          course: 'UiUX', 
          activeTime: 30, 
          completed: 53, 
          device: 'Desktop', 
          location: 'US', 
          client: 'Company E', 
          dateStarted: '30.12.24 08:00', 
          dateCompleted: '31.12.24 17:00' 
        },
        { 
          id: 6, 
          fullName: 'Emily Davis', 
          course: 'Data analytics', 
          activeTime: 30, 
          completed: 43, 
          device: 'Mobile', 
          location: 'US', 
          client: 'Company F', 
          dateStarted: '06.12.24 08:00', 
          dateCompleted: '08.12.24 11:00' 
        },
        { 
          id: 9, 
          fullName: 'Daniel Taylor', 
          course: 'Admin', 
          activeTime: 30, 
          completed: 123, 
          device: 'Desktop', 
          location: 'Thailand', 
          client: 'Company G', 
          dateStarted: '22.12.24 06:00', 
          dateCompleted: '28.12.24 19:00' 
        },
        { 
          id: 7, 
          fullName: 'Sophia Lee', 
          course: 'Data analytics', 
          activeTime: 30, 
          completed: 46, 
          device: 'Mobile', 
          location: 'US', 
          client: 'Company H', 
          dateStarted: '06.12.24 08:00', 
          dateCompleted: '10.12.24 15:00' 
        },
        { 
          id: 12, 
          fullName: 'Liam Anderson', 
          course: 'Admin', 
          activeTime: 38, 
          completed: 23, 
          device: 'Desktop', 
          location: 'US', 
          client: 'Company I', 
          dateStarted: '06.12.24 02:00', 
          dateCompleted: '07.12.24 05:00' 
        },
        { 
          id: 11, 
          fullName: 'Olivia Martinez', 
          course: 'Admin', 
          activeTime: 90, 
          completed: 167, 
          device: 'Desktop', 
          location: 'US', 
          client: 'Company J', 
          dateStarted: '12.12.24 15:00', 
          dateCompleted: '18.12.24 20:00' 
        },
        { 
          id: 10, 
          fullName: 'Ethan Harris', 
          course: 'Admin', 
          activeTime: 56, 
          completed: 235, 
          device: 'Desktop', 
          location: 'US', 
          client: 'Company K', 
          dateStarted: '07.12.24 09:00', 
          dateCompleted: '14.12.24 17:00' 
        },
        { 
          id: 8, 
          fullName: 'Mia Clark', 
          course: 'Admin', 
          activeTime: 30, 
          completed: 63, 
          device: 'Desktop', 
          location: 'US', 
          client: 'Company L', 
          dateStarted: '06.12.24 08:00', 
          dateCompleted: '09.12.24 18:00' 
        },
      ],
      sortConfig: {
        key: null,
        direction: "asc", // or "desc"
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
    if (firebase.apps.length !== 0) {
      const defaultAnalytics = firebase.app().analytics();
      defaultAnalytics.logEvent("Analytics::Web::Load");
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    
    // Customizable Area End
  }
  handleChangePageUserTable = (
    event: React.ChangeEvent<unknown>,
    newPage: number,)=>{
    this.setState({currentPage:newPage - 1})
  }

  handleChangeTabUserAnalytics=(value:string)=>{
    this.props.navigation.navigate(value)
  }
   handleSort = (key:string) => {
    this.setState((prevState) => {
      const { tableData, sortConfig } = prevState;
      const direction =
        sortConfig.key === key && sortConfig.direction === "asc"
          ? "desc"
          : "asc";

      const sortedData = [...tableData].sort((a, b) => {
        if (a[key] < b[key]) {
          return direction === "asc" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === "asc" ? 1 : -1;
        }
        return 0;
      });

      return {
        tableData: sortedData,
        sortConfig: { key, direction },
      };
    });
  };
}
