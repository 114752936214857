import React from "react";
// Customizable Area Start
import {
    Container,
    Button,
    Checkbox,
    TextField,
    Modal,
    Typography,
    Box,
    IconButton,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    InputAdornment,
    Pagination,
    Select,
    MenuItem,
    Popover, DialogTitle, Grid, InputBase,
    MenuList, Dialog, DialogActions, DialogContent,
} from "@mui/material";
import {
    createTheme,
    ThemeProvider,
    Theme,
    StyledEngineProvider,
    styled
} from "@mui/material/styles";
import * as Yup from "yup";
import { Formik } from "formik";
import "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FilterList, KeyboardArrowDown, KeyboardArrowRight, Search, GroupAdd } from '@mui/icons-material';
import AdminFooter from '../../../../packages/components/src/AdminFooter.web';
import Loader from "../../../components/src/Loader.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#000000",
            contrastText: "#fff",
        },
    },
});

import ClientManagementController, {
    Props,
} from "./ClientManagementController";
import { exportIcon, fileIcon, uploadIcon } from "./assets";
import Sidebar from "../../../../packages/components/src/sidebar/Sidebar.web";

declare module "@mui/styles/defaultTheme" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme { }
}

// Customizable Area End

export default class ClientManagement extends ClientManagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    userSchema = () => {
        return Yup.object().shape({
            firstName: Yup.string().required("*First name is required")
                .min(2,"*First name is too short (minimum is 2 characters)"),
            lastName: Yup.string().required("*Last name is required")
                .min(2,"*Last name is too short (minimum is 2 characters)"),
            jobTitle: Yup.string().required("*Job title is required")
                .min(2,"*Job title is too short (minimum is 2 characters)"),
            email: Yup.string().required("*Email is required")
                .email("*Please enter a valid email address"),
        });
    }

    DataTable = () => {

        const TabCellHeader = styled(TableCell)({
            fontFamily: 'Heebo-Bold', color: '#475569', fontWeight: 700, fontSize: '14px', backgroundColor: 'white', zIndex: 1
        })

        const TabCells = styled(TableCell)({
            fontFamily: 'Heebo-Regular', color: '#0F172A', fontWeight: 400, fontSize: '14px'
        })

        return (
            <TableContainer component={Box} style={{ width: '100%', maxHeight: '430px', overflowY: 'scroll' }}>
                <style>
                    {`
        .MuiTableCell-root{
          border="none"
        }
        `}
                </style>
                <Box style={{ width: '100%', overflowX: 'hidden' }}>
                    <Table aria-label="sticky table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TabCellHeader>#</TabCellHeader>
                                <TabCellHeader style={{ whiteSpace: 'nowrap' }}>First and Last Name</TabCellHeader>
                                <TabCellHeader>Email</TabCellHeader>
                                <TabCellHeader>Role</TabCellHeader>
                                <TabCellHeader></TabCellHeader>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.csvData.map((user, index) => (
                                <TableRow key={index}>
                                    <TabCells>{index + 1}</TabCells>
                                    <TabCells style={{ whiteSpace: 'nowrap' }}>{`${user.firstName} ${user.lastName}`}</TabCells>
                                    <TabCells>{user.email}</TabCells>
                                    <TabCells>ADMIN</TabCells>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </TableContainer>
        );
    };

    BulkUploadCsv = () => {
        return (
            <Modal open={this.state.csvModal} onClose={this.handleModalClose} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Container
                    sx={{
                        position: 'absolute',
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                        boxShadow: "0px 8px 32px 0px #0000000F",
                        width: '600px',
                        padding: '24px',
                        height: "80vh",
                        borderRadius: '8px 8px 32px 8px',
                        backgroundColor: '#FFFFFF',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box display="flex" justifyContent="space-between" alignItems="center" padding="10px 0" borderBottom="1px solid #F0F0F0">
                        <Typography
                            style={{
                                fontFamily: 'Heebo',
                                fontSize: '24px',
                                letterSpacing: '0.5%',
                                lineHeight: '32px',
                                fontWeight: '700',
                            }}>
                            CSV file upload
                        </Typography>
                        <IconButton data-testID="close-Modal" onClick={this.handleModalClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    {this.state.isDoneClicked ? (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: "100%"
                            }}
                        >
                            {this.DataTable()}
                            <Box
                                sx={{
                                    backgroundColor: this.state.isValid ? '#D1FAE5' : '#FEE2E2',
                                    borderLeft: `4px solid ${this.state.isValid ? '#059669' : '#DC2626'}`,
                                    padding: '10px',
                                    borderRadius: '4px',
                                    marginBottom: '16px',
                                }}
                            >
                                <Typography
                                    style={{
                                        fontFamily: 'Heebo',
                                        fontSize: '16px',
                                        fontWeight: '600',
                                        color: this.state.isValid ? '#059669' : '#DC2626',
                                    }}
                                >
                                    {this.state.isValid
                                        ? "All data validated, and ready for upload"
                                        : `Error in rows`}
                                </Typography>
                            </Box>
                        </Box>
                    ) : (
                        <>
                            <Box
                                sx={{
                                    cursor: "pointer",
                                    border: "1px dashed #CBD5E1",
                                    borderRadius: "8px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: '16px',
                                    textAlign: "center",
                                    padding: '24px',
                                }}
                                onDragOver={this.handleDragOver}
                                onDrop={this.handleDrop}
                                data-testID="dragFile"
                                className="upload"
                            >
                                <img src={uploadIcon} alt="" />
                                <Typography
                                    style={{
                                        fontFamily: 'Heebo',
                                        fontSize: '24px',
                                        letterSpacing: '0.5%',
                                        lineHeight: '32px',
                                        fontWeight: '700',
                                    }}>
                                    Upload media
                                </Typography>
                                <Typography
                                    style={{
                                        fontFamily: 'Heebo',
                                        fontSize: '16px',
                                        color: '#334155',
                                        lineHeight: '24px',
                                        fontWeight: '400',
                                    }}>
                                    Drag and drop or add some files
                                </Typography>
                                <Button variant="text" component="label"
                                    style={{
                                        fontFamily: 'Heebo',
                                        textTransform: 'none',
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        fontWeight: '700',
                                        color: '#000000',
                                        backgroundColor: '#F5F5F4',
                                    }}>
                                    Upload from your device
                                    <input
                                        type="file"
                                        accept=".csv"
                                        data-testID="fileInput"
                                        onChange={this.handleFileChange}
                                        style={{
                                            position: 'absolute',
                                            width: '1px',
                                            height: '1px',
                                            opacity: 0,
                                            pointerEvents: 'none',
                                        }}
                                    />
                                </Button>
                                <Typography
                                    style={{
                                        fontFamily: 'Heebo',
                                        fontSize: '12px',
                                        lineHeight: '18px',
                                        fontWeight: '400',
                                        color: '#94A3B8',
                                    }}>
                                    Supported formats: CSV
                                </Typography>
                            </Box>

                            {this.state.file && (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    padding="16px 12px 16px 16px"
                                    border="1px solid #ccc"
                                    borderRadius="8px"
                                    gap="16px"
                                >
                                    <Box display="flex" gap="16px">
                                        <img src={fileIcon} alt="" />
                                        <Box display="flex" flexDirection="column">
                                            <Typography variant="body2">{this.state.file.name}</Typography>
                                            <Typography variant="caption" color="textSecondary">
                                                {(this.state.file.size / 1024).toFixed(2)} KB
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <IconButton>
                                        <MoreVertIcon />
                                    </IconButton>
                                </Box>
                            )}
                        </>
                    )}

                    <Box display="flex" justifySelf="end" gap="16px" justifyContent="end" borderTop="1px solid #F0F0F0">
                        {this.state.isDoneClicked ? (
                            <Button
                                variant="contained"
                                style={{ color: 'white', backgroundColor: 'black' }}
                                disabled={!this.state.isValid}
                                onClick={this.handleCSVFileUpload}
                                data-testID="uploadValid-Btn"
                            >
                                Upload
                            </Button>
                        ) : (
                            <>
                                <Button variant="outlined"
                                    style={{ color: 'black', backgroundColor: '#F0F0F0', border: 'none' }}
                                    onClick={this.handleModalClose}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    style={{ color: 'white', backgroundColor: 'black' }}
                                    disabled={!this.state.file}
                                    onClick={this.handleDoneClick}
                                    data-testID="done-button"
                                >
                                    Done
                                </Button>
                            </>
                        )}
                    </Box>
                </Container>
            </Modal>
        )
    }

    AddNewUserModal = () => {
        const { newUserData } = this.state
        return (
            <CustomDialog
                scroll="body"
                data-test-id="add-formik"
                open={this.state.addNewUserModal}
                onClose={this.handleAddNewUserModalClose}
            >
                <DialogTitle className="dialog-title">
                    <Typography className="heading">Add User</Typography>
                    <CloseIcon className="close-icon" data-testID="close-modal" onClick={this.handleAddNewUserModalClose} />
                </DialogTitle>
                <Formik
                    data-test-id="add-formik"
                    initialValues={{
                        firstName: "",
                        lastName: "",
                        jobTitle: "",
                        email: ""
                    }}
                    enableReinitialize
                    validationSchema={this.userSchema}
                    onSubmit={() => {
                        this.handleAddNewUserDoneClick();
                    }}
                >
                    {({ errors, touched, setFieldValue, handleSubmit }) => (
                        <form
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSubmit}
                            className="form"
                        >
                            <DialogContent className="heading">
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Box ml="8px" mt="15px">
                                            <Typography className="label-title">First name</Typography>
                                            <CustomInput
                                                fullWidth
                                                placeholder="First name"
                                                data-testID="addFirstName"
                                                value={newUserData.firstName}
                                                onChange={(e) => {
                                                    setFieldValue("firstName", e.target.value);
                                                    this.handleUserDataChange("firstName", e);
                                                }}
                                            />
                                            {touched.firstName && errors.firstName &&
                                                <div className="error">{errors.firstName}</div>
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box ml="8px">
                                            <Typography className="label-title">Last name</Typography>
                                            <CustomInput
                                                fullWidth
                                                placeholder="Last name"
                                                data-testID="addLastName"
                                                value={newUserData.lastName}
                                                onChange={(e) => {
                                                    setFieldValue("lastName", e.target.value);
                                                    this.handleUserDataChange("lastName", e);
                                                }}
                                            />
                                            {touched.lastName && errors.lastName &&
                                                <div className="error">{errors.lastName}</div>
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box ml="8px">
                                            <Typography className="label-title">Job Title</Typography>
                                            <CustomInput
                                                fullWidth
                                                placeholder="Job title"
                                                data-testID="addJobTitle"
                                                value={newUserData.jobTitle}
                                                onChange={(e) => {
                                                    setFieldValue("jobTitle", e.target.value);
                                                    this.handleUserDataChange("jobTitle", e);
                                                }}
                                            />
                                            {touched.jobTitle && errors.jobTitle &&
                                                <div className="error">{errors.jobTitle}</div>
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box ml="8px">
                                            <Typography className="label-title">Email</Typography>
                                            <CustomInput
                                                fullWidth
                                                placeholder="Email"
                                                data-testID="addEmail"
                                                value={newUserData.email}
                                                onChange={(e) => {
                                                    setFieldValue("email", e.target.value);
                                                    this.handleUserDataChange("email", e);
                                                }}
                                            />
                                            {touched.email && errors.email &&
                                                <div className="error">{errors.email}</div>
                                            }
                                            <Box className="error">{this.state.emailError}</Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box ml="8px" mb="12px">
                                            <Typography className="label-title">Role</Typography>
                                            <Box className="role">Trainee</Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions className="dialog-action">
                                <CancelButton sx={{ padding: "12px 30px !important" }} onClick={this.handleAddNewUserModalClose}>
                                    Cancel
                                </CancelButton>
                                <DoneButton type="submit">
                                    Done
                                </DoneButton>
                            </DialogActions>
                        </form>)}
                </Formik>
            </CustomDialog>
        );
    }

    ViewUserModal = () => {
        const { currentUser } = this.state;
        return (
            <CustomDialog
                open={this.state.viewUserModal}
                onClose={this.handleViewUserModalClose}
            >
                <DialogTitle className="dialog-title">
                    <Typography className="heading">View User</Typography>
                    <CloseIcon className="close-icon" data-testID="closeViewModal" onClick={this.handleViewUserModalClose} />
                </DialogTitle>
                <DialogContent className="heading">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box ml="8px" mt="25px">
                                <Typography className="label-title">First name</Typography>
                                <CustomInput
                                    fullWidth
                                    readOnly
                                    placeholder="First name"
                                    value={currentUser?.first_name}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box ml="8px">
                                <Typography className="label-title">Last name</Typography>
                                <CustomInput
                                    fullWidth
                                    readOnly
                                    placeholder="Last name"
                                    value={currentUser?.last_name}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box ml="8px">
                                <Typography className="label-title">Job Title</Typography>
                                <CustomInput
                                    fullWidth
                                    readOnly
                                    placeholder="Job title"
                                    value={currentUser?.job_title}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box ml="8px">
                                <Typography className="label-title">Email</Typography>
                                <CustomInput
                                    fullWidth
                                    readOnly
                                    data-testID="editEmail"
                                    value={currentUser?.email}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box ml="8px" mb="12px">
                                <Typography className="label-title">Role</Typography>
                                <Box className="role">Trainee</Box>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </CustomDialog>
        );
    }

    EditUserModal = () => {
        const { editUser } = this.state;
        return (
            <CustomDialog
                scroll="body"
                open={this.state.editUserModal}
                onClose={this.handleEditUserModalClose}
            >
                <DialogTitle className="dialog-title">
                    <Typography className="heading">Edit User</Typography>
                    <CloseIcon className="close-icon" data-testID="closeEditModal" onClick={this.handleEditUserModalClose} />
                </DialogTitle>
                <Formik
                    data-test-id="edit-formik"
                    initialValues={{
                        firstName: editUser.firstName,
                        lastName: editUser.lastName,
                        jobTitle: editUser.jobTitle,
                        email: editUser.email
                    }}
                    enableReinitialize
                    validationSchema={this.userSchema}
                    onSubmit={() => {
                        this.handleEditUserDoneClick();
                    }}
                >
                    {({ errors, touched, setFieldValue, handleSubmit }) => (
                        <form
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSubmit}
                            className="form"
                        >
                            <DialogContent className="heading">
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Box ml="8px" mt="15px">
                                            <Typography className="label-title">First name</Typography>
                                            <CustomInput
                                                fullWidth
                                                placeholder="First name"
                                                data-testID="editFirstName"
                                                value={editUser.firstName}
                                                onChange={(e) => {
                                                    setFieldValue("firstName", e.target.value);
                                                    this.handleInputChange("firstName", e);
                                                }}
                                            />
                                            {touched.firstName && errors.firstName &&
                                                <div className="error">{errors.firstName}</div>
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box ml="8px">
                                            <Typography className="label-title">Last name</Typography>
                                            <CustomInput
                                                fullWidth
                                                placeholder="Last name"
                                                data-testID="editLastName"
                                                value={editUser.lastName}
                                                onChange={(e) => {
                                                    setFieldValue("lastName", e.target.value);
                                                    this.handleInputChange("lastName", e);
                                                }}
                                            />
                                            {touched.lastName && errors.lastName &&
                                                <div className="error">{errors.lastName}</div>
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box ml="8px">
                                            <Typography className="label-title">Job Title</Typography>
                                            <CustomInput
                                                fullWidth
                                                placeholder="Job title"
                                                data-testID="editJobTitle"
                                                value={editUser.jobTitle}
                                                onChange={(e) => {
                                                    setFieldValue("jobTitle", e.target.value);
                                                    this.handleInputChange("jobTitle", e);
                                                }}
                                            />
                                            {touched.jobTitle && errors.jobTitle &&
                                                <div className="error">{errors.jobTitle}</div>
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box ml="8px">
                                            <Typography className="label-title">Email</Typography>
                                            <CustomInput
                                                fullWidth
                                                placeholder="Email"
                                                data-testID="editEmail"
                                                value={editUser.email}
                                                onChange={(e) => {
                                                    setFieldValue("email", e.target.value);
                                                    this.handleInputChange("email", e);
                                                }}
                                            />
                                            {touched.email && errors.email &&
                                                <div className="error">{errors.email}</div>
                                            }
                                            <Box className="error">{this.state.editEmailError}</Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box ml="8px" mb="12px">
                                            <Typography className="label-title">Role</Typography>
                                            <Box className="role">Trainee</Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions className="dialog-action">
                                <CancelButton sx={{ padding: "12px 30px !important" }} onClick={this.handleEditUserModalClose}>
                                    Cancel
                                </CancelButton>
                                <DoneButton type="submit">
                                    Done
                                </DoneButton>
                            </DialogActions>
                        </form>)}
                </Formik>
            </CustomDialog>
        );
    }

    DeleteModal = () => {
        const { currentUser } = this.state;
        return (
            <CustomDialog
                open={this.state.deleteUserModal}
                onClose={this.handleDeleteUserModalClose}
                className="delete-popup"
            >
                <DialogTitle className="dialog-title">
                    <CloseIcon className="close-icon" data-testID="close-Modal" onClick={this.handleDeleteUserModalClose} />
                </DialogTitle>
                <DialogContent className="heading">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box padding="0px 5px 0px 15px">
                                <Typography className="delete-title">Are you sure you want to delete {currentUser?.first_name}<br />{currentUser?.last_name}'s account?</Typography>
                                <Typography className="delete-description">This action can't be undone and all the data stored in this <br /> account won't be accessible anymore.</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className="dialog-action">
                    <CancelButton
                        onClick={this.handleDeleteUserModalClose}
                    >
                        No
                    </CancelButton>
                    <DoneButton
                        onClick={() => this.handleDeleteUser(this.state.currentUser?.id ?? "")}
                        data-testID="deleteBtn"
                    >
                        Yes
                    </DoneButton>
                </DialogActions>
            </CustomDialog>
        );
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <Loader loading={this.state.loading} />
                    <Wrapper>
                        <Box>
                            <Sidebar navigation={this.props.navigation} current='clients' />
                        </Box>
                        <Box className="right-section">
                            <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                                <Box display={"flex"} gap={"10px"} alignItems={"center"}>
                                    <Typography style={{ fontFamily: 'Heebo', fontWeight: '400', fontSize: '24px', lineHeight: '32px', color: '#475569' }} 
                                    data-testId="handleBackClientManagement"
                                    >Client</Typography>
                                    <KeyboardArrowRight />
                                    <Typography style={{ fontFamily: 'Heebo', fontSize: '24px', fontWeight: '400', lineHeight: '32px', color: '#475569' }} 
                                    data-testID="handleBackClient"
                                    onClick={this.handleBackClient}
                                    >{this.state.clientName}</Typography>
                                    <KeyboardArrowRight />
                                    <Typography style={{ fontFamily: 'Heebo', fontSize: '24px', fontWeight: '700', lineHeight: '32px', color: '#0F172A' }} >Users</Typography>
                                </Box>
                                <Box display={"flex"} justifyContent={"space-between"} gap={"8px"}>
                                    <Button
                                        sx={{ fontFamily: 'Heebo', fontSize: '16px', textTransform: 'none', fontWeight: '400', color: '#000000', padding: '10px 16px', display: 'flex', gap: '8px' }}
                                        data-testID="export-btn" onClick={this.exportCSV}>
                                        <img src={exportIcon} alt="" />
                                        <span>
                                            Export
                                        </span>
                                    </Button>
                                    <Button
                                        sx={{ fontFamily: 'Heebo', fontSize: '16px', textTransform: 'none', fontWeight: '400', color: '#000000', padding: '10px 16px', borderRadius: '8px', border: '1px solid black' }}
                                        data-testID="upload-btn" onClick={this.handleModalOpen}>
                                        Bulk Actions
                                    </Button>
                                    <AddNewUserButton
                                        startIcon={<GroupAdd />}
                                        onClick={this.handleAddNewUserModalOpen}
                                        data-testID="AddNewUser"
                                    >Add new user
                                    </AddNewUserButton>
                                </Box>
                            </Box>
                            <TextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                    sx: { backgroundColor: 'white', padding: '5px', paddingLeft: '20px' }
                                }}
                                variant="outlined"
                                size="small"
                                placeholder="Search by user"
                                sx={{ width: "55%", height: "50px", marginLeft: "4px", paddingTop:'24px' }}
                                value={this.state.searchQuery}
                                onChange={this.handleSearchQuery}
                                data-testID="searchQuery"
                            />
                            <Box display="flex" alignItems="center" justifyContent="space-between"  padding="24px 0px" bgcolor="#f5f5f5">
                                <Box display="flex" alignItems="center">
                                    {this.state.deleteArr.length > 0 &&
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <Custombox
                                                checked={this.state.deleteArr.length > 0}
                                                data-testID="deselectAll"
                                                onChange={this.handleDeselectAll}
                                            />
                                            <Typography>
                                                {this.state.deleteArr.length} items selected
                                            </Typography>
                                            <Button
                                                style={{
                                                    fontFamily: 'Heebo',
                                                    fontWeight: '700',
                                                    textTransform: 'none',
                                                    lineHeight: '24px',
                                                    color: '#DC2626',
                                                    border: "#F87171 1px solid",
                                                    borderRadius: '4px',
                                                    padding: '8px',
                                                    marginLeft: "10px"
                                                }}
                                                onClick={this.handleBulkDelete}
                                                data-testID="bulkDelete"
                                            >
                                                Delete
                                            </Button>
                                        </Box>
                                    }
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: "center" }}>
                                    <Box>Sort by :</Box>
                                    <Box
                                        data-testID='btnSort'
                                        onClick={this.handlePopoverClick}
                                        style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}
                                    >
                                        {this.state.currentSortKey}
                                        <KeyboardArrowDown />
                                    </Box>
                                    <Popover
                                        data-test-id='btnPopover'
                                        open={this.isSortPopoverOpen()}
                                        anchorEl={this.state.anchorElSort}
                                        onClose={this.handlePopoverClose}
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                                        style={{ boxShadow: 'none' }}
                                    >
                                        {this.sortKeys.map((item, index) => (
                                            <MenuItem
                                                key={'sortId' + index}
                                                data-testID={`btnOptions${index}`}
                                                onClick={() => this.handleSort(item)}
                                            >
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Popover>
                                </Box>
                            </Box>
                            <Box>
                                {this.state.userData.length > 0 ?
                                    <TableContainer component={Box} style={{ width: '100%', maxHeight: '800px', overflowY: 'scroll' }}>
                                        <style>
                                            {`
                                            .MuiTableCell-root{
                                              border="none"
                                            }
                                            `}
                                        </style>
                                        <Box style={{ width: '100%', overflowX: 'hidden', backgroundColor: "#fff" }}>
                                            <Table aria-label="sticky table" stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        <TabCellHeader></TabCellHeader>
                                                        <TabCellHeader>First and Last Name</TabCellHeader>
                                                        <TabCellHeader>Email</TabCellHeader>
                                                        <TabCellHeader>Packages/Courses</TabCellHeader>
                                                        <TabCellHeader>Job Title</TabCellHeader>
                                                        <TabCellHeader>Last Date Active</TabCellHeader>
                                                        <TabCellHeader></TabCellHeader>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.userData.map((user, i) => (
                                                        <TableRow key={i}>
                                                            <TabCells>
                                                                <CustomCheckbox
                                                                    checked={this.isUserSelected(user.id)}
                                                                    onChange={() => { this.handleCheckboxChange(user.id) }}
                                                                    color="info"
                                                                    data-testID={`checkbox${i}`}
                                                                />
                                                            </TabCells>
                                                            <TabCells style={{ whiteSpace: 'nowrap' }}>{`${user.first_name} ${user.last_name}`}</TabCells>
                                                            <TabCells>{user.email}</TabCells>
                                                            <TabCells>12</TabCells>
                                                            <TabCells>{user.job_title}</TabCells>
                                                            <TabCells>{this.convertDate(user.created_at)}</TabCells>
                                                            <TabCells>
                                                                <IconButton data-testID="userOptions" onClick={(event) => this.handleUserOptionClick(event, user)}>
                                                                    <MoreVertIcon />
                                                                </IconButton>
                                                                <Box>
                                                                    <Popover
                                                                        onClose={this.handlePopoverClose}
                                                                        anchorEl={this.state.userAnchorEl}
                                                                        open={this.handleCloseUserOption()}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'center',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'center',
                                                                        }}
                                                                    >
                                                                        <MenuList>
                                                                            <MenuItem data-testID="viewModal" onClick={this.handleViewUserModalOpen}>View</MenuItem>
                                                                            <MenuItem data-testID="editModal" onClick={this.handleEditUserModalOpen}>Edit</MenuItem>
                                                                            <MenuItem data-testID="deleteModal" onClick={this.handleDeleteUserModalOpen}>Delete</MenuItem>
                                                                        </MenuList>
                                                                    </Popover>
                                                                </Box>
                                                            </TabCells>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    </TableContainer>
                                    :
                                    <Box display={"flex"} justifyContent={"center"}>
                                        No record found.
                                    </Box>
                                }

                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: 'center' }}>
                                <Pagination
                                    count={this.state.totalPages}
                                    page={this.state.currentPage}
                                    onChange={this.handlePageChange}
                                    shape="rounded"
                                    data-testID="pageChange"
                                />
                            </Box>
                        </Box>
                        {this.BulkUploadCsv()}
                        {this.AddNewUserModal()}
                        {this.EditUserModal()}
                        {this.DeleteModal()}
                        {this.ViewUserModal()}
                    </Wrapper>
                    <AdminFooter/>
                </ThemeProvider>
            </StyledEngineProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const Wrapper =  styled(Box)({
    height: "calc(100vh - 72px)",
    display: 'flex',
    width:'100%',
    flexDirection: 'row',
    backgroundColor:'#fafafa',
    "& .right-section": {
    width: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    padding: "25px 20px 25px 10px",
  },
});

const CustomDialog = styled(Dialog)({
    '& .MuiPaper-root': {
      maxWidth: '600px',
      width: '100%',
      borderRadius: "8px 8px 32px 8px",
    },
    "& .description": {
      color: "#0F172A",
      fontSize: "16px",
      marginBottom: "15px",
      fontFamily: "Heebo-Regular"
    },
    "& .dialog-title": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "18px 15px 16px 30px",
      borderBottom: "1px solid #E2E8F0",
      "& .close-icon": {
        cursor: "pointer",
        color: "#334155",
      },
    },
    "&.delete-popup": {
      "& .MuiPaper-root": {
        borderRadius: "8px 8px 32px 8px",
      },
      "& .dialog-title": {
        justifyContent: "end",
        borderBottom: "none",
      },
      "& .delete-title": {
        lineHeight: "32px",
        marginBottom: "10px",
        fontSize: "24px",
        fontFamily: "Heebo-Bold",
        letterSpacing: "-0.5px"
      },
      "& .delete-description": {
        fontSize: "18x",
        fontFamily: "Heebo-Regular",
        letterSpacing: "-0.5px"
      },
      " & .dialog-action": {
        padding: "20px",
      }
    },
    "& .error": {
      fontSize: "14px",
      fontFamily: "Heebo-Regular",
      color: "red"
    },
    "& .heading": {
      fontSize: "24px",
      fontFamily: "Heebo-Bold",
      letterSpacing: "-0.5px"
    },
    "& .role": {
      fontSize: "16px",
      color: "#94A3B8",
      fontFamily: "Heebo-Regular",
      letterSpacing: "-0.5px"
    },
    "& .label-title": {
      marginTop: "0px",
      fontSize: "14px",
      color: "#334155",
      marginBottom: "5px",
      fontFamily: "Heebo-Bold",
    },
    "& .dialog-action": {
      padding: "15px 18px",
      borderTop: "1px solid #E2E8F0",
    },
});

const CustomInput = styled(InputBase)({
    width: "100%",
    borderRadius: "8px",
    padding: "7px 8px",
    backgroundColor: "#fff",
    border: "1px solid #A8A29E",
});

const CancelButton = styled(Button)({
    textTransform: "none",
    fontFamily: "Heebo-Bold",
    color: "black !important",
    fontSize: "16px !important",
    padding: "12px 38px !important",
    backgroundColor: "#F0F0F0",
    borderRadius: "8px !important",
    "&:hover": {
      backgroundColor: "#F0F0F0",
    },
  });

  const DoneButton = styled(Button)({
    marginLeft: "15px !important",
    textTransform: "none",
    fontFamily: "Heebo-Bold",
    color: "white !important",
    fontSize: "16px !important",
    padding: "12px 35px !important",
    backgroundColor: "black",
    borderRadius: "8px !important",
    "&:hover": {
      backgroundColor: "black",
    },
  });

const AddNewUserButton = styled(Button)({
    fontFamily: 'Heebo',
    fontSize: '16px',
    textTransform: 'none',
    fontWeight: '400',
    color: '#fff',
    padding: '10px 16px',
    borderRadius: '8px',
    backgroundColor: '#000000',
    "&:hover": {
        backgroundColor: "black",
    },
});

const TabCellHeader = styled(TableCell)({
    fontFamily: 'Heebo-Bold',
    color: '#475569',
    fontWeight: 700,
    fontSize: '14px',
    backgroundColor: 'white', zIndex: 1
})

const TabCells = styled(TableCell)({
    fontFamily: 'Heebo-Regular',
    color: '#0F172A',
    fontWeight: 400,
    fontSize: '14px'
})

const RoleTabCell = styled(TableCell)({
    fontWeight: 700, lineHeight: '18px'
})

const CustomCheckbox = styled(Checkbox)({
    '& .MuiSvgIcon-root': {
        width: '20px',
        backgroundColor: 'white',
        height: '24px',
        borderRadius: '4px',
        border: '1px solid black',
    },
    '&:not(.Mui-checked) .MuiSvgIcon-root': {
        color: 'transparent',
    },
    '&.Mui-checked': {
        '& .MuiSvgIcon-root': {
            borderColor: 'black',
            backgroundColor: 'black',
        },
        '& .MuiSvgIcon-root path': {
            d: 'path("M5 12l5 5L20 7")',
            strokeWidth: 2,
            stroke: 'white',
            fill: 'none',
        },
    },
});

const Custombox = styled(Checkbox)({
    '&:not(.Mui-checked) .MuiSvgIcon-root': {
        color: 'transparent',
    },
    '& .MuiSvgIcon-root': {
        width: '20px',
        height: '24px',
        backgroundColor: 'white',
        border: '1px solid black',
        borderRadius: '4px',
    },
    '&.Mui-checked': {
        '& .MuiSvgIcon-root': {
            borderColor: 'black',
            backgroundColor: 'black',
        },
        '& .MuiSvgIcon-root path': {
            d: 'path("M6 12h12")',
            strokeWidth: 2,
            stroke: 'white',
            fill: 'none',
        },
    },
});

const webStyles = {
    containerModal: {
        position: 'absolute',
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        boxShadow: "0px 8px 32px 0px #0000000F",
        width: '600px',
        padding: '24px',
        borderRadius: '8px 8px 32px 8px',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        justifyContent: 'space-between',
    },
    modalText: {
        font: 'Heebo',
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '22px',
        color: '#334155'
    }
}
// Customizable Area End
