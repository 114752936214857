import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/ApiCall.web";
import { toast } from "react-toastify";
import axios from "axios";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isEditing:boolean,
  editingType:string,
  editorState:any,
  allContent:any,
  assignedCourses:any;
  adminGreetingTitleHTML?: string | any,
  adminGreetingDescriptionHTML?: string | any,
  providerLogoHTML?: string | any,
  providerDescriptionHTML?: string | any
  selectedBlock: any|null,
  previousHTML: {
    adminGreetingTitle:string|any,
    adminGreetingDescription:string|any,
    providerLogo:string|any,
    providerDescription:string|any,
  }
  loading: boolean,
  showSaveButton: boolean,
  keysToDelete: any,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceDataMessage)
    ];

    this.state = {
      isEditing: false,
      editingType: '',
      editorState: null,
      selectedBlock: null,
      allContent:{},
      assignedCourses:[],
      previousHTML: {
        adminGreetingTitle: '',
        adminGreetingDescription: '',
        providerLogo: '',
        providerDescription: '',
      },
      adminGreetingTitleHTML: null,
      adminGreetingDescriptionHTML: null,
      providerLogoHTML: null,
      providerDescriptionHTML: null,
      loading: false,
      showSaveButton:false,
      keysToDelete:[],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.getContentApiCallId:
            this.handleContentResponse(responseJson,false);
            break;
          case this.getCoursesApiCallId:
            this.handleCoursesResponse(responseJson);
            break;
          case this.updateContentApiCallId:
            this.handleContentResponse(responseJson,true);
            break;
        }
        this.setState({ loading: false });
      }
    }    
    // Customizable Area End
  }

  // Customizable Area Start
  getContentApiCallId: string="";
  updateContentApiCallId: string="";
  getCoursesApiCallId: string="";
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getContent();
    this.getCourses();
  }

    
  handleEditorChange = (editorState: any, type: string = '') => {
    this.setState({ editorState });
    switch(type){
      case 'admin_greeting_title': this.setState({adminGreetingTitleHTML: editorState});
      break;
      case 'admin_greeting_desc': this.setState({adminGreetingDescriptionHTML: editorState});
      break;
      case 'provider_logo': this.setState({providerLogoHTML: editorState});
      break;
      case 'provider_desc': this.setState({providerDescriptionHTML: editorState});
      break;
      default:
    }
  }

  extractTags = (htmlString:string,type:string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    let data;
    if (type == 'img') {
      data = Array.from(doc.getElementsByTagName('img'));
    } else {
      data = Array.from(doc.getElementsByTagName('p'));
    }
    return data;
  };

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getContent = async () => {
    this.setState({ loading: true });
    this.getContentApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      endPoint: `bx_block_content_management/landing_pages/show_content/`,
      token: localStorage.getItem("token"),
    });
  }

  getCourses = async () => {
    this.setState({ loading: true });
    this.getCoursesApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      endPoint: `bx_block_content_management/landing_pages`,
      token: localStorage.getItem("token"),
    });
  }

  updateContent = async () => {
    this.setState({ loading: true });
    let id = this.state.allContent.id;
    const reqBody = await this.prepareReqBodyToUpdate();
    this.updateContentApiCallId=await apiCall({
      method: "PATCH",
      contentType: "application/json",
      endPoint: `bx_block_content_management/landing_pages/` + id,
      body: reqBody,
      token: localStorage.getItem("token"),
    });
    this.setState({showSaveButton:false})
  }
  
  preparedText = (tags: any) => {
    let preparedText: any = '';
    tags.forEach((tag: any) => {
      if (tag.innerHTML?.length) {
        preparedText +=tag.outerHTML.replace(/<img[^>]*src="[^"]*"[^>]*>/g, '');
      }
    });
    return preparedText.replace('<p></p>','');
  }

  convertImageToBase64 = async (imageUrl: any) => {
    const response = await axios.get(imageUrl, {
      responseType: 'arraybuffer'
    });
    const base64 = Buffer.from(response.data, 'binary').toString('base64');
    const base64Image:any = `data:${response.headers['content-type']};base64,${base64}`;
    return base64Image;
  }

  createImage = (src: string) => {
    const imgElement = document.createElement('img');
    imgElement.src = src;
    imgElement.alt = 'wave';
    imgElement.style.maxHeight = '60px';
    imgElement.style.maxWidth = '200px';
    return imgElement;
  }

  prepareReqBodyToUpdate = async () => {
    const imgTag:any = this.extractTags(this.state.adminGreetingTitleHTML, 'img');
    let base64:any = imgTag[0]?.src;
    if (base64?.includes('http')) {
      base64 =await this.convertImageToBase64(base64)
    }
    const headingTags = this.extractTags(this.state.adminGreetingTitleHTML, 'text');
    const headingContextTags = this.extractTags(this.state.adminGreetingDescriptionHTML, 'text');
    const companyLogoContextTags = this.extractTags(this.state.providerDescriptionHTML, 'text');
    const headingPreparedContent = this.preparedText(headingTags);
    const headingContextPreparedContent = this.preparedText(headingContextTags);
    const companyLogoContextPreparedContent = this.preparedText(companyLogoContextTags);
    let reqBody: any={
      landing_page:{
      }
    }
    if (imgTag.length) {
      reqBody.landing_page['welcome_heading_logo'] = base64;
    } if (headingTags.length) {
      reqBody.landing_page['heading'] = this.isAddedToDeleteList('admin_greeting_title') ? "<p></p>" : headingPreparedContent.toString();
    }
    if (headingContextTags.length) {
      reqBody.landing_page["context"] = this.isAddedToDeleteList('admin_greeting_desc') ? "<p></p>" : headingContextPreparedContent.toString()
    }
    if (companyLogoContextTags.length) {
      reqBody.landing_page["company_logo_context"] = this.isAddedToDeleteList('provider_desc') ? "<p></p>" : companyLogoContextPreparedContent.toString()
    }
    return reqBody;
  }

  isAddedToDeleteList = (key: string) => this.state.keysToDelete.includes(key);

  handleContentResponse = (response: any, isUpdate: boolean) => {
    if (response?.data) {
      this.setState({keysToDelete:[]});
      const data = isUpdate ? response.data : response.data[0];
      const {welcome_heading_logo, heading,context,company_logo_context}=data?.attributes;
      this.setState({ allContent: data });
      const welcomeHeadingLogo=this.createImage(welcome_heading_logo?.url);
      this.setState({adminGreetingTitleHTML:heading.replace('<p>', `<p>`+welcomeHeadingLogo.outerHTML),adminGreetingDescriptionHTML:context,providerDescriptionHTML:company_logo_context});
      if(isUpdate){
        toast.success(response?.meta?.message);
      }
    } else {
      toast.error('Content Not found')
    }
  }

  handleCoursesResponse = (response: any) => {
    if (response?.data) {
      this.setState({ assignedCourses: response.data });
    }
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  // Customizable Area End
}
