import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { AttemptedAssessmentInterface } from "./AssessmentTestPage.web";
import { apiCall } from "../../../components/src/ApiCall.web";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {

  // Customizable Area Start
  assessmentList: AttemptedAssessmentInterface[],
  activeAssessmentId: { listItem: number, item: number }
  courseDetails: any,
  assessmentData: any,
  currentQuestion: any,
  submitedAnswers: any[];
  currentResponse: any;
  assessmentResult: any;
  isFinished: boolean;
  loading: boolean;
  selectedCourseModuleId:string,
  firstName:string;
  lastName:string,
  clientId:number,
  imageUrl:string,
  courseId: any;
  courseModuleId: any;
  // Customizable Area End
}


interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AssessmentTestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCoursesApiCallId: string;
  submitAnswerApiCallId: string;
  submitAssessmentApiCallId: string;
  getUserDetailsApiCallId:string = '';
  getClientDetailApiCallId:string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      assessmentList: [
        {
          id: 1, title: "Introduction", assessments: [
            { id: 1, title: "Introducing user experience design", isPassed: true},
            { id: 2, title: "Start the UX Design Process: Empathize, Define, and Ideate", isPassed: true }
          ]
        },
        {
          id: 2, title: "Dive in the UX", assessments: [
            { id: 1, title: "Build Wireframes and Low-Fidelity Prototypes", isPassed: true },
            { id: 2, title: "Design a User Experience for Social Good & Prepare for Jobs", isPassed: true },
            { id: 3, title: "Design a User Experience for Social Good & Prepare for Jobs", isPassed: true },
            { id: 4, title: "Test Your Knowledge", isPassed: false }
          ]
        }
      ],
      activeAssessmentId: { listItem: 2, item: 4 },
      courseDetails: {},
      assessmentData: {},
      currentQuestion: {},
      submitedAnswers: [],
      currentResponse: {},
      assessmentResult: {},
      isFinished: false,
      loading: false,
      selectedCourseModuleId:'',
      firstName:'',
      lastName:'',
      clientId:0,
      imageUrl:'',
      courseId: '',
      courseModuleId: '',
    }
    this.getCoursesApiCallId = '';
    this.submitAnswerApiCallId = '';
    this.submitAssessmentApiCallId = '';
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseToken),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }


// Customizable Area Start
  async componentDidMount() {
    this.getCourses();
    this.getUser();
  }
  // Customizable Area End


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        const { data, meta } = responseJson;
        if (data) {
          switch (apiRequestCallId) {
            case this.getCoursesApiCallId:
              this.handleCoursesResponse(data);
              break;
            case this.submitAnswerApiCallId:
              this.handleSubmitAnswerResponse(data, meta);
              break;
            case this.submitAssessmentApiCallId:
              this.handleSubmitAssessmentResponse(data);
              break;
            case this.getUserDetailsApiCallId:
              this.handleUserRespose(data)
              break;
            case this.getClientDetailApiCallId:
              this.handleClientResponse(data)
              break;
          }
        } else {
          this.handleErrorResponse(responseJson);
        }
        this.setState({ loading: false });
      }
    }   
    // Customizable Area End
  }

  // Customizable Area Start
  getCourses = async () => {
    const courseId = this.props.navigation.getParam('courseId');
    const courseModuleId = this.props.navigation.getParam('courseModuleId')
    this.setState({ courseId: courseId, courseModuleId: courseModuleId, loading: true });
    this.getCoursesApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      endPoint: `bx_block_admin/courses/${courseId}`,
      token: localStorage.getItem("token"),
    });
  }
  
  handleErrorResponse = (responseJson: any) => {
    const { errors, error } = responseJson;
    if (errors || error) {
      toast.error(error || errors)
    } else {
      toast.error('Something went wrong !')
    }
  }

  navigateToCertificate = () => {
    let newURL = window.location.origin + '/Certificate';
    window.open(newURL,'_blank')
  }

  handleCoursesResponse = (data: any) => {
    const courseModuleId = this.props.navigation.getParam('courseModuleId')
    this.setState({ selectedCourseModuleId: courseModuleId })
    const assessment = data.attributes.course_modules?.find((module: any) => module.id == courseModuleId)?.assessment;
    if(!assessment){return}
    this.setState({ courseDetails: data, assessmentData: assessment, currentQuestion: { position: 1, data: assessment.questions?.[0] } });
    const courseName = this.state.courseDetails?.attributes?.title || "Course Name";
    localStorage.setItem("currentCourse", courseName);
  }

  handleSubmitAnswerResponse = (data: any, meta: any) => {
    if (data?.length) {
      this.setState({ currentResponse: data[0] });
      toast.success(meta?.message);
      if (this.state.currentQuestion.position >= this.state.assessmentData?.questions.length) {
        this.submitAssessment();
      }
    }
  }

  isMultiSelect = (questionType:string) => questionType === "multiple_correct_options";

  handleSubmitAssessmentResponse = (data: any) => {
    if (data.attributes) {
      this.setState({ assessmentResult: data, currentQuestion: {}, currentResponse: {}, submitedAnswers: [] });
    }
  }

  submitAssessment = async () => {
    const reqBody = {
      "assessment_id": this.state.assessmentData.id,
    }
    this.submitAssessmentApiCallId = await apiCall({
      method: "POST",
      contentType: "application/json",
      endPoint: `bx_block_assessmenttest/attempted_assessments/submit_assessment`,
      body: reqBody,
      token: localStorage.getItem("token"),
    });
  }

  submitAnswer = async () => {
    if (!this.state.submitedAnswers.length) {
      toast.error('Please select answer');
      return;
    }
    const reqBody = {
      "assessment_id": this.state.assessmentData.id,
      "question_answer_attributes": [
        {
          "question_id": this.state.currentQuestion.data.id,
          "selected_option_ids": this.state.submitedAnswers
        }
      ]
    }
    this.submitAnswerApiCallId = await apiCall({
      method: "POST",
      contentType: "application/json",
      endPoint: `bx_block_assessmenttest/attempted_assessments/submit_que_answer`,
      body: reqBody,
      token: localStorage.getItem("token"),
    });
  }

  retakeAssessment = () => {
    this.setState({ currentQuestion: { position: 1, data: this.state.assessmentData.questions?.[0] }, assessmentResult: {} })
  }

  isAssessmentActive = (listItem: number, item: number) => {
    return this.state.activeAssessmentId.listItem === listItem && this.state.activeAssessmentId.item === item
  }

  isAssessmentPassed = (listItem: number, item: number) => {
    const listItemData = this.state.assessmentList.find(lItem => lItem.id === listItem);
    const assessment = listItemData?.assessments?.find(assessment => assessment.id === item);
    return assessment?.isPassed;
  }

  onChangeOption = (option: any) => {
    if (this.state.currentResponse?.attributes) { return; }
    if (!this.isMultiSelect(this.state.currentQuestion?.data?.question_type)) {
      let data = [option.id];
      this.setState({ submitedAnswers: data });
    } else if (this.state.submitedAnswers.includes(option.id)) {
      const indexToRemove = this.state.submitedAnswers.indexOf(option.id);
      if (indexToRemove !== -1) {
        const temp = this.state.submitedAnswers;
        temp.splice(indexToRemove, 1);
        this.setState({ submitedAnswers: temp })
      }
    } else {
      this.setState({ submitedAnswers: [...this.state.submitedAnswers, option.id] })
    }
  }

  onNext = () => {
    const nextItem = this.state.assessmentData.questions[this.state.currentQuestion.position];
    this.setState({ currentQuestion: { position: this.state.currentQuestion.position + 1, data: nextItem }, currentResponse: {}, submitedAnswers: [] });
  }

  showNextButton = () => (this.state.currentQuestion.position < this.state.assessmentData?.questions?.length) && this.state.currentResponse.attributes;

  isPassedAssessment = () => this.state.assessmentResult?.attributes?.passing_status !== 'fail' && this.state.isFinished;

  getResultDate = () => {
    const date = new Date(this.state.assessmentResult?.attributes?.updated_at);
    const options: any = { day: 'numeric', month: 'long', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
  }

  onFinish = () => {
    this.setState({ isFinished: true });
  }
  getClient = () => {
    let token = localStorage.getItem('token')
    const header = {
      "Content-Type": 'application/json',
      "token":token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage) 
    );
    this.getClientDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_account_groups/clients/${this.state.clientId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleClientResponse = (response:any) => {
    this.setState({imageUrl:response?.attributes?.company_logo?.url})
    localStorage.setItem('company_logo_url',this.state.imageUrl);
  }
  getUser = () => {
    let token = localStorage.getItem('token')
    let userId = localStorage.getItem('id');
    const header = {
      "Content-Type": 'application/json',
      "token":token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage) 
    );
    this.getUserDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/user_managements/${userId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleUserRespose = (response:any) => {
    this.setState({clientId:response?.attributes?.client_id},)
    this.getClient()
  }
   

  
  // Customizable Area End
}
