import React from "react";
// Customizable Area Start
  import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    Paper,
    Box,
    Grid,
    TextField,
    Select,
    Checkbox,
    ListItemText,
    Divider,
    InputAdornment,
    FormControl,
    Autocomplete,
    List, ListItem,
    DialogTitle,
    DialogContent,
    Dialog,
    Pagination,
    TableFooter
  } from "@mui/material";
  import { Close, DragIndicator, Search } from "@mui/icons-material";
  import MoreVertIcon from "@mui/icons-material/MoreVert";
  import AnalyticsController, { Props } from "./AnalyticsController.web";

// Customizable Area End

export default class ReportsAnalytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

 // Customizable Area Start

 
  CreateReportAnalytics = () => {
    const {
        title,
        reportingArea,
        availableColumns,
        selectedColumns,
        arrangedColumns,
        selectedFilter,
        subFilter,
        availableFilters,
        availableSubFilters,
        emailOptions,
        selectedEmails,
        isEdit
      } = this.state
      
      return (
        <div>
        <Box padding={3}>
            <Box style={{display:"flex",justifyContent:"space-between"}}>
            <Typography variant="h6">{isEdit?"Edit Report":"Create New Report"}</Typography>
            <Button variant="outlined" style={{height:"50px",width:"200px"}}
            onClick={this.handleOpenPreviewReport}
            > 
            Preview Report 
            </Button>
            </Box>
            <Grid container spacing={3} marginTop={2}>
            <Grid item xs={12}>
            <Typography style={webStyle.typography}>Title of Report</Typography>
              <TextField
                value={title}
                onChange={this.handleTitleChange}
                fullWidth
                style={{backgroundColor:"#fff"}}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Typography style={webStyle.typography}>Select Reporting Area</Typography>
                <Select
                  value={reportingArea}
                //   onChange={this.handleReportingAreaChange}
                style={{backgroundColor:"#fff"}}
                >
                  <MenuItem value="Courses">Courses</MenuItem>
                  <MenuItem value="Clients">Clients</MenuItem>
                  <MenuItem value="Users">Users</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography style={webStyle.typography}>Select Columns</Typography>

              <Box sx={{backgroundColor:"#fff",padding:"20px",borderRadius:"5px",height:"100%"}}>
              <TextField
                placeholder="Search Columns"
                variant="outlined"
                size="small"
                fullWidth
                sx={{ marginRight: 1 }}
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <Search />
                    </InputAdornment>
                    ),
                }}
                />
               
              {availableColumns.map((column) => (
                <>
                {/* istanbul ignore next */}
                <Box key={column} display="flex" alignItems="center">
                  <Checkbox
                    checked={selectedColumns.includes(column)}
                    onChange={(e)=>this.handleSelectColoumn(e,column)}
                  />
                  <ListItemText primary={column} />
                </Box>
                </>
              ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography style={webStyle.typography}>Arrange Columns</Typography>
              <Box sx={{backgroundColor:"#fff",padding:"20px",borderRadius:"5px",height:"100%"}}>
              <List>
            {arrangedColumns.map((item:any, index) => (            
             <ListItem
              key={index}
              draggable
              onDragStart={() => this.handleDragStart(index)}
              onDragOver={this.handleDragOver}
              onDrop={() => this.handleDrop(index)}
              onDragEnd={this.handleDragEnd}
              style={{
                cursor: 'move',
                margin: '4px 0',
                border: '1px solid #ddd',
                padding: '8px',
                borderRadius: '4px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
            <Typography>{index+1} .  </Typography>
              <IconButton>
                <DragIndicator />
              </IconButton>
              <ListItemText primary={item} />
              <IconButton
                edge="end"
                onClick={() => this.handleDelete(index)}
                aria-label="delete"
              >
                <Close />
              </IconButton>
            </ListItem>
          ))}
              </List>
              </Box>
            </Grid>
            </Grid>


            <Box p={3} border={1} borderRadius={2} borderColor="grey.300" style={{marginTop:"12%",backgroundColor:"#fff"}}>
            <Typography variant="h6" mb={2}>
             Report Filters
            </Typography>

            <Grid item xs={12}>
            <TextField
        label="Date Range"
        placeholder="YYYY-MM-DD to YYYY-MM-DD"
        variant="outlined"
        fullWidth
        value={this.state.dateRange}
        onChange={this.handleDateRangeChange}
        style={{ marginBottom: '16px' }}
      />
            </Grid>

            <Grid container spacing={2}>
            <Grid item xs={5}>
                <FormControl fullWidth>
                <Typography style={webStyle.typography}>Filter</Typography>
                <Select
                    value={selectedFilter}
                    // onChange={this.handleFilterChange}
                >
                    {availableFilters.map((filter) => {
                    return(
                    <MenuItem key={filter} value={filter}>
                        {filter}
                    </MenuItem>
                    )})}
                </Select>
                </FormControl>
            </Grid>

            <Grid item xs={5}>
                <FormControl fullWidth>
                <Typography style={webStyle.typography}>Sub Filter</Typography>
                <Select
                    multiple
                    value={subFilter}
                    // onChange={this.handleSubFilterChange}
                    renderValue={(selected) => (selected as string[]).join(', ')}
                >
                    {availableSubFilters.map((filter) => (
                    <MenuItem key={filter} value={filter}>
                        {filter}
                    </MenuItem>
                    ))}
                </Select>
                </FormControl>
            </Grid>

            <Grid item xs={2} style={{marginTop:"27px"}}>
                <Typography></Typography>
                <Button variant="outlined" style={{height:"50px"}}>
                 Add Filter
                </Button>
            </Grid>
            </Grid>
            </Box> 

            <Box p={3} border={1} borderRadius={2} borderColor="grey.300" style={{marginTop:"7%",backgroundColor:"#fff"}}>
            <Typography variant="h6" mb={2}>
            Schedule Report
            </Typography>

        <Grid container spacing={2}>
          {/* Date & Time Picker */}
          <Grid item xs={12}>
            <TextField
                label="Date Range"
                placeholder="YYYY-MM-DD to YYYY-MM-DD"
                variant="outlined"
                fullWidth
                value={this.state.dateRange}
                onChange={this.handleDateRangeChange}
                style={{ marginBottom: '16px' }}
            />
            </Grid>

          <Grid item xs={8} style={{marginTop:"-5px"}}>
            <Typography style={webStyle.typography} variant="subtitle1" gutterBottom>
              Email Report
            </Typography>
            <Autocomplete
              multiple
              options={emailOptions}
              value={selectedEmails}
              onChange={this.handleEmailChange}
              renderInput={(params) => <TextField {...params} placeholder="Select Emails" />}
            />
          </Grid>

          <Grid item xs={4} style={{marginTop:"50px"}}>
            <Typography></Typography>
            <Button variant="contained" color="primary" fullWidth style={{height:"50px"}} 
            onClick={this.handleCloseReport}
            >
              Schedule & Create
            </Button>
          </Grid>
        </Grid>
            </Box>

        </Box>
        </div>
    );
  };

  /* istanbul ignore next */
  renderRunReportTable =()=>{
    const { reportPreview, rowsPerPage, page,metaData} = this.state; 
    const startResult = page * rowsPerPage + 1;
    const endResult = Math.min((page + 1) * rowsPerPage, metaData.total_count);
    const total_pages = Math.ceil(reportPreview.length / rowsPerPage)
   
    return(
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={webStyle.tableHeader}>Clients Name</TableCell>
            <TableCell style={webStyle.tableHeader}>Users</TableCell>
            <TableCell style={webStyle.tableHeader}>Course Assigned</TableCell>
            <TableCell style={webStyle.tableHeader}>Course Completed</TableCell>
            <TableCell style={webStyle.tableHeader}>Accreditation Achieved</TableCell>
            <TableCell style={webStyle.tableHeader}>Average User Completion Rate</TableCell>
            <TableCell style={webStyle.tableHeader}>Total Learning Path Completed</TableCell>
            <TableCell style={webStyle.tableHeader}>Total Learning Hours</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reportPreview.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row:any, index:any) => (
            <TableRow key={index}>
              <TableCell>{row.client}</TableCell>
              <TableCell>{row.users}</TableCell>
              <TableCell>{row.courseAssigned}</TableCell>
              <TableCell>{row.courseCompleted}</TableCell>
              <TableCell>{row.accreditation}</TableCell>
              <TableCell>{row.avgCompletion}</TableCell>
              <TableCell>{row.totalLearning}</TableCell>
              <TableCell>{row.totalHours}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                      <Grid container style={{alignItems: 'center' }}>
                        <Grid item xs={2} style={{ flex: 1 }}></Grid>
                          <Grid item xs={8} style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                            <Pagination
                              data-test-id="pagination"
                              count={total_pages}
                              page={this.state.metaData.current_page}
                              onChange={this.handlePageChange}
                              siblingCount={1}
                              boundaryCount={1}
                            />
                          </Grid>
                          <Grid item xs={2} style={{ flex: 1, textAlign: 'right' }}>
                            {`${startResult}-${endResult} of ${this.state.metaData.total_count} results`}
                          </Grid>
                      </Grid>
                      </TableCell>
                    </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
    )
  }

  /* istanbul ignore next */
  reportPreviewModal = () => {
    const { reportPreview, rowsPerPage, page, openDialog ,openRunReport,metaData} = this.state; 
    const startResult = page * rowsPerPage + 1;
    const endResult = Math.min((page + 1) * rowsPerPage, metaData.total_count);
    const total_pages = Math.ceil(reportPreview.length / rowsPerPage)
   
    return (
        <Dialog open={openDialog} onClose={this.previewReportClose} fullWidth maxWidth="lg">
        <DialogTitle>
           {openRunReport?"Run Report" :"Report Preview"}
            <IconButton
            aria-label="close"
            onClick={this.previewReportClose}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <Close/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
           {openRunReport&& <>
            <Typography>Date Range</Typography>
            <TextField
        label="Date Range"
        placeholder="YYYY-MM-DD to YYYY-MM-DD"
        variant="outlined"
        fullWidth
        value={this.state.dateRange}
        onChange={this.handleDateRangeChange}
        style={{ marginBottom: '16px' }}
      />
            </>}
          <Divider/>
         {this.renderRunReportTable()}        
         {openRunReport ?
          <div style={{ marginTop: "16px", display: "flex", justifyContent: "flex-end", gap: "10px" }}>
            <Button variant="outlined" style={webStyle.modalBtn} >
            Export Report
            </Button>
            <Button variant="contained" style={webStyle.modalBtn} onClick={()=>{this.handleOpenreportTable();this.previewReportClose()}}>
              Run Report
            </Button>
          </div>:
          <div style={{ marginTop: "16px", display: "flex", justifyContent: "flex-end", gap: "10px" }}>
          <Button variant="outlined" style={webStyle.modalBtn} onClick={() => {this.handleOpenTemplateModal()}}>
            Save as Template
          </Button>
          <Button variant="contained" style={webStyle.modalBtn}>
            Export Report
          </Button>
        </div>
          }
          
        </DialogContent>
      </Dialog>
    )
  }

  saveTemplateModal = () => {
    const { openTemplateDialog } = this.state;    
    return (
        <Dialog open={openTemplateDialog} onClose={this.handleCloseTemplateModal} fullWidth maxWidth="sm">
        <DialogTitle>
            Save as Template 
            <IconButton
            aria-label="close"
            onClick={this.handleCloseTemplateModal}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <Close/>
          </IconButton>
        </DialogTitle>
        <Divider/>
        <DialogContent>
        
        <Grid item xs={12}>
            <Typography>Title of Report</Typography>
              <TextField
                // value={"title"}
                // onChange={this.handleTitleChange}
                fullWidth
                style={{backgroundColor:"#fff"}}
              />
            </Grid>


        <Divider style={{marginTop:"5%"}}/>
          <div style={{ marginTop: "16px", display: "flex", justifyContent: "flex-end", gap: "10px" }}>
            <Button variant="outlined" style={webStyle.modalBtn} onClick={this.handleCloseTemplateModal}>
              Cancel
            </Button>
            <Button variant="contained" style={webStyle.modalBtn}>
              Save Template
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    )
  }

 // Customizable Area End
  render() {
   // Customizable Area Start
    {/* istanbul ignore next */}
    const { anchorEl, menuRowId, page, rowsPerPage, Reportdata,reportModal ,metaData,openReportTable,reportPreview } = this.state;
    const open = Boolean(anchorEl); 
    const startResult = page * rowsPerPage + 1;
    const endResult = Math.min((page + 1) * rowsPerPage, metaData.total_count);
    const total_pages = Math.ceil(Reportdata.length / rowsPerPage)
   // Customizable Area End
    return (
      // Customizable Area Start
      <div>
         {reportModal === true ?
         (<div>
        {this.CreateReportAnalytics()}
         </div>)
        :openReportTable ?
        (<div>
        {/* istanbul ignore next */}
        <Paper sx={{ padding: "16px" }}>
        <Box display={"flex"} justifyContent={"space-between"} gap={"8px"}> 
            <Typography variant="h6" gutterBottom>
            MUI Reports
            </Typography>
            <IconButton
               onClick={(e) => this.handleMenuOpen(e, 1)}>
                <MoreVertIcon />
             </IconButton>
                      {menuRowId === 1 && (
                        <Menu
                          anchorEl={anchorEl}
                          open={open}
                          onClose={this.handleMenuClose}
                        >
                          <MenuItem onClick={this.handleMenuClose}>Edit</MenuItem>
                          <MenuItem style={{color:"red"}} onClick={this.handleMenuClose}>Delete</MenuItem>
                        </Menu>
                      )}
         </Box>
         {this.renderRunReportTable()}
        </Paper>
        </div>)
        :(<Paper sx={{ padding: "16px" }}>
        <Box display={"flex"} justifyContent={"space-between"} gap={"8px"}> 
            <Typography variant="h6" gutterBottom>
            Saved Reports
            </Typography>
            </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={webStyle.tableHeader}>Report Name</TableCell>
                <TableCell style={webStyle.tableHeader}>Reporting Area</TableCell>
                <TableCell style={webStyle.tableHeader}>Created By</TableCell>
                <TableCell style={webStyle.tableHeader}>Date of Creation</TableCell>
                <TableCell style={{...webStyle.tableHeader,textAlign:"center"}}>Action</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Reportdata
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row:any) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.area}</TableCell>
                    <TableCell>{row.createdBy}</TableCell>
                    <TableCell>{row.date}</TableCell>
                    <TableCell style={{textAlign:"center"}}>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{ marginRight: "8px" }}
                        onClick={()=>{
                          {/* istanbul ignore next */}
                            this.handleOpenPreviewReport();
                            this.handleOpenRunReport()
                        }}
                      >
                        Run Report
                      </Button> 
                    </TableCell>
                    <TableCell>
                    <IconButton
                        onClick={(e) => this.handleMenuOpen(e, row.id)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      {menuRowId === row.id && (
                        <Menu
                          anchorEl={anchorEl}
                          open={open}
                          onClose={this.handleMenuClose}
                        >
                          <MenuItem onClick={()=>{this.handleMenuClose(); this.handleCreateReport("Edit")}}>Edit</MenuItem>
                          <MenuItem onClick={this.handleMenuClose}>Clone</MenuItem>
                          <MenuItem onClick={this.handleMenuClose}>Delete</MenuItem>
                        </Menu>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
                          <TableRow>
                            <TableCell colSpan={7} align="center">
                              <Grid container style={{alignItems: 'center' }}>
                                <Grid item xs={4} style={{ flex: 1 }}></Grid>
                                <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                                  <Pagination
                                    data-test-id="pagination"
                                    count={total_pages}
                                    page={this.state.metaData.current_page}
                                    onChange={this.handlePageChange}
                                    siblingCount={1}
                                    boundaryCount={1}
                                  />
                                </Grid>
                                <Grid item xs={4} style={{ flex: 1, textAlign: 'right' }}>
                                  {`${startResult}-${endResult} of ${this.state.metaData.total_count} results`}
                                </Grid>

                              </Grid>
                            </TableCell>
                          </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>      
        </Paper>)
        }

        {this.reportPreviewModal()}
        {this.saveTemplateModal()}
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
    tableHeader:{
        color:"#475569",
        fontWeight:"600"
    },
    modalBtn:{
        height:"50px"
    },
    typography:{
      marginBottom:"1%"
    }
  };
// Customizable Area End
