// Customizable Area Start
import React from "react";
import { createTheme, styled, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import {
	Box,
} from "@mui/material";
import Palette from '../palette';
// Customizable Area End

// Customizable Area Start
import AdminSignupEmailController, {
	Props,
} from "./AdminSignupEmailController";
import { Typography } from "antd";
import { EditorContent } from "@tiptap/react";
import EditorToolbar from "./EditorToolbar.web";
import CustomEditor from "../../../components/src/CustomEditor.web";

const theme = createTheme(
	{
		palette: {
			backgrounds: {
				main: "#FAFAFA",
				light: "#FFFFFF",
				dark: "#000000",
			},
			texts: {
				main: "#0F172A",
				light: "#475569",
				dark: "#334155",
				contrastText: "#1C1C1C",
			},
			danger: {
				main: "#FF7468",
			},
			borders: {
				main: "#D6D3D1",
				light: "#F8FAFC",
			},
		},
	});

	const ids = {
		editorTitleContainer: 'editor-title-container',
		editorTitle: 'editor-title',
		editorDescription: 'editor-description',
	};
// Customizable Area End


export default class AdminSignupEmail extends AdminSignupEmailController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}

	// Customizable Area Start
	// Customizable Area End

	render() {
		// Customizable Area Start
		// Customizable Area End

		return (
			// Customizable Area Start
			<div id="main_block" style={{width:'640px'}}>
				<StyledEngineProvider injectFirst>
					<ThemeProvider theme={theme}>
						<Container>
							<Main>
								{this.state.logo && (
									<Logo src={this.state.logo} alt="ThinkSpike" />
								)}
								<EditorsContainer>
									{!this.state.loading &&
										<>
											<CustomEditor
												data-test-id="title"
												value={this.state.title}
												placeholder="Enter title here"
												hideBottomBorder={true}
												onChange={this.onTitleChange}
											/>
											<CustomEditor
												data-test-id="description"
												value={this.state.description}
												placeholder="Enter description here"
												hideBottomBorder={true}
												onChange={this.onDescriptionChange}
											/>
										</>
									}
								</EditorsContainer>
								<CredentialsContainer>
									<CredentialsItem>
										<CredentialsTitle>Your email</CredentialsTitle>
										<CredentialsDescription>test@email.com</CredentialsDescription>
									</CredentialsItem>
									<CredentialsItem>
										<CredentialsTitle>Temporary password</CredentialsTitle>
										<CredentialsDescription>Password1234</CredentialsDescription>
									</CredentialsItem>
								</CredentialsContainer>

								<Footer>
									<FooterTitle>THINKSPIKE</FooterTitle>
									<FooterTitle>Privacy Policy</FooterTitle>
									<FooterTitle>Terms & Conditions</FooterTitle>
								</Footer>
							</Main>
						</Container>
					</ThemeProvider>
				</StyledEngineProvider>
			</div>
			// Customizable Area End
		);
	}
}
// Customizable Area Start

const Container = styled(Box)({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	flexDirection: 'column',
	width: '100%',
	padding: '12px 0px'
});

const Main = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	gap: '20px',
	width: '100%',
	backgroundColor: theme.palette.backgrounds.light,
	padding: '40px 0px',
	borderRadius: '4px',
});

const Logo = styled('img')({
	maxWidth: '166px',
	margin: '0 auto',
});

const EditorsContainer = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	gap: '10px',
	padding: '20px 24px',
});

const StyledEditorTitle = styled('div')({
	fontSize: '24px',
	color: theme.palette.texts.main,
	maxWidth: '100%',
	'& .ProseMirror-focused': {
		border: 'none',
		outline: 'none',
	},
	'& .ProseMirror > p': {
		margin: '0px',
	},
});

const StyledEditorContent = styled('div')({
	fontSize: '16px',
	color: theme.palette.texts.dark,
	maxWidth: '100%',
	'& .ProseMirror-focused': {
		border: 'none',
		outline: 'none',
	},
	'& .ProseMirror > p': {
		margin: '0px',
	},
	'& .ProseMirror > .is-editor-empty::before': {
    content: 'attr(data-placeholder)',
    color: '#aaa',
    float: 'left',
    height: '0',
    pointerEvents: 'none',
  },
});

const CredentialsContainer = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	gap: '8px',
	padding: '10px 24px',
	backgroundColor: theme.palette.borders.light,
});

const CredentialsItem = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between',
	gap: '24px',
});

const CredentialsTitle = styled(Typography)({
	fontSize: '14px',
	fontWeight: '400',
	color: theme.palette.texts.main
});

const CredentialsDescription = styled(CredentialsTitle)({
	fontWeight: '700',
});

const Footer = styled(Box)({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	gap: '8px',
});

const FooterTitle = styled(Typography)({
	fontSize: '14px',
	fontWeight: '700',
	color: theme.palette.texts.light,
});
// Customizable Area End
