import MessageEnum, { getName } from "../../../../packages/framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { BlockComponent } from "../../../../packages/framework/src/BlockComponent";
import { apiCall } from '../../../components/src/ApiCall.web';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    anchorEl:any
    courseDetail:any
    courseProcessDetail:any
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CourseDescriptionController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getCourseDetailDataApiCall:string =''
    createProcessApiCall:string =''
    showProcessApiCall: string =''
//    async componentDidUpdate()  {
//     const param = this.props.navigation.getParam('courseId')
//     if(this.state.courseId) return   
//     this.setState({courseId:param})
//     this.handleGetCourseDetail()
//     }
   
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            anchorEl:null,
            courseDetail:undefined,
            courseProcessDetail:undefined
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {
              switch (apiRequestCallId) {
                case this.getCourseDetailDataApiCall:
                return this.handleGetCourseDescriptionResponse(responseJson)
                case this.createProcessApiCall:
                  return this.handleCreateProcessResponse(responseJson)
                case this.showProcessApiCall:
                  return this.handleShowProcessResponse(responseJson)
                default:
                  break;
              }
            }
          }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.handleGetCourseDetail()
    }
    handleGetCourseDescriptionResponse=(res:any)=>{
        this.setState({courseDetail:res.data})
    }
    handleCreateProcessResponse=(res:any)=>{
     if(res.data){
     this.handleShowProcess(res.data?.data?.id)
      }
    }
    handleShowProcessResponse = (res:any)=>{
      if(res.data){
        this.setState({courseProcessDetail:res.data.data.attributes})
      }
    }
    handleGetCourseDetail = async ()=>{
        const routeName = this.props.navigation.state.routeName.split('/')
        const assignCourseId = localStorage.getItem('assign_course_id')
        const courseId = routeName[routeName.length-1]
            this.getCourseDetailDataApiCall = await apiCall({
                method: 'GET',
                contentType: 'application/json',
                endPoint: `bx_block_admin/courses/get_course?id=${courseId}`,
                token: localStorage.getItem('token'),
              });
            this.handleCreateProcess(assignCourseId??'')
    }
    handleCreateProcess = async (courseId:string)=>{
      const body = {
        "user_process": {
          "assign_course_id": courseId
        }
      }
      this.createProcessApiCall = await apiCall({
        method:"POST",
        contentType: 'application/json',
        token: localStorage.getItem('token'),
        endPoint: `bx_block_profile/user_processes`,
        body
      })
    }
    handleShowProcess = async(id:string) =>{
      this.showProcessApiCall = await apiCall({
        method: 'GET',
        contentType: 'application/json',
        endPoint: `bx_block_profile/user_processes/${id}`,
        token: localStorage.getItem('token'),
      });
    }

    handleResumeCourse =()=>{
        const {courseDetail,courseProcessDetail} = this.state
        const process = courseProcessDetail?.process
      const courseId = this.props.navigation.getParam('courseId')
      if(process === 0 || process === 100){
        const courseModuleId = courseDetail.attributes.course_modules[0]?.id
        return  this.props.navigation.navigate('CourseLearning',{path:{courseId,courseModuleId}})
        }
        const courseCount = courseProcessDetail?.module_count
        const numberOfCourseModuleCompleted = Math.round(process * courseCount /100)
        const courseModuleId = courseDetail.attributes.course_modules[numberOfCourseModuleCompleted]?.id
        this.props.navigation.navigate('CourseLearning',{path:{courseId,courseModuleId}})
    }
  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  onLogout = () => {
    const { setAccessPermission } = this.context as any;
    setAccessPermission?.(false);
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("p");
    localStorage.removeItem("u");
    this.props.navigation.replace("EmailAccountRegistration")
    this.handleClose()
  }

  myLearning = ()=>{
    this.props.navigation.navigate("MyLearning")
  }
  checkCompletedPercentage:(index:number) => boolean = (index:number)=>{
    const {courseProcessDetail} = this.state
    if(!courseProcessDetail) return false
    const process = courseProcessDetail?.process
    const modulesCount = courseProcessDetail?.module_count
    if(process === 0){
      return false
    }else{
      const numberOfCourseModuleCompleted = Math.round(process * modulesCount /100)
      return (index<=numberOfCourseModuleCompleted -1) 
    }
  }
  getButtonName = ()=>{
    switch (this.state.courseProcessDetail?.process) {
      case 0:
        return 'Start'
      case 100:
        return 'Restart'
      default:
        return 'Resume'
    }
  }
    // Customizable Area End
}
