import React from "react";

import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import {
  StyledEngineProvider,
  styled
} from '@mui/material/styles';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { courseImg, timer } from "../../catalogue/src/assets";

import {
  imgChevronRight,
  imgWave,
  img3Dots,
  imgEdit,
  imgPaintBacket,
  imgTrashBin,
} from '../../landingpage/src/assets';
import ClientContentManagementController from "./ClientContentManagementController";
import HtmlReactParser from 'html-react-parser';
import Sidebar from "../../../components/src/sidebar/Sidebar.web";
import { SketchPicker } from 'react-color';


const editorModules = {
  toolbar: [
    [{ 'font': [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    ['link', 'image'],
    [{ 'color': [] }, { 'background': [] }],
    ['clean']
  ],
};

const editorFormats = [
  'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'color', 'background'
];

export default class ClientContentManagement extends ClientContentManagementController {
  private adminGreetingTitle = React.createRef<HTMLDivElement>();
  private adminGreetingDescription = React.createRef<HTMLDivElement>();
  private providerLogo = React.createRef<HTMLDivElement>();
  private providerDescription = React.createRef<HTMLDivElement>();

  handlePageClick = (e: any) => {
    if (e.target == this.state.selectedBlock || !this.state.selectedBlock) return;

    this.setState((prevState) => ({ ...prevState, selectedBlock: null }));
    this.setState((prevState) => ({ ...prevState, editorState: '' }));
    this.setState({ isEditing: false });
  }

  setSelectedBlock = (e: any | null) => {
    e?.preventDefault();

    const currentTarget = e.currentTarget;

    this.setState((prevState) => ({ ...prevState, selectedBlock: currentTarget }));

    this.setState((prevState) => ({
      ...prevState, editorState: prevState.selectedBlock?.innerHTML
    }));
  }

  startEdit = (editingType: string) => {
    this.setState({
      showSaveButton: true,
      previousHTML: {
        adminGreetingTitle: this.adminGreetingTitle.current?.innerHTML,
        adminGreetingDescription: this.adminGreetingDescription.current?.innerHTML,
        providerLogo: this.providerLogo.current?.innerHTML,
        providerDescription: this.providerDescription.current?.innerHTML,
      }
    });

    this.setState({ editingType });

    this.setState({ isEditing: !this.state.isEditing });
  }

  remove = (key: string) => {
    if (!this.state.selectedBlock) return;
    this.setState({ selectedBlock: null });
    this.setState({ editorState: '' });
    this.setState({ keysToDelete: [...this.state.keysToDelete, key] })
  }

  isEditingRender(showButtons: boolean) {
    return (
      showButtons &&
      <>
        <Button
          sx={styles.saveButton}
          data-testid='btnSave'
          onClick={() => this.updateContent()}
        >
          Save
        </Button>
        <Button sx={styles.moreButton} color="info">
          <img src={img3Dots} alt="3dots" />
        </Button>
      </>
    )
  }
 
  PopupMenuRender = (condition: boolean, key: string) => {
    return (
      condition &&
      <Box className="popup_edit">
        <Button onClick={() => this.startEdit('full')}>
          <img src={imgEdit} alt="pencil" />
        </Button>
        <Button data-test-id={'color-editor'}onClick={() => this.startEdit('color')}>
          <img src={imgPaintBacket} alt="color" />
        </Button>
        <Button onClick={() => this.remove(key)}>
          <img src={imgTrashBin} alt="delete" />
        </Button>
      </Box>
    )
  }
  ReactQuillRender = (condition: boolean, editorState: any, type: string = '') => {
    const bgColorState :()=>string = ()=>{
      switch (type) {
        case 'admin_greeting_title': return this.state.adminGreetingTitleBgColor
        case 'admin_greeting_desc': return this.state.adminGreetingDescriptionBgColor
        case 'provider_desc': return this.state.providerDescriptionBgColor
        default: break
      }
    }
    return (
      condition && (
        this.state.editingType === 'full'?
        <ReactQuill
        value={editorState}
        className={'editor-container'}
        onChange={(e) => this.handleEditorChange(e, type)}
        modules={editorModules}
        formats={editorFormats}
      />
        :
        <EditorColorPickerContainer>
				<SketchPicker
					data-test-id={`bg-color`}
					color={bgColorState()}
					onChangeComplete={(color: any) => this.handleBgColorChange(color,type)}
				/>
			</EditorColorPickerContainer>
      )
      
    )
  }

  render() {
    const { editorState, isEditing, selectedBlock, selectedPath, allCourses, response } = this.state;
    const isAdminGreetingTitle = selectedBlock == this.adminGreetingTitle.current;
    const isAdminGreetingDescription = selectedBlock == this.adminGreetingDescription.current;
    const isProviderLogo = selectedBlock == this.providerLogo.current;
    const isProviderDescription = selectedBlock == this.providerDescription.current;
    const isAdminGreetingTitleEditing = isAdminGreetingTitle && isEditing;
    const isAdminGreetingDescriptionEditing = isAdminGreetingDescription && isEditing;
    const isProviderLogoEditing = isProviderLogo && isEditing;
    const isProviderDescriptionEditing = isProviderDescription && isEditing;
    const isShowUpdatedBranding = (template: any) => template;

    const coursesList = selectedPath
      ? response?.learning_paths.find(path => path.data.id === selectedPath)?.data.attributes.courses
      : allCourses;
    return (
      <StyledEngineProvider>
        <Wrapper onClick={this.handlePageClick} data-testid="mainWrapper">
          <Box sx={styles.mainBlock}>
            <Box>
              <Sidebar navigation={this.props.navigation} current='clients' />
            </Box>
            <Box sx={styles.pageContent} data-testid="pageContent">
              <Box sx={styles.contentHeader}>
                <Box sx={styles.navigation}>
                  <Box sx={styles.breadcrumbButton}
                  data-test-id="client-navigate"
                  onClick={()=>{this.props.navigation.navigate('Clients')}}
                  >
                    Clients
                    </Box>
                  <img src={imgChevronRight} alt="rightangle" />
                  <Box sx={styles.currentNavigationPage} 
                  data-test-id='manageClient-navigate'
                  onClick={()=>{this.props.navigation.navigate('ManageClient')}}>
                    {this.state.ClientName}
                    </Box>
                  <img src={imgChevronRight} alt="rightangle" />
                  <Box sx={styles.currentNavigationPage}>Content Management</Box>
                </Box>
                <Box sx={styles.leftActions}>
                  {this.isEditingRender(this.state.showSaveButton)}
                </Box>
              </Box>

              <Box sx={styles.userContent}>
                <Box sx={styles.greetingBlock} width="100%" data-testid="greetingBlock">
                  <Box onClick={(e: any) => e.stopPropagation()}>
                    {this.PopupMenuRender(isAdminGreetingTitle, 'admin_greeting_title')}
                    {this.ReactQuillRender(isAdminGreetingTitleEditing, editorState, 'admin_greeting_title')}
                    <Box
                      sx={{...styles.adminGreeting,backgroundColor:this.state.adminGreetingTitleBgColor}}
                      className={isAdminGreetingTitleEditing ? 'hide' : ''}
                      onContextMenu={this.setSelectedBlock}
                      onClick={this.setSelectedBlock}
                      ref={this.adminGreetingTitle}
                      data-testid="greetingTitle"
                    >
                      {isShowUpdatedBranding(this.state.adminGreetingTitleHTML) ?
                        <>{HtmlReactParser(this.state.adminGreetingTitleHTML)}</>
                        : <Box sx={styles.greetingBg}>
                          <Box> <img style={{ maxHeight: '60px', maxWidth: '200px' }} src={imgWave} alt="wave"></img></Box>
                          <Box><div>{this.state.allContent?.attributes?.heading}</div></Box>
                        </Box>}
                    </Box>

                    {this.PopupMenuRender(isAdminGreetingDescription, 'admin_greeting_desc')}
                    {this.ReactQuillRender(isAdminGreetingDescriptionEditing, editorState, 'admin_greeting_desc')}
                    <Box
                      sx={{...styles.greetingInfo,backgroundColor:this.state.adminGreetingDescriptionBgColor}}
                      className={isAdminGreetingDescriptionEditing ? 'hide' : ''}
                      onContextMenu={this.setSelectedBlock}
                      onClick={this.setSelectedBlock}
                      ref={this.adminGreetingDescription}
                      data-testid="greetingDescription"
                    >
                      {isShowUpdatedBranding(this.state.adminGreetingDescriptionHTML) ?
                        <Box> {HtmlReactParser(this.state.adminGreetingDescriptionHTML)}</Box> :
                        <p>{this.state.allContent?.attributes?.context}</p>
                      }
                    </Box>
                  </Box>

                  <Box sx={styles.providerBlock} onClick={(e: any) => e.stopPropagation()} >
                    <Box
                      className={isProviderLogoEditing ? 'hide' : ''}
                      onContextMenu={this.setSelectedBlock}
                      onClick={this.setSelectedBlock}
                      sx={{ maxHeight: '60px', maxWidth: '200px', overflow: 'hidden' }}
                      ref={this.providerLogo}
                      data-testid='providerLogo'
                    >
                      {this.state.providerLogoHTML ? 
                        <img style={{ maxHeight: '60px', maxWidth: '200px' }} src={this.state.providerLogoHTML} alt="builder_logo" />
                      :
                        <img style={{ maxHeight: '60px', maxWidth: '200px' }} src="/builderLogo.svg" alt="builder_logo" />}
                    </Box>
                    {this.PopupMenuRender(isProviderDescription, 'provider_desc')}
                    {this.ReactQuillRender(isProviderDescriptionEditing, editorState, 'provider_desc')}
                    <Box
                      className={isProviderDescriptionEditing ? 'hide' : ''}
                      onContextMenu={this.setSelectedBlock}
                      onClick={this.setSelectedBlock}
                      ref={this.providerDescription}
                      sx={{backgroundColor:this.state.providerDescriptionBgColor}}
                      data-testid="providerDescription"
                    >{isShowUpdatedBranding(this.state.providerDescriptionHTML) ?
                      <Box> {HtmlReactParser(this.state.providerDescriptionHTML)}</Box> :
                      <p>{this.state.allContent?.attributes?.company_logo_context}</p>}
                    </Box>
                  </Box>
                </Box>
                  <Box sx={styles.pathTitle}>Your Learning Paths</Box>
                <Box>
                  <Box sx={styles.filterButtonsBlock}>
                    <Box
                      sx={{
                        ...styles.filterButton,
                        ...(this.handleStyle(styles, null))
                      }}
                      data-test-id="all-courses-btn"
                      onClick={() => this.handlePathClick(null)}
                    >
                      All Courses ({this.state.allCourses.length})
                    </Box>
                    {response?.learning_paths?.map((path) => (
                      <Box
                        key={path.data.id}
                        sx={{
                          ...styles.filterButton,
                          ...(this.handleStyle(styles,path.data.id))
                        }}
                      data-test-id={`path-btn-${path.data.id}`}
                      color="info"
                        onClick={() => this.handlePathClick(path.data.id)}
                      >
                        {path.data.attributes.title} ({path.data.attributes.courses.length})
                      </Box>
                    ))}
                  </Box>
                </Box>

                <Box>
                  <Box sx={styles.courseCardsBlock}>
                    {coursesList?.map((course, index) => (
                      <Box
                        padding={"10px"}
                        key={course.id}
                        style={{
                          maxWidth: '300px',
                          width: '300px',
                          minWidth: '300px',
                          height: '350px'
                        }}
                      >
                        <Card style={{
                          width: '100%',
                          height: '100%',
                          position: 'relative'
                        }}>
                          <Box
                            position="relative">
                            <Box
                              style={{
                                position: 'relative',
                                height: '160px',
                                width: '100%',
                                backgroundSize: 'cover',
                                backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8)), url(${course.images.length !== 0 ? course.images[0].url : courseImg})`,
                                backgroundPosition: 'center',
                              }}
                            />
                            <Box
                              position="absolute"
                              left={0}
                              top={0}
                              height="100%"
                              width="100%"
                              flexDirection="column"
                              display="flex"
                              justifyContent="space-between"
                              p={1}
                            >
                              <Box
                                display={"flex"}
                                padding={"5px"}
                                alignItems={"center"}>
                                <img src={timer} height={"16px"} width={"16px"} alt="" />
                                <Typography
                                  color={"white"}
                                  margin={"10px"}>
                                  {course.duration} Hours
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <CardContent>
                            <Typography variant="h6" component="div"> {course.title}</Typography>
                            <Typography variant="body2" color="text.secondary"> {course.lessons} Lessons</Typography>
                            <Typography variant="body2" color="text.secondary"> {course.description}</Typography>
                          </CardContent>
                        </Card>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={styles.footerBlock}>
            <Box sx={styles.leftFooter}>
              <Box>Terms and Conditions</Box>
              <Box>Pravicy Policy</Box>
              <Box></Box>
            </Box>

            <Box>
              <Box>© 2024 All Rights Reserved</Box>
            </Box>
          </Box>
        </Wrapper>
      </StyledEngineProvider>
    )
  }
}
const EditorColorPickerContainer = styled(Box)({
	position: 'absolute',
	top: '100px',
});
const Wrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  ".ql-size-huge": {
    fontSize: "32px"
  },
  ".ql-size-large": {
    fontSize: '24px'
  },
  '.hide': {
    display: 'none'
  },
  "p": {
    margin: '0px',
  },
  ".popup_edit": {
    display: 'flex',
    flexDirection: 'row',
    background: '#FFFFFF',
    width: '270px',
    borderRadius: '4px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
  ".editor-container": {
    position: 'absolute',
    zIndex: 10,
    background: '#FFFFFF',
    borderRadius: '4px',
    maxWidth: '700px',
    maxHeight: '600px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  }
}));

const styles = {
  mainBlock: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#f6f6f6',
    height: '100%'
  },

  footerBlock: {
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
    justifyContent: 'space-between',
    padding: '24px 52px 24px 52px',
    backgroundColor: '#1C1C1C',
    color: '#fff'
  },
  leftFooter: {
    display: 'flex',
    gap: '24px'
  },
  rightFooter: {},

  sideBar: {
    height: '974px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '30px 16px 24px 40px',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff'
  },
  sideBarTop: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
  },
  sideNavList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px'
  },
  sideNavItem: {
    height: '48px',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    padding: '4px 16px',
    borderRadius: '8px'
  },
  logoutButton: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    color: 'red',
    flexGrow: '1',
    justifyContent: 'start',
    fontSize: '16px',
    textTransform: 'none'
  },
  pageContent: {
    display: 'flex',
    flexDirection: 'column',
    scrollbarWidth:'thin',
    width:'100%'
  },
  contentHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 48px'
  },
  navigation: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '16px'
  },
  currentNavigationPage: {
    fontWeight: '700',
    fontSize: '24px',
    padding: '4px 8px'
  },
  breadcrumbButton: {
    fontSize: '24px',
    padding: '4px 8px'
  },
  leftActions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    fontSize: '24px'
  },
  blackButton: {
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: '#333333',
    },
    textTransform: 'none'
  },
  learningPathBtn:{
    '&:hover': {
      backgroundColor: '#fafafa',
    },
    textTransform: 'none',
    backgroundColor:'transparent',
    color:'black',
  },
  saveButton: {
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: '#333333',
    },
    height: '44px',
    padding: '10px 16px 6px',
    fontWeight: '700'
  },
  moreButton: {
    color: 'black',
    padding: '10px'
  },

  userContent: {
    padding: '0 40px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '26px',
    overflowY:'scroll',
    height:'100%'
  },
  greetingBlock: {
    boxSizing:'border-box',
    display: 'flex',
    flexDirection: 'row',
    padding: '24px 16px',
    justifyContent: 'space-between',
    background: '#FFFFFF',
  },
  adminGreeting: {
    background: '#F0F0F0',
    display: 'inline-block',
    fontSize: '32px',
    whiteSpace: 'wrap',
    marginBottom: '16px',
    padding: '4px',
    maxWidth: '700px',
    maxHeight: '400px',
    overflow: 'hidden',
    borderRadius: '4px'
  },
  greetingBg: {
    background: '#F0F0F0',
    paddingRight: '16px',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '4px'
  },
  greetingInfo: {
    display: 'flex',
    paddingLeft: '4px',
    flexDirection: 'column',
    maxWidth: '700px',
    maxHeight: '400px',
    overflow: 'hidden'
  },
  coursesWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  providerBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '290px',
    marginLeft: '16px'
  },
  editorContainer: {
    position: 'absolute',
    background: '#FFFFFF',
    borderRadius: '4px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
  pathTitle: {
    fontSize: '24px',
    fontWeight: '500'
  },

  filterButtonsBlock: {
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
    width:'70vw',
    padding:'10px 0 20px',
    overflowX:'scroll',
    scrollbarWidth:'thin'
  },
  filterButton: {
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    borderRadius:'4px',
    padding: '8px 16px 4px',
    fontSize: '14px',
    width:'max-content',
    minWidth:'140px',
    maxHeight:'40px',
  },
  courseCardsBlock: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '40px',
    borderRadius: '4px',
  },
  courseCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '240px'
  },
  courseCardDescription: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '0px 16px 24px 16px'
  },
  iconsStyle: {
    minWidth: '1px'
  },
  textRow: {
    whiteSpace: 'nowrap'
  }
}

const flexRow = {
  display: 'flex',
  flexDirection: 'row'
}
