export const imgMenu = require("../assets/image_menu_24px.png").default;
export const imgMenuGray = require("../assets/image_menu_24px_gray.png").default;
export const imgMenuFull = require("../assets/image_menu_full.png").default;
export const imgSuccess = require("../assets/success_image.png").default;
export const imgInProgress = require("../assets/in_progress_image.png").default;
export const imgRadioButton = require("../assets/radiobutton_.png").default;
export const imgRadioButtonChecked = require("../assets/radiobutton_checked.png").default;
export const imgCorrectImage = require("../assets/correct_image.png").default;
export const imgIncorrectImage = require("../assets/incorrect_image.png").default;
export const imgPassedTest = require("../assets/image_passed_test.png").default;
export const imgRetake = require("../assets/image_rotate_left_24px.png").default;
export const imgCheck = require("../assets/image_check_24px.png").default;
export const imgClose = require("../assets/image_close_24px.png").default;
export const imgBackground = require("../assets/image_background.png").default;
export const ThinkSpike = require('../assets/ThinkSpike.png').default;