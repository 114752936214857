import React,{CSSProperties} from 'react'
import Select, {
  components,
  ActionMeta,
  IndicatorProps,
  ValueType,
  Props as SelectProps,
} from "react-select";

import {Box} from '@mui/material'
import {ExpandMore} from '@mui/icons-material'

type CustomSelectProps = {
  placeholder: string;
  styles?: React.CSSProperties;
  onChange?: (
    value: ValueType<{ label: string; value: string }, false>,
    actionMeta: ActionMeta<{ label: string; value: string }>
  ) => void;
}
const DropdownIndicator = (
    props: any
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        <ExpandMore data-test-id='expand-indicator' style={{color:'black'}}/>
      </components.DropdownIndicator>
    );
  };
 
const CustomSelect = ({placeholder, styles,onChange}:CustomSelectProps) => {
  return (
    <Box sx={styles}>
      <Select
                components={{
                  DropdownIndicator,
                  IndicatorSeparator: () => null
                }}
                placeholder={<div style={{color:'black'}}>{placeholder}</div>}
                isSearchable={false}
                onChange={onChange}
                styles={{
                  control: (base) => ({
                    ...base,
                    border: ' 1px solid #D6D3D1',
                  boxShadow: '0px 2px 20px 0px #00000014',
                    height: '100%',
                    fontSize:'12px',
                   
                  }),
                  menu: (base) => ({
                    ...base,
                  boxShadow: '0px 2px 20px 0px #00000014',
                    border: 'none',
                    zIndex: '999',
                    marginTop: 0,
                  }),
                  option: (base, props) => ({
                    ...base,
                    backgroundColor: "#fff",
                    color: 'black',
                    fontWeight: props.isSelected ? 'bolder' : 'normal',
                    padding: '15px',
                    fontSize: '14px'
                  }),
                  dropdownIndicator: (
                    base: React.CSSProperties,
                    state: IndicatorProps<{ label: string; value: string }, false>
                  ): React.CSSProperties => {
                    return ({
                      ...base,
                      transition: "all .2s ease",
                      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : undefined,
                    })
                  }
                }}
                data-test-id="filter-select"
              />
    </Box>
  )
}

export default CustomSelect