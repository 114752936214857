import React from 'react'
import {Box} from '@mui/material'

const AdminFooter = () => {
  return (
    <Box sx={webStyles.footerBlock}>
            <Box sx={webStyles.leftFooter}>
              <Box>Terms and Conditions</Box>
              <Box>Privacy Policy</Box>
            </Box>

            <Box>
              <Box>© {new Date().getFullYear()} All Rights Reserved</Box>
            </Box>
          </Box>
  )
}

export default AdminFooter

const webStyles = {
    footerBlock: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '24px 52px 24px 52px',
        backgroundColor: '#1C1C1C',
        color: '#fff',
      },
      leftFooter: {
        display: 'flex',
        gap: '24px',
        flexGrow: 1
      },
}