import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { toast } from "react-toastify";
import { LearningPageData, ResponseData } from "./types";
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  mylearningPageData:LearningPageData[]
  errorMsg: string;
  isLoadingData:boolean;
  coursesCount:number
  // Customizable Area End
}
interface SS {}

export default class MyLearningController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiMyLearningItemCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      mylearningPageData:[],
      coursesCount:0,
      errorMsg: 'string',
      isLoadingData:false
};
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getMyLearningData();
    // Customizable Area Start
    // Customizable Area End
  }

  getMyLearningData(): boolean {
    // Customizable Area Start
    let token = localStorage.getItem('token')
    const webHeader = {
      "Content-Type": webConfigJSON.dashboarContentType,
      "token": token
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiMyLearningItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_content_management/landing_pages/learning_page?per_page=200&page=1'
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    this.setState({isLoadingData:true})
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webResponseJson?.data) {
        if(webApiRequestCallId === this.apiMyLearningItemCallId){
          this.handleMyLearningApiCall(webResponseJson);
        }
      } else if (webResponseJson.errors) {
        this.setState({
          errorMsg: webErrorReponse,
        });
        toast.error(webErrorReponse);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  countCoursePackages(response: ResponseData<any[]>): number {
    return response.data.reduce((count, learningPage) => {
        return count + learningPage?.courses.length;
    }, 0);
}
  handleMyLearningApiCall=(res:ResponseData<LearningPageData[]>)=>{
    this.setState({
      mylearningPageData:res.data, 
      isLoadingData:false,
      coursesCount:this.countCoursePackages(res)
    })
  }
  NavigationLearningInsights = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationLearningInsightsMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  // Customizable Area End
}
