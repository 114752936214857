// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  LinearProgress,
  Grid
} from "@mui/material";
import MixpanelIntegrationController, {
  Props
} from "./MixpanelIntegrationController";
import Header from "../../../components/src/Header.web";
import { ExpandMore,TimerOutlined,MoreVert  } from '@mui/icons-material';
import { CourseThumbnailImage,TrophyGrey,TrophyYello,EndFlag,PlayCirle } from "./assets";
export default class MixpanelIntegration extends MixpanelIntegrationController {
  constructor(props: Props) {
    super(props);
  }

  // new
  

  render() {
    const LearningPath = {
      data: [
        {
          name: "John Doe",
          total_courses: 4,
          completion_percent: 40,
          startTime: "05.10.2024 08:00",
          endTime: "05.10.2024 09:00",
          courseList: [
            {
              id: 101,
              name: "Start the UX Design Process: Empathize, Define, and Ideate 1",
              achievement: "Gain a foundational understanding of a subject or tool. Learn new concepts from industry experts.",
              duration: 6,
              completed: 40,
              lessonsCount: 15
            },
            {
              id: 102,
              name: "Start the UX Design Process",
              achievement: "Gain the understanding of a subject and tools.",
              duration: 5,
              completed: 30,
              lessonsCount: 18
            },
            {
              id: 103,
              name: "Start the UX Design Process: Empathize, Define, and Ideate 3",
              achievement: "Gain the foundation of the user experience and learn more about it",
              duration: 6,
              completed: 40,
              lessonsCount: 15
            },
            {
              id: 104,
              name: "Start the Developemnt: Empathize, Define, and Ideate 4",
              achievement: "Gain a foundational and understand the depth of the subject and learn more about it",
              duration: 5,
              completed: 10,
              lessonsCount: 17
            }
          ]
        },
        {
          name: "Jane Smith",
          total_courses: 3,
          completion_percent: 60,
          startTime: "06.10.2024 08:00",
          endTime: "06.10.2024 09:00",
          courseList: [
            {
              id: 201,
              name: "Introduction to UX Design",
              achievement: "Learn the basics of UX design and understand its importance.",
              duration: 5,
              completed: 80,
              lessonsCount: 12
            },
            {
              id: 202,
              name: "Advanced UX Techniques",
              achievement: "Explore advanced concepts and strategies in UX design.",
              duration: 7,
              completed: 60,
              lessonsCount: 20
            },
            {
              id: 203,
              name: "User Testing and Research",
              achievement: "Gain skills in conducting user research and testing.",
              duration: 6,
              completed: 40,
              lessonsCount: 18
            }
          ]
        }
      ]
    }
    const dummyData = {
      data: [
        {
          name: "John Doe",
          total_courses: 4,
          completion_percent: 100,
          courseList: [
            {
              id: 101,
              name: "Start the UX Developemt Process: Empathize, Define, and Ideate 1",
              achievement: "Gain a foundational developemnt understanding for it and you Learn new concepts from industry experts.",
              duration: 7,
              completed: 55,
              lessonsCount: 9
            },
            {
              id: 102,
              name: "Start the UX Design Process: Empathize, Define, and Ideate 2",
              achievement: "Gain a foundational understanding of a subject or tool. New conscept of testing.",
              duration: 6,
              completed: 45,
              lessonsCount: 11
            },
            {
              id: 103,
              name: "Start the UX Design Process: Empathize, Define 3",
              achievement: "Gain a foundational understanding  or tool. Learn new concepts from industry experts.",
              duration: 7,
              completed: 46,
              lessonsCount: 15
            },
            {
              id: 104,
              name: "Start the UX Design Process: Empathize, Define, and Ideate 4",
              achievement: "Gain a foundational understanding of a subject. Learn new concepts from industry experts.",
              duration: 6,
              completed: 40,
              lessonsCount: 15
            }
          ]
        },
        {
          name: "Jane Smith",
          total_courses: 3,
          completion_percent: 60,
          courseList: [
            {
              id: 201,
              name: "Introduction to UX Design",
              achievement: "Learn the basics of UX design and understand its importance.",
              duration: 5,
              completed: 80,
              lessonsCount: 12
            },
            {
              id: 202,
              name: "Advanced UX Techniques",
              achievement: "Explore advanced concepts and strategies in UX design.",
              duration: 7,
              completed: 60,
              lessonsCount: 20
            },
            {
              id: 203,
              name: "User Testing and Research",
              achievement: "Gain skills in conducting user research and testing.",
              duration: 6,
              completed: 40,
              lessonsCount: 18
            }
          ]
        }
      ]
    }
    return (
      <>
        <div style={{backgroundColor:'#fafafa'}}>
        <Header
          navigation={this.props.navigation}
          islogin={true}
          isHeader={true}
          
        >
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: '30px', justifyContent: 'space-between' }}>
          <Box
            display={"flex"} justifyContent={"space-between"} padding={"10px 0px 20px 0px"}
          >
            <Box sx={{ display: 'flex', gap: '16px', padding: '15px 0px', alignItems: 'center', fontSize: '14px' }}>
              <Button data-test-id={'myLearningNavBtn'} sx={styles.commonButton} onClick={()=>this.props.navigation.navigate('MyLearning')}>
                All Courses (10)
              </Button>
              <Button sx={styles.commonButton}>
                Ongoing
              </Button>
              <Button sx={styles.commonButton}>
                Completed
              </Button>
              <Button sx={{ ...styles.commonButton, ...styles.blackButton }}>
                Learning Insights
              </Button>
            </Box>
          </Box>
        </Box>

          <Box sx={{ display: 'flex', padding: '0 30px', justifyContent: 'space-evenly', columnGap: '10px' }}>
            <Card>
              <CardLabel>Courses Assigned</CardLabel>
              <CardValue>12</CardValue>
            </Card>
            <Card>
              <CardLabel>Courses Completed</CardLabel>
              <CardValue>9</CardValue>
            </Card>
            <Card>
              <CardLabel>Courses Left to Complete</CardLabel>
              <CardValue style={{color:"#F59E0B"}}>3</CardValue>
            </Card>
            <Card>
              <CardLabel>Total Learning Time</CardLabel>
              <CardValue>12h</CardValue>
            </Card>
          </Box>

          <Box sx={{ display: 'flex', padding: '0 30px', justifyContent: 'space-evenly', columnGap: '10px',marginTop:'1rem' }}>
            <Card>
              <CardLabel>Number of Assessments Assigned</CardLabel>
              <CardValue sx={{color:'#F59E0B'}}>10</CardValue>
            </Card>
            <Card>
              <CardLabel>Average Assessment Score</CardLabel>
              <CardValue sx={{color:'#059669'}}>90%</CardValue>
            </Card>
            <Card>
              <CardLabel>Average Time To Complete a Course</CardLabel>
              <CardValue sx={{color:'#059669'}}>2h</CardValue>
            </Card>
          </Box>

            <Box sx={{ padding: '30px 30px 10px 30px' }}>
              <Typography sx={{ fontSize: '24px',marginBottom:'1rem' }}>Accreditation</Typography>
              {dummyData.data.map((courseSection, sectionIndex) => (
                <StyledAccordion key={sectionIndex}>
                  <StyledAccordionSummary>
                    <ContentContainer>
                      <TitleContainer>
                        <StyledTypography>{courseSection.name}</StyledTypography> 
                      </TitleContainer>

                      <GrayTypography>Total courses - {courseSection.total_courses}</GrayTypography> 

                      <ProgressContainer>
                        <ProgressText>{courseSection.completion_percent}% Completed</ProgressText>
                        <StyledLinearProgress
                          sx={{
                            '& .MuiLinearProgress-bar': {
                              backgroundColor: courseSection.completion_percent === 100 ? '#29CC6A' : 'black',
                            },
                          }}
                          variant="determinate"
                          value={courseSection.completion_percent}
                        />
                        {courseSection.completion_percent === 100 ? (
                          <img src={TrophyYello} />
                        ) : (
                          <img src={TrophyGrey} />
                        )}
                      </ProgressContainer>

                    </ContentContainer>
                    
                  </StyledAccordionSummary>

                  <AccordionDetails>
                    <Grid container sx={{padding:'15px'}} spacing={3} marginTop={-5}>
                      {courseSection.courseList.map((lesson, lessonIndex) => (
                        <Grid item xs={3} key={lesson.id}>
                          
                          <Box data-test-id={'course-' + lessonIndex} sx={styles.courseCard}>
                            
                            <BoxThumbnail>
                              <img src={CourseThumbnailImage} />
                              <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                                alignItems: 'center',
                                boxSizing: 'border-box',
                                height: '65px',
                                padding: '10px',
                              }}>
                              </div>
                              <Typography
                                style={{
                                  display: 'flex',
                                  padding: '10px',
                                  color: '#fff',
                                  alignItems: 'center',
                                  zIndex:'2',
                                  fontSize: '14px',
                                }}
                              >
                                <TimerOutlined style={{ marginRight: '5px' }} />
                                {lesson.duration} Hours - {lesson.completed}% Completed
                              </Typography>
                            </BoxThumbnail>
                            <Box sx={styles.courseCardDescription}>
                              <Box sx={{color:'black', fontSize: '16px', }}>{lesson.name}</Box>
                              <Box sx={{ fontSize: '12px', color: '#171717' }}>{lesson.lessonsCount} lessons</Box>
                              <Box sx={{color: "#8A8A8A", fontSize: '14px',  }}>
                                <b style={{ color: "#1A1A1A" }}>You will learn:</b> {lesson.achievement}
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </StyledAccordion>
              ))}
            </Box>
            <Box sx={{ padding: '0px 30px',marginBottom:'10rem' }}>
              <Typography sx={{ fontSize: '24px', marginBottom: '1rem' }}>Your Learning Paths</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box
                  display={"flex"} justifyContent={"space-between"} padding={"10px 0px 20px 0px"}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '14px',columnGap:'10px' }}>
                    <Button sx={styles.commonButton}>
                      All Courses (10)
                    </Button>
                    <Button sx={styles.commonButton}>
                      Experience Design (6)
                    </Button>
                    <Button sx={styles.commonButton}>
                      Interfact Design (4)
                    </Button>
                  </Box>
                </Box>
              </Box>
              {LearningPath.data.map((courseSection, sectionIndex) => (
                <StyledAccordion key={sectionIndex}>
                  <StyledAccordionSummary>
                    <ContentContainer>
                      <TitleContainer>
                        <StyledTypography>{courseSection.name}</StyledTypography> 
                      </TitleContainer>

                      <div>
                        <div style={{ display: 'flex', marginBottom: '4px' }}>
                          <img src={PlayCirle} style={{ marginRight: '8px' }} />
                          <GrayTypography>{courseSection.startTime}</GrayTypography> 
                        </div>
                        <div style={{ display: 'flex', marginTop: '4px' }}>
                          <img src={EndFlag} style={{ marginRight: '8px' }} />
                          <GrayTypography>{courseSection.endTime}</GrayTypography> 
                        </div>
                      </div>

                      <ProgressContainer>
                        <ProgressText>{courseSection.completion_percent}% Completed</ProgressText> 
                        <StyledLinearProgress variant="determinate" value={courseSection.completion_percent} />
                        <MoreVert />
                      </ProgressContainer>
                    </ContentContainer>
                  </StyledAccordionSummary>

                  <AccordionDetails>
                    <Grid container sx={{ padding: '15px' }} spacing={3} marginTop={-5}>
                      {courseSection.courseList.map((lesson, lessonIndex) => (
                        <Grid item xs={3} key={lesson.id}>
                          <Box data-test-id={'course-' + lessonIndex} sx={styles.courseCard}>
                            <BoxThumbnail>
                              <img src={CourseThumbnailImage} alt="ux_courses" />
                              <div
                                style={{
                                  display: 'flex',
                                  width: '100%',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  padding: '10px',
                                  height: '65px',
                                  boxSizing: 'border-box'
                                }}
                              />
                              <Typography
                                style={{
                                  display: 'flex',
                                  color: '#fff',
                                  padding: '10px',
                                  fontSize: '14px',
                                  alignItems: 'center',
                                  zIndex: '2'
                                }}
                              >
                                <TimerOutlined style={{ marginRight: '5px' }} />
                                {lesson.duration} Hours - {lesson.completed}% Completed
                              </Typography>
                            </BoxThumbnail>
                            <Box sx={styles.courseCardDescription}>
                              <Box sx={{ fontSize: '16px', color: 'black' }}>{lesson.name}</Box>
                              <Box sx={{ fontSize: '12px', color: '#171717' }}>{lesson.lessonsCount} lessons</Box>
                              <Box sx={{ fontSize: '14px', color: '#8A8A8A' }}>
                                <b style={{ color: '#1A1A1A' }}>You will learn:</b> {lesson.achievement}
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </StyledAccordion>
              ))}

            </Box>
        </Header>
        </div>
      </>
    );
  }
}

const BoxThumbnail = styled(Box)({
  boxSizing:'border-box',
  position:'relative',
  width: '100%',
  display:'flex',
  flexDirection:'column',
  justifyContent:'space-between',
      height:'160px',
  "&>img":{
      position: 'absolute',
      width:'100%',
      height:'100%',
      zIndex:'1'
  }
})
const Card = styled(Box)({
  padding:'16px 32px',
  backgroundColor:'white',
  width:'100%',
  boxShadow: '0px 2px 20px 0px #00000014',
  borderRadius:'4px'
})
const CardLabel = styled(Typography)({
  fontSize:'0.875rem',
  fontWeight:700,
  fontFamily:'Heebo',
  color:'#6A6A6A'
})
const CardValue = styled(Typography)({
  fontSize:"1.875rem",
  fontWeight:700,
  fontFamily:'Heebo'
})
const StyledTypography = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '16px',
});

const StyledLinearProgress = styled(LinearProgress)({
  backgroundColor: '#e7e5e4', 
  height: '8px', 
  width: '100%',
  marginRight: '0.8rem',
  '& .MuiLinearProgress-bar': {
      backgroundColor: 'black', 
  },
});

const StyledAccordion = styled(Accordion)({
  border: '1px solid #D6D3D1',
  boxShadow: 'none', 
  marginBottom:'10px'
});


const StyledAccordionSummary = styled((props:any) => (
  <AccordionSummary
    expandIcon={
      <ExpandMore
        
      />
    }
    {...props}
  />
))(({ theme }) => ({
  
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(0),
  },
}));
const ContentContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  margin: '0 1rem',
});

const TitleContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const ProgressContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '30%',
});

const GrayTypography = styled(Typography)({
  color: 'gray',
});

const ProgressText = styled(Typography)({
  marginRight: '0.5rem',
  whiteSpace: 'nowrap',
});
const styles = {
  mainBlock: {
    flexDirection: 'row',
    display: 'flex',
  },
  logoutButton: {
    flexDirection: 'row',
    display: 'flex',
    color: 'red',
    gap: '8px',
    justifyContent: 'start',
    flexGrow: '1',
    textTransform: 'none',
    fontSize: '16px',
  },
  commonButton: {
    backgroundColor: 'white',
    fontSize: '14px',
    padding: '5px 15px',
    textTransform: 'none',
    color: 'black',
  },
  blackButton: {
    color: 'white',
    backgroundColor: 'black',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#333333',
    },
  },
  courseCard: {
    display: 'flex',
    width: '100%',
    maxWidth: '350px',
    gap: '16px',
    flexDirection: 'column',
    boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.08)',
  },
  courseCardDescription: {
    gap: '16px',
    flexDirection: 'column',
    padding: '0px 16px 24px 16px',
    display: 'flex',
  },
}



// Customizable Area End
