import React from 'react';

// Customizable Area Start
import {
    Box,
    Typography,
    Button,
    styled,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    MenuItem,
    Checkbox,
    ListItemText,
    Pagination,
    CircularProgress
} from '@mui/material';
import Sidebar from '../../../components/src/sidebar/Sidebar.web';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ArrowDropUp, ArrowDropDown, KeyboardArrowDown } from '@mui/icons-material';
// Customizable Area End

import ClientWideAnalyticsController, {
    Props,
} from './ClientWideAnalyticsController';


export default class ClientWideAnalytics extends ClientWideAnalyticsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            clientsCount,
            usersCount,
            totalCoursesAssigned,
            totalCoursesCompleted,
            accreditationsAchieved,
            averageUserCompletionRate,
            totalLearningPathCompleted,
            totalLearningHours,
            tableData,
            order,
            orderBy,
            clientList,
            selectedClients,
            currentPage,
            rowsPerPage
        } = this.state;

        const totalRows = tableData.length;
        const startIndex = (currentPage - 1) * rowsPerPage + 1;
        const endIndex = Math.min(startIndex + rowsPerPage - 1, totalRows);

        const columns = [
            { id: "clientName", label: "Client Name" },
            { id: "users", label: "Users" },
            { id: "courseAssigned", label: "Courses Assigned" },
            { id: "coursesCompleted", label: "Courses Completed" },
            { id: "accreditationAchieved", label: "Accreditation Achieved" },
            { id: "avgCompletionRate", label: "Average User Completion Rate" },
            { id: "totalLearningPathCompleted", label: "Total Learning Path Completed" },
            { id: "totalLearningHours", label: "Total Learning Hours" },
        ];

        const renderTabs = () => {
            return (
                <Box sx={{ padding: '4px 6px', backgroundColor: 'white', width: 'fit-content', borderRadius: '4px' }}>
                    <TabButton data-test-id="user-tab" onClick={()=>this.handleChangeTab('UserAnalytics')}>User Analytics</TabButton>
                    <BlackButton sx={{ padding: '6px 10px', fontSize: '16px' }}>Client Wide Analytics</BlackButton>
                    <TabButton data-test-id='course-tab' onClick={()=>this.handleChangeTab('CourseAnalytics')}>Course Analytics</TabButton>
                    <TabButton>Feedback Aggregation</TabButton>
                    <TabButton>Reports</TabButton>
                </Box>
            )
        }
        const renderCards = () => {
            return (
                <GridContainer>
                    <Card>
                        <LabelText>Clients</LabelText>
                        <LabelValue>{clientsCount}</LabelValue>
                    </Card>
                    <Card>
                        <LabelText>Users</LabelText>
                        <LabelValue>{usersCount}</LabelValue>
                    </Card>
                    <Card>
                        <LabelText>Total Courses Assigned</LabelText>
                        <LabelValue sx={{color:'#F59E0B'}}>{totalCoursesAssigned}</LabelValue>
                    </Card>
                    <Card>
                        <LabelText>Total Courses Completed</LabelText>
                        <LabelValue>{totalCoursesCompleted}</LabelValue>
                    </Card>
                    <Card>
                        <LabelText>Accreditations Achieved</LabelText>
                        <LabelValue sx={{color:'#F59E0B'}}>{accreditationsAchieved}</LabelValue>
                    </Card>
                    <Card>
                        <LabelText>Average User Completion Rate</LabelText>
                        <LabelValue sx={{color:'#F59E0B'}}>{averageUserCompletionRate}%</LabelValue>
                    </Card>
                    <Card>
                        <LabelText>Total Learning Path Completed</LabelText>
                        <LabelValue sx={{color:'#059669'}}>{totalLearningPathCompleted}</LabelValue>
                    </Card>
                    <Card>
                        <LabelText>Total Learning Hours</LabelText>
                        <LabelValue sx={{color:'#059669'}}>{totalLearningHours}h</LabelValue>
                    </Card>
                </GridContainer>
            )
        }
        const renderFooter = () => {
            return (
                <Box sx={webStyles.footerBlock}>
                    <Box sx={webStyles.leftFooter}>
                        <Box>Terms and Conditions</Box>
                        <Box>Pravicy Policy</Box>
                        <Box></Box>
                    </Box>
                    <Box>
                        <Box>© 2024 All Rights Reserved</Box>
                    </Box>
                </Box>
            )
        }

        return (
            <>
                <Box sx={webStyles.mainWrapper}>
                    <Box sx={webStyles.mainBlock}>
                        <Box>
                            <Sidebar current='analytics' navigation={this.props.navigation} />
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '10px', justifyContent: 'space-between', padding: '1rem 1.5rem', marginTop:'1rem' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: '24px', fontFamily: "Heebo-Bold" }}>Analytics</Typography>
                                    <Typography sx={{ marginLeft: '1rem', fontSize: '16px', fontFamily: "Heebo-Bold" }}>
                                        Jul 30 - Aug 5
                                    </Typography>
                                    <KeyboardArrowDown sx={{marginLeft:'0.5rem'}}/>
                                </div>
                                <BlackButton
                                    data-test-id='save-btn'
                                >Export Data</BlackButton>
                            </Box>
                            <Box sx={{ padding: '1rem 1.5rem' }}>
                                {renderTabs()}
                            </Box>



                            <div style={{ padding: '1rem 1.5rem', display: 'flex', alignItems: 'center', columnGap: '10px', justifyContent: 'space-between' }}>
                                <StyledSelect
                                    displayEmpty
                                    data-test-id="dropdown"
                                    IconComponent={(props) =><ArrowDropDownIcon {...props} style={{ color: "black" }} />}
                                    value={selectedClients}
                                    multiple
                                    sx={{ width: '90%' }}
                                    renderValue={(selected: any) => this.renderClientNames(selected)}
                                >
                                    <MenuItem sx={{ display: 'none' }} value="" >
                                        All Client
                                    </MenuItem>
                                    {clientList.map((client: any) => {
                                        return (
                                            <MenuItem key={client.id} value={client.id}>
                                                <ListItemText key={client.id} primary={client.attributes.company_name} />
                                                <Checkbox
                                                    data-testId={`client-cb-${client.id}`}
                                                    checked={selectedClients.includes(client.id)}
                                                    onChange={() => this.handleCheckboxToggle(client.id)}
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: 'black',
                                                            borderRadius: '6px',
                                                            '&:hover': {
                                                                backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                                            },
                                                        },
                                                    }}
                                                />
                                            </MenuItem>
                                        );
                                    })}

                                </StyledSelect>

                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography>Filter</Typography>
                                        <FilterListIcon style={{ marginLeft: '8px' }} />
                                    </div>
                                </div>
                            </div>

                            {this.state.isLoading ?
                                <Box sx={{ width: '100%', height: '100vh' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <CircularProgress
                                        style={{
                                            height: '100px',
                                            width: '100px',
                                            boxShadow: 'inset 0 0 1px 1px #D9d9d9',
                                            borderRadius: '50%'
                                        }}
                                        sx={{ 'svg circle': { stroke: '#1c1c1c' } }}
                                    />
                                </Box>
                                :
                                <div>
                                    <Box sx={{ padding: '1rem 1.5rem' }}>
                                        {renderCards()}
                                    </Box>

                                    <Box sx={{ padding: '1rem 1.5rem',marginBottom:'2rem' }}>
                                        <TableContainer sx={{ borderRadius: '6px',background:'white' }} component={Paper}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        {columns.map((column) => (
                                                            <ColumnNameCell key={column.id}>
                                                                <div style={{ display: "flex", alignItems: "center", width: 'fit-content' }}>
                                                                    {column.label}
                                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                                        <ArrowDropUp
                                                                            data-test-id={`sort-icon-${column.id}`}
                                                                            fontSize='small'
                                                                            style={{
                                                                                marginBottom: "-10px",
                                                                                cursor: "pointer",
                                                                                color: orderBy === column.id && order === "asc" ? "black" : "gray",
                                                                            }}
                                                                            onClick={() => this.handleSort(column.id)}
                                                                        />
                                                                        <ArrowDropDown
                                                                            data-test-id={`dsc-icon-${column.id}`}
                                                                            fontSize='small'
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                color: orderBy === column.id && order === "desc" ? "black" : "gray",
                                                                            }}
                                                                            onClick={() => this.handleSort(column.id)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </ColumnNameCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {tableData.slice(startIndex - 1, endIndex).map((row: any, index: number) => (
                                                        <TableRow key={index}>
                                                            <TabValueCell>{row.clientName}</TabValueCell>
                                                            <TabValueCell>{row.users}</TabValueCell>
                                                            <TabValueCell>{row.courseAssigned}</TabValueCell>
                                                            <TabValueCell>{row.coursesCompleted}</TabValueCell>
                                                            <TabValueCell>{row.accreditationAchieved}</TabValueCell>
                                                            <TabValueCell>{row.avgCompletionRate}%</TabValueCell>
                                                            <TabValueCell>{row.totalLearningPathCompleted}</TabValueCell>
                                                            <TabValueCell>{row.totalLearningHours}</TabValueCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>

                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem 1.5rem' }}>
                                                <span></span>
                                                <CustomPagination
                                                    data-test-id='pagination'
                                                    size='small'
                                                    siblingCount={0}
                                                    boundaryCount={1}
                                                    count={Math.ceil(totalRows / rowsPerPage)}
                                                    page={currentPage}
                                                    onChange={this.handlePageChange}
                                                />
                                                <Typography sx={{ fontSize: "12px" }}>
                                                    {startIndex}-{endIndex} of {totalRows} results
                                                </Typography>
                                            </Box>
                                        </TableContainer>
                                    </Box>
                                </div>
                            }
                        </Box>
                    </Box>
                    {renderFooter()}
                </Box>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const TabButton = styled(Button)({
    backgroundColor: 'white',
    fontFamily: 'Heebo',
    fontWeight: '700',
    textTransform: 'none',
    color: '#6A6A6A',
    fontSize: '16px',
    padding: '6px 10px'
})
const BlackButton = styled(Button)({
    textTransform: 'none',
    color: 'white',
    fontFamily: 'Heebo',
    backgroundColor: 'black',
    fontWeight: 700,
    height: 'fit-content',
    fontSize: '14px',
    '&:hover': {
        backgroundColor: 'black',
        color: 'white',
    },
})
const Card = styled(Box)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: '16px',
    paddingBottom: "10px",
    backgroundColor: 'white',
    borderRadius: '8px',
    borderBottomRightRadius: '32px'
})
const GridContainer = styled(Box)({
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '16px',
});
const LabelText = styled(Typography)({
    fontSize: '14px',
    fontFamily: 'Heebo-Bold',
    color: '#6A6A6A'
})
const LabelValue = styled(Typography)({
    fontSize: '1.6rem',
    fontFamily: 'Heebo',
    fontWeight: '700'
})
const StyledSelect = styled(Select)({
    backgroundColor: 'white',
    borderRadius: '4px',

    '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: '12px',
    },
})
const ColumnNameCell = styled(TableCell)({
    fontSize: '13px',
    lineHeight: '1.5',
    fontWeight: 'bold',
    color: "#475569",
    padding: '8px',
    paddingLeft: '12px'
})
const TabValueCell = styled(TableCell)({
    color: "#0F172A",
    fontFamily: 'Heebo',
    fontSize: '14px',
    fontWeight: '400'
})
const CustomPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
        "&.Mui-selected": {
            backgroundColor: "transparent",
            color: "#6200EA",
            fontWeight: "700",
        },
    },
})
const webStyles = {
    mainWrapper: {
        backgroundColor: '#f6f6f6',
        flex: 1,
        flexDirection: 'col',

    },
    mainBlock: {
        display: 'flex',
        backgroundColor: '#f6f6f6',
        flexDirection: 'row',
    },
    footerBlock: {
        display: 'flex',
        padding: '24px 52px 24px 52px',
        justifyContent: 'space-between',
        flexDirection: 'row',
        color: '#fff',
        backgroundColor: '#1C1C1C',
    },
    leftFooter: {
        gap: '24px',
        display: 'flex',
        flexGrow: 1
    },
}
// Customizable Area End
