import React from "react";
import {
    Modal, Box, Button, Typography,Dialog,DialogContent,DialogActions, DialogTitle,
    InputBase, Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Select, { components } from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { imageArrowLeft } from "./assets";
import CourseCreationHeaderController, { Props } from "./CourseCreationHeaderController";

export default class CourseCreationHeader extends CourseCreationHeaderController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    renderTopBar = () => {
        return (
            <Header data-test-id="wrapper">
                <div className="content">
                    <div className="block">
                        <DefaultCustomButton onClick={this.onBack} style={buttonStyles.backButtonStyle} data-test-id="back-btn">
                            <img src={imageArrowLeft} alt="" />
                            <span>{this.props.isLessonVisible || this.props.isQuizVisible ? "Back" : "Course Dashboard"}</span>
                        </DefaultCustomButton>
                    </div>{
                        this.props.isQuizVisible ? <div className="block" style={{ paddingRight: '20px', cursor:'pointer'}}>
                            <DefaultCustomButton data-test-id="requirements-button-quiz" onClick={() => this.setState({ requirementsModal: { isVisible: true } })} style={buttonStyles.emptyButtonStyle}>Requirements</DefaultCustomButton>
                            <DefaultCustomButton data-test-id="save-button-quiz" style={buttonStyles.blackButtonStyle} onClick={()=>this.props.onSaveQuiz()}>Save</DefaultCustomButton>
                        </div> :
                        <div className="block">
                            {!this.props.isLessonVisible && <DefaultCustomButton data-test-id="requirements-button" onClick={() => this.setState({ requirementsModal: { isVisible: true } })} style={buttonStyles.emptyButtonStyle}>Requirements</DefaultCustomButton>}
                            {this.props.isLessonVisible && <DefaultCustomButton style={this.props.isPreview ? buttonStyles.previewBlackButtonStyle : buttonStyles.emptyButtonStyle} onClick={this.props.onPreview} data-test-id="preview-btn">Preview</DefaultCustomButton>}
                            {!this.props.isLessonVisible && <DefaultCustomButton style={buttonStyles.whiteButtonStyle} onClick={this.openPackageDialog} data-test-id="open-dialog">Assign Learning Path</DefaultCustomButton>}
                            {!this.props.isLessonVisible && <DefaultCustomButton data-test-id="publish-btn" style={buttonStyles.blackButtonStyle} onClick={() => this.props.onPublish(this.state.totalHours, this.state.passingPercentage, this.state.courseRequirements, this.state.packageIds)}>Publish</DefaultCustomButton>}
                        </div>
                    }
                </div>
                {this.props.isQuizVisible && <div style={{borderBottom:'0.5px',boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'}}>
                    <Box display={"flex"} padding={'10px 0px 20px 0px'} justifyContent={"center"} alignItems={"center"}>
                        <CustomInputTitle
                            fullWidth
                            autoFocus
                            value={this.state.assessmentTitle}
                            onChange={(e) => this.setAssessmentTitle(e.target.value)}
                            data-test-id={"custom-assessment-title-input"}
                            placeholder={'Assessment title here'}
                        />
                    </Box>
                </div>}
            </Header>
        );
    }
        renderModalMenu = () => {
        return (
            <Modal
                open={this.state.requirementsModal.isVisible}
                onClose={this.onHideRequirementsModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {this.props.isQuizVisible ? <>
                    <CourseRequirements>
                        <Formik
                            data-test-id="formik"
                            initialValues={{
                                noOfQuestions: this.state.noOfQuestions,
                                noOfAnswers: this.state.noOfAnswers,
                                retakeOption: this.state.retakeOption,
                                showAnswer: this.state.showAnswer,
                            }}
                        enableReinitialize
                        onSubmit={(values) => {
                            this.submitAssessmentRequirement(values);
                        }}
                    >
                        {({ errors, touched, setFieldValue, handleSubmit }) => (
                            <form
                                noValidate
                                autoComplete="off"
                                onSubmit={(event) => {
                                    event.preventDefault();
                                    handleSubmit()
                                }}
                                className="form"
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault();
                                    }
                                }}
                            >
                                <CourseRequirementsHeader>
                                    <Typography className="title">Assessment Requirement</Typography>
                                    <CloseIcon className="close-icon" data-test-id="close-btn" onClick={this.onHideRequirementsModal} />
                                </CourseRequirementsHeader>
                                <CourseRequirementsContent>
                                    <Box className="dialog-content">
                                        <Box>
                                            <Typography className="label-title">Select Number of Questions for Test</Typography>
                                            <CustomInput
                                                data-test-id="input-total-questions"
                                                fullWidth
                                                type={"number"}
                                                value={this.state.noOfQuestions}
                                                inputMode="numeric"
                                                onInput={this.handleNumericInput}
                                                onChange={(e) => this.setNoOfQuestions(e)}
                                            />
                                        </Box>
                                        <Box>
                                            <Typography className="label-title">Set number of correct answers for passing</Typography>
                                            <CustomInput
                                                data-test-id="input-correct-passing-answers"
                                                fullWidth
                                                type={"number"}
                                                value={this.state.noOfAnswers}
                                                inputMode="numeric"
                                                onInput={this.handleNumericInput}
                                                onChange={(e) => {this.setNoOfAnswers(e)}}
                                            />
                                        </Box>
                                            <Box display={"flex"} flexDirection={'row'} justifyContent={'space-between'} alignContent={'center'}>
                                                <Box justifyContent={'center'} alignContent={'center'}>
                                                    <Typography className="label-title-assessment" >Option to retake the test</Typography></Box>
                                                <Box> 
                                                    <CustomCheckbox
                                                    data-test-id="retake-checkbox"
                                                    checked={this.state.retakeOption}
                                                    onChange={() => this.setState({ retakeOption: !this.state.retakeOption })}
                                                    data-testid={`checkbox-retake`}
                                                /></Box>
                                            </Box>
                                            <Box display={"flex"} flexDirection={'row'} justifyContent={'space-between'} alignContent={'center'}>
                                                <Box justifyContent={'center'} alignContent={'center'}>
                                                    <Typography className="label-title-assessment">Show correct answer</Typography></Box>
                                                <Box>
                                                    <CustomCheckbox
                                                        data-test-id="show-correct-checkbox"
                                                        checked={this.state.showAnswer}
                                                        onChange={() => this.setState({ showAnswer: !this.state.showAnswer })}
                                                        data-testid={`checkbox-show-correct`}
                                                    /></Box>
                                            </Box>
                                        </Box>

                                </CourseRequirementsContent>
                                <CourseRequirementsFooter>
                                    <CancelButton onClick={this.onHideRequirementsModal}>Cancel</CancelButton>
                                    <SaveButton data-test-id="submit-button" type="submit">Save</SaveButton>
                                </CourseRequirementsFooter>
                            </form>
                        )}
                    </Formik>
                </CourseRequirements>
                </> : <CourseRequirements>
                    <Formik
                        data-test-id="formik"
                        initialValues={{
                            totalHours: this.state.totalHours,
                            passingPercentage: this.state.passingPercentage,
                            courseRequirements: this.state.courseRequirements,
                        }}
                        enableReinitialize
                        onSubmit={(values) => {
                            this.submitCourseRequirements(values);
                        }}
                    >
                        {({ errors, touched, values, setFieldValue, handleSubmit }) => (
                            <form
                                noValidate
                                autoComplete="off"
                                onSubmit={(event) => {
                                    event.preventDefault();
                                    handleSubmit()
                                }}
                                className="form"
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault();
                                    }
                                }}
                            >
                                <CourseRequirementsHeader>
                                    <Typography className="title">Course Requirements</Typography>
                                    <CloseIcon className="close-icon" data-test-id="close-btn" onClick={this.onHideRequirementsModal} />
                                </CourseRequirementsHeader>
                                <CourseRequirementsContent>
                                    <Box className="dialog-content">
                                        <Box>
                                            <Typography className="label-title">Total Hours</Typography>
                                            <CustomInput
                                                data-test-id="input-total-hours"
                                                fullWidth
                                                type={"number"}
                                                inputMode="numeric"
                                                value={values.totalHours}
                                                onInput={this.handleNumericInput}
                                                placeholder="Total lesson hours"
                                                onChange={(e: { target: { value: string; }; }) => {
                                                    setFieldValue("totalHours", e.target.value.trim());
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            <Typography className="label-title">Passing Percentage</Typography>
                                            <CustomInput
                                                data-test-id="input-passing-percentage"
                                                fullWidth
                                                type={"number"}
                                                inputMode="numeric"
                                                value={values.passingPercentage}
                                                onInput={(event: React.FormEvent<HTMLDivElement>) => {this.handleNumericInput(event); this.handleValdidateMaxValue(event)}}
                                                placeholder="Minimum passing percentage"
                                                onChange={(e: { target: { value: string; }; }) => {
                                                    setFieldValue("passingPercentage", e.target.value.trim());
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            <Typography className="label-title">Course Requirements</Typography>
                                            <CustomInput
                                                fullWidth
                                                data-test-id="input-course-requirements"
                                                placeholder="Write course requirement here"
                                                value={values.courseRequirements}
                                                onChange={(e: { target: { value: string; }; }) => {
                                                    setFieldValue("courseRequirements", e.target.value);
                                                }}
                                            />
                                        </Box>
                                    </Box>

                                </CourseRequirementsContent>
                                <CourseRequirementsFooter>
                                    <CancelButton onClick={this.onHideRequirementsModal}>Cancel</CancelButton>
                                    <DoneButton data-test-id="submit-button" type="submit">Save</DoneButton>
                                </CourseRequirementsFooter>
                            </form>
                        )}
                    </Formik>
                </CourseRequirements>}
            </Modal>
        );
    }

    learningPathSchema = () => {
        return Yup.object().shape({
          title: Yup.string().required("*Title is required"),
          description: Yup.string().required("*Description is required"),
        });
    }

    renderLearningPathDialog = () => {
        return (
            <CustomDialog
                open={this.state.isPackageDialogOpen}
                onClose={this.closePackageDialog}
            >
                <DialogTitle className="dialog-title">
                    <Typography className="heading">Assign Learning Path</Typography>
                    <CloseIcon className="close-icon" data-test-id="close-btn" onClick={this.closePackageDialog} />
                </DialogTitle>
                <DialogContent className="content">
                    <Box>
                        <Typography sx={{ marginBottom: "5px" }}>Learning Path</Typography>
                        <Select
                            classNamePrefix="react-select"
                            placeholder="Select Learning Path"
                            data-test-id="list-dropdown"
                            options={this.state.packageList}
                            isMulti={true}
                            allowSelectAll={true}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            openMenuOnClick={true}
                            components={{
                                Option: (props) => <div>
                                    <components.Option {...props}>
                                        <label>{props.label}</label>
                                        <Checkbox
                                            disableRipple
                                            icon={<div className="unchecked" />}
                                            checkedIcon={<div className="checked"><DoneIcon /></div>}
                                            checked={props.isSelected}
                                            onChange={() => null}
                                        />
                                    </components.Option>
                                </div>
                            }}
                            value={
                                this.state.packageList.filter((option: { value: string; }) =>
                                    !!this.state.tempIds.find((ele: string) => ele === option.value)
                                )
                            }
                            onChange={(event) => {
                                let ids = [];
                                if (event) {
                                    ids = event.map((obj) => {
                                        return obj.value;
                                    });
                                }
                                this.setState({ tempIds: ids });
                            }}
                        />
                    </Box>
                    <Box m="20px 0px" display="flex" justifyContent="center">
                        <Typography>OR</Typography>
                    </Box>
                    <Formik
                        initialValues={{
                            title: "",
                            description: ""
                        }}
                        enableReinitialize
                        data-test-id="learning-formik"
                        validationSchema={this.learningPathSchema}
                        onSubmit={(values) => {
                              this.submitLearningPath(values);
                        }}
                    >
                        {({ errors, touched, values, setFieldValue, handleSubmit }) => (
                            <form
                                noValidate
                                autoComplete="off"
                                onSubmit={handleSubmit}
                                className="form"
                            >
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography className="create-title">Create new learning path</Typography>
                                    <DoneButton
                                        type="submit"
                                        sx={{
                                            width: "fit-content",
                                            padding: "8px 16px !important",
                                            borderRadius: "4px !important",
                                            fontSize: "14px !important",
                                        }}
                                    >
                                        Create
                                    </DoneButton>
                                </Box>
                                <Box mt="10px">
                                    <Typography className="label-title">Title of Learning Path</Typography>
                                    <CustomInputBase
                                        fullWidth
                                        data-test-id="title"
                                        value={values.title}
                                        placeholder="Write title here"
                                        onChange={(e: { target: { value: string; }; }) => {
                                            this.setState({ titleError: "" });
                                            setFieldValue("title", e.target.value);
                                        }}
                                    />
                                    {touched.title && errors.title &&
                                        <div className="text-danger">{errors.title}</div>
                                    }
                                    {this.state.titleError &&
                                        <div className="text-danger">{this.state.titleError}</div>
                                    }
                                </Box>
                                <Box mt="15px">
                                    <Typography className="label-title">Description</Typography>
                                    <CustomInputBase
                                        rows={5}
                                        fullWidth
                                        multiline={true}
                                        data-test-id="description"
                                        value={values.description}
                                        placeholder="Add description about learning path"
                                        onChange={(e: { target: { value: string; }; }) => {
                                            setFieldValue("description", e.target.value);
                                        }}
                                    />
                                    {touched.description && errors.description &&
                                        <div className="text-danger">{errors.description}</div>
                                    }
                                </Box>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
                <DialogActions className="dialog-action">
                    <CancelButton
                        sx={{
                            padding: "8px 40px !important",
                            borderRadius: "4px !important",
                            width: "fit-content",
                        }}
                        data-test-id="cancel-btn"
                        onClick={this.closePackageDialog}
                     >
                        Cancel
                    </CancelButton>
                    <DoneButton
                        sx={{
                            padding: "8px 40px !important",
                            borderRadius: "4px !important",
                            width: "fit-content",
                        }}
                        data-test-id="save-btn"
                        onClick={this.onSavePackageList}
                    >
                        Assign
                    </DoneButton>
                </DialogActions>
            </CustomDialog>
        );
    }

    render() {
        return (
            <>
                {this.renderModalMenu()}
                {this.renderLearningPathDialog()}
                <header style={{borderBottom: this.props.isQuizVisible ? 'none' : '1px solid #E7E5E4',padding:this.props.isQuizVisible? "20px 0px 20px 0px":"20px 43px 20px 0px" }}>{this.renderTopBar()}</header>
                <main>
                    {this.props.children}
                </main>
            </>
        );
    }
}

// Customizable Area Start
const CustomDialog = styled(Dialog)({
    '& .MuiPaper-root': {
        maxWidth: '800px',
        width: '100%',
        borderRadius: "8px",
    },
    "& .dialog-title": {
        padding: "15px 30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #E2E8F0",
        "& .close-icon": {
            cursor: "pointer",
            color: "#334155",
        },
    },
    "& .create-title": {
        fontSize: "20px",
        color: "##1C1C1C",
        fontFamily: "Heebo-SemiBold",
    },
    "& .label-title": {
        fontSize: "14px",
        color: "#64748B",
        fontFamily: "Heebo",
        fontWeight: 600,
        padding: '5px 0',
    },
    "& .heading": {
        fontSize: "24px",
        fontFamily: "Heebo-Bold",
    },
    "& .content": {
        padding: "20px 30px 25px !important",
    },
    "& .dialog-action": {
        padding: "10px 17px",
        borderTop: "1px solid #E2E8F0",
    },
    "& .react-select__placeholder": {
        paddingLeft: "7px"
    },
    "& .react-select__input": {
        paddingLeft: "7px",
    },
    "& .unchecked": {
        width: "20px",
        height: "20px",
        border: "1px solid #484848",
        borderRadius: "3px",
    },
    "& .checked": {
        width: "20px",
        height: "20px",
        backgroundColor: "#000",
        border: "1px solid #000",
        borderRadius: "3px",
    },
    "& .checked svg": {
        color: "#fff",
        fontSize: "20px"
    },
    "& .react-select__multi-value__remove:hover": {
        backgroundColor: "black",
        color: "#fff",
    },
    ".react-select__menu-list": {
        maxHeight: "223px",
    },
    "& .react-select__menu": {
        color: "black",
    },
    "& .react-select__option": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #e8e8e8",
        backgroundColor: "#fff",
        color: "black",
        marginTop: "0px",
        padding: "0px 12px",
        paddingLeft: "0px",
        fontSize: "14px",
        "& label": {
            marginLeft: "15px"
        }
    },
    "& .react-select__option--is-selected": {
        fontFamily: "Heebo-SemiBold",
    },
    "& .react-select__option:hover": {
        backgroundColor: "#fff",
        fontFamily: "Heebo-SemiBold",
        color: "black",
    },
    "& .react-select__option:hover .MuiButtonBase-root:hover": {
        backgroundColor: "transparent"
    },
    "& .react-select__option:active": {
        backgroundColor: "#fff",
        color: "black",
        fontFamily: "Heebo-SemiBold",
    },
    "& .react-select__multi-value": {
        borderRadius: "6px",
        color: "#fff",
        backgroundColor: "black",
        padding: "0px 2px",
    },
    "& .react-select__multi-value__label": {
        color: "#fff",
    }
});

const DoneButton = styled(Button)({
    marginLeft: "15px !important",
    width: "95px",
    textTransform: "none",
    fontFamily: "Heebo-Bold",
    color: "white !important",
    fontSize: "16px !important",
    padding: "12px 28px !important",
    backgroundColor: "black",
    borderRadius: "8px !important",
    "&:hover": {
      backgroundColor: "black",
    },
});

const CancelButton = styled(Button)({
    marginLeft: "15px !important",
    width: "95px",
    textTransform: "none",
    fontFamily: "Heebo-Bold",
    color: "black !important",
    fontSize: "16px !important",
    padding: "12px 28px !important",
    background: "#F0F0F0",
    borderRadius: "8px !important",
    "&:hover": {
      backgroundColor: "#F0F0F0",
    },
});


const CustomInputBase = styled(InputBase)({
    border: "1px solid #CBD5E1",
    borderRadius: "5px",
    fontFamily: "Heebo-Regular",
    backgroundColor: "#fff",
    marginTop: "3px",
    fontSize: "14px",
    "& input": {
      padding: "10px 8px",
      '&:placeholder': {
        fontWeight:500
      }
    },
    "& .MuiInputBase-inputMultiline": {
      fontSize: "14px",
      padding: "10px 8px",
    },
    
});

const Header = styled(Box)({
    backgroundColor: "#FFFFFF",
    "& .content": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& .block": {
            gap: 16,
            display: "flex",
            alignItems: "center"
        }
    }
})

const CustomCheckbox = styled(Checkbox)(() => ({
    '& .MuiSvgIcon-root': {
      width: '32px',
      height: '32px',
      backgroundColor: 'white',
      border: '1px solid #A8A29E',
      borderRadius: '4px',
    },
    '&:not(.Mui-checked) .MuiSvgIcon-root': {
      color: 'transparent',
    },
    '&.Mui-checked': {
      '& .MuiSvgIcon-root': {
        backgroundColor: 'black',
        borderColor: 'black',
      },
      '& .MuiSvgIcon-root path': {
        d: 'path("M5 12l5 5L20 7")',
        stroke: 'white',
        strokeWidth: 2,
        fill: 'none',
      },
    },
  }));

const SaveButton = styled(Button)({
    padding: "16px 32px 16px 32px",
    borderRadius: "4px",
    background: "#000000",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#FFFFFF",
    cursor: "pointer",
    textTransform: "none",
    "&:hover": {
        background: "#000000",
        color: "#FFFFFF",
    }
})

const CustomInputTitle = styled(InputBase)({
    borderRadius: "4px",
    fontFamily: "Crimson Text",
    fontSize: "40px",
    fontWeight: 400,
    lineHeight: "80px",
    width:'700px',
    "& ::placeholder": {
        fontFamily: "Crimson Text",
        fontSize: "40px",
        fontWeight: 400,
        lineHeight: "80px",
    }
});

const CustomInput = styled(InputBase)({
    padding: "10px 14px 10px 14px",
    background: "#FFFFFF",
    height: 48,
    border: "1px solid #9D9D9D",
    borderRadius: "4px",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    "& ::placeholder": {
        fontFamily: "Heebo",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
    }
});

const CourseRequirements = styled(Box)({
    width: 600,
    backgroundColor: "#FFFFFF",
    position: 'absolute',
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
});

const CourseRequirementsHeader = styled(DialogTitle)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 30px",
    borderBottom: "1px solid #E2E8F0",
    "& .title": {
        fontFamily: "Inter",
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "28px",
        padding: "10px 0",
    }
})

const CourseRequirementsContent = styled(Box)({
    margin: "16px 0",
    padding: "24px 40px 24px 40px",
    "& .dialog-content": {
        display: "flex",
        flexDirection: "column",
        gap: 16,
    },
    "& .label-title": {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: "700",
        lineHeight: "22px",
        color: "#334155",
        marginBottom: "4px"
    },
    "& .label-title-assessment":{
        fontFamily: "Heebo",
        fontSize: "18px",
        fontWeight: "400",
        lineHeight: "32px",
        color: "##1C1C1C",
        marginBottom: "4px"
    }
})

const CourseRequirementsFooter = styled(Box)({
    borderTop: "1px solid #E2E8F0",
    padding: "17px 23px 2px",
    display: "flex",
    justifyContent: "flex-end",
})

const baseButtonStyle = {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    padding: "6px 16px",
    color: "#1C1C1C",
};

const buttonStyles = {
    emptyButtonStyle: {
        ...baseButtonStyle,
        fontWeight: 700,
    },
    previewBlackButtonStyle: {
        ...baseButtonStyle,
        color: "#FFFFFF",
        backgroundColor: "#1C1C1C",
    },
    whiteButtonStyle: {
        ...baseButtonStyle,
        borderRadius: "4px",
        border: "1px solid #1C1C1C",
    },
    blackButtonStyle: {
        ...baseButtonStyle,
        fontFamily: "Heebo",
        fontWeight: 400,
        color: "#FFFFFF",
        padding: "6px 10px",
        backgroundColor: "#1C1C1C",
    },
    backButtonStyle: {
        ...baseButtonStyle,
        fontFamily: "Heebo",
        fontWeight: 400,
        fonstSize: "16px",
        lineHeight: "24px",
        gap: 8,
        "& img": {
            width: 24,
            height: 24,
        }
    }
};

const DefaultCustomButton = styled(Button)({
    textTransform: "none",
});

// Customizable Area End