import React from 'react';
import { TableRow, TableCell, Skeleton } from '@mui/material';

type TableSkeletonProps = {
  rowsNum: number; // Number of rows to display
  columnsNum: number; // Number of cells in each row
};

const TableSkeleton: React.FC<TableSkeletonProps> = ({ rowsNum, columnsNum }) => {
  return (
    <>
      {[...Array(rowsNum)].map((_, rowIndex) => (
        <TableRow key={rowIndex}>
          {[...Array(columnsNum)].map((_, colIndex) => (
            <TableCell key={colIndex}>
              <Skeleton animation="wave" variant="text" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default TableSkeleton;
