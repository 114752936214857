import React from "react";

// Customizable Area Start
import { AppBar, Tab, Tabs, Box, Button, Typography, Select,Card,CardContent,Rating,Divider, TextField, FormControl, MenuItem, IconButton, InputAdornment, Modal, Container, Grid } from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import Sidebar from "../../../../packages/components/src/sidebar/Sidebar.web";
import { KeyboardArrowDown, Search, Close, AddCircleOutline, DeleteOutline, MoreHoriz, ExpandMore } from '@mui/icons-material';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ReportsAnalytics from "./ReportsPage.web";
// Customizable Area End

import AnalyticsController, {
  Props,
  configJSON,
} from "./AnalyticsController.web";

export default class Analytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  UserAnalytics = () => {
    return <div>User Analytics Component</div>;
  };

  CourseProgressAnalytics = () => {
    return <div>Course Analytics</div>
  };

  UsageMetrics = () => {
    return <div>Usage Metrics Component</div>;
  };

  Reports = () => {
    const {reportModal}=this.state
    return (
    <div>
      <ReportsAnalytics navigation={reportModal} id={""}/>
    </div>);
  };

  FeedbackAggregation = () => {
    const { selectedCourse, courseData ,sortBy, difficulty, feedbackForms ,duration,filteredForms,feedbackId} = this.state;
    const feedbackData= selectedCourse.id !== "0" ? filteredForms : feedbackForms
    return (
      <Box width={"100%"}>
        <Box display={"flex"} gap={"40px"} alignItems={"center"} height={"60px"}>
          <Box style={{
            width: '75%', height: '100%'
          }}>
            <TextField
              variant="outlined"
              placeholder="Search all courses"
              style={{
                borderRadius: '4px',
                border: "#E7E7E7 1px solid",
                backgroundColor:"#fff"
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderRadius: '4px',
                  },
                },
              }}
              // value={""}
              data-testID="searchQuery"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Box>
          <Box style={{
            width: '25%', height: '100%'
          }}>
            <Button
              data-testID="feedbackModal"
              style={{
                fontFamily: 'Heebo', height: '100%',
                display: 'flex', textAlign: 'center', gap: '10px',
                alignItems: 'center', fontWeight: '700',
                padding: '10px 16px', borderRadius: '4px',
                fontSize: '16px', lineHeight: '24px',
                backgroundColor: '#000', color: '#fff',
                textTransform: 'none',
                width:"100%"
              }}
              onClick={this.handleReviewModalOpen}
            >
              <AddCircleOutline />
              <Typography
                style={{ fontFamily: 'Heebo', fontWeight: '700', fontSize: '14px', color: '#fff' }}
              >
                Create New Review
              </Typography>
            </Button>
          </Box>
        </Box>

        <Box sx={{ padding: 1 ,marginTop:2}}>

        <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 2}}>
        <Grid item xs={12} sm={6}>
           <FormControl>
              <Select
                labelId="client-select-label"
                id="client-select"
                placeholder="Select Course"
                value={selectedCourse.id}
                onChange={this.handleFilterSelectedCourse}
                IconComponent={ExpandMore}
                style={{minWidth:"235px",width:"100%",backgroundColor:"black",color:"#fff",fontWeight:"bold"}}
                renderValue={(selected) => (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span>{selectedCourse.name || 'Select Course'}</span>
                    <ExpandMore sx={{ color: 'white' }} />
                  </Box>
                )}
              >
                <MenuItem  value={"0"}>
                    All Courses
                  </MenuItem>
                {courseData?.map((course) => (
                  <MenuItem key={course.id} value={course.id}>
                    {course.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl>
              <Select
                value={duration}
                onChange={(e:any) => this.handlefilterChange(e,"duration")}
                name="duration"
                style={webStyle.filterSelect}
                IconComponent={ExpandMore}
              >
                <MenuItem value="Published">Published</MenuItem>
                <MenuItem value="Draft">Draft</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl>
              <Select
                value={sortBy}
                onChange={(e:any) => this.handlefilterChange(e,"sortBy")}
                name="sortBy"
                style={webStyle.filterSelect}
                IconComponent={ExpandMore}
              >
                <MenuItem value="Sort by name">Sort by name</MenuItem>
                <MenuItem value="Sort by rating">Sort by rating</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl>
              <Select
                value={difficulty}
                onChange={(e:any) => this.handlefilterChange(e,"difficulty")}
                name="difficulty"
                style={webStyle.filterSelect}
                IconComponent={ExpandMore}
              >
                <MenuItem value="Beginner">Beginner</MenuItem>
                <MenuItem value="Intermediate">Intermediate</MenuItem>
                <MenuItem value="Advanced">Advanced</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {feedbackData?.map((course:any) => {          
          return(
          <Card key={course?.id} sx={{ marginBottom: 2,borderColor:"lightgray",border:"0.2"}}>
           {feedbackData?.length !==0 ? (<CardContent>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={3}>               
                <Typography variant="h6" style={{whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis",fontWeight:"bold",color:"#000"}}>
                <IconButton style={{color:"#000",fontWeight:"bold"}}>
                <ExpandMore/>
                </IconButton>
                 {this.getTitleById(course?.course_id)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={5}>
                  
                  <Typography variant="body2" style={{color:"#000",fontWeight:"bold"}}>
                   {course?.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {course?.description}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} textAlign="right" style={{display:"flex",gap:2}}>
                  <Typography style={{color:"#000",fontWeight:"bold"}} variant="body2">Course rating</Typography>
                  <Rating value={course?.average_rating/2} precision={0.5} size="small" readOnly style={{color:"#64748B"}} />
                </Grid>
                <Grid item xs={12} sm={1} textAlign="right">
                <IconButton style={{color:"#000",fontWeight:"bold",padding:"5px"}} data-testID="iconButtonModal" onClick={()=>this.handleSummaryModalOpen(course.id)}>
                <MoreVertIcon/>
                </IconButton>
                </Grid> 
              </Grid>
            </CardContent>)
            :(<CardContent>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={12}>               
                <Typography variant="h6">
                Feedback form not found..
                </Typography>
                </Grid> 
              </Grid>
            </CardContent>
            )}
          </Card>
        )})}
      </Box>

      </Box>
    );
  };

  FeedbackReviewModal = () => {
    return (
      <Modal open={this.state.reviewModal} onClose={this.handleReviewModalClose}>
        <Container
          sx={{
            boxShadow: "0px 8px 32px 0px #0000000F",
            width: '640px',
            minWidth: '380px',
            padding: '24px',
            borderRadius: '8px 8px 32px 8px',
            backgroundColor: '#FFFFFF',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            height:"100%",
             overflow:"auto"
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" padding="10px 0" borderBottom="1px solid #E2E8F0">
            <Typography
              style={{
                fontFamily: 'Heebo',
                fontSize: '24px',
                letterSpacing: '0.5%',
                lineHeight: '32px',
                fontWeight: '700',
              }}>
              New Feedback Form
            </Typography>
            <IconButton style={{color:"#000",fontWeight:"bold"}} data-testID="close-Modal" onClick={this.handleReviewModalClose}>
              <Close />
            </IconButton>
          </Box>
          <Box sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            overflowY: 'auto',
            gap: '24px'
          }}>
            <FormControl fullWidth>
              <Typography
                style={{ fontFamily: 'Heebo', fontWeight: '700', fontSize: '14px', color: '#64748B' }}
              >Course</Typography>
              <Select
                labelId="client-select-label"
                id="client-select"
                placeholder="Select Course"
                value={this.state.selectedCourse.id}
                onChange={this.handleSelectedCourse}
                data-testID="clientSelect"
              >
                {this.state?.courseData?.map((course) => (
                  <MenuItem key={course.id} value={course.id}>
                    {course.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box>
              <Typography
                style={{ fontFamily: 'Heebo', fontWeight: '700', fontSize: '14px', color: '#334155' }}
              >Name of Feedback Form</Typography>
              <TextField placeholder="Builder's Feedback Form" data-testID="formName" fullWidth value={this.state.feedbackFormName} onChange={this.handleFeedbackFormName} />
            </Box>
            <Box>
              <Typography
                style={{ fontFamily: 'Heebo', fontWeight: '700', fontSize: '14px', color: '#334155' }}
              >Description</Typography>
              <TextField placeholder="Description" data-testID="formName" fullWidth value={this.state.feedbackDescription} onChange={this.handleFeedbackFormDescription} />
            </Box>
            <Box>
              <Button data-testID="addQuestion" style={{ padding: "0px", display: 'flex', gap: '6px', textTransform: 'none' }} onClick={this.handleAddQuestion}>
                <AddCircleOutline />
                <Typography
                  style={{ fontFamily: 'Heebo', fontWeight: '700', fontSize: '14px', color: '#1C1C1C' }}
                >
                  Add question
                </Typography>
              </Button>
            </Box>

            <Box>
              {this.state.questions.map((question, index) => (
                <Box key={index} display={"flex"} flexDirection={"column"}
                  justifyContent={"space-between"} gap={"24px"} padding={"10px 0px"}>
                  <Typography
                    style={{ fontFamily: 'Heebo', fontWeight: '700', fontSize: '14px', color: '#334155' }}
                  >Question</Typography>
                  <TextField
                    placeholder="Ease of use"
                    data-testID="question"
                    fullWidth
                    value={question.question}
                    onChange={(e) => this.handleQuestionChange(index, 'question', e.target.value)}
                  />
                  <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Box display={"flex"} alignItems="center" gap={"10px"}>
                      <Typography
                        style={{ fontFamily: 'Heebo', fontWeight: '700', fontSize: '14px', color: '#334155' }}
                      >Required: </Typography>
                      <Select
                        style={{ border: '1px solid #1C1C1C' }}
                        value={question.required ? 'Yes' : 'No'}
                        onChange={(e) => this.handleQuestionChange(index, 'required', e.target.value === 'Yes')}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                    </Box>
                    <Box display={"flex"} alignItems="center" gap={"10px"}>
                      <Typography
                        style={{
                          fontFamily: 'Heebo', fontWeight: '700', fontSize: '14px', color: '#334155'
                        }}>Type: </Typography>
                      <Select
                        style={{ border: '1px solid #1C1C1C' }}
                        value={question.type}
                        onChange={(e) => this.handleQuestionChange(index, 'type', e.target.value)}
                      >
                        <MenuItem value="free-text">Free text</MenuItem>
                        <MenuItem value="rating">Numeric 1-10</MenuItem>
                      </Select>
                    </Box>
                    <Box>
                      <IconButton data-testID={`delete${index}`} disabled={index == 0} onClick={() => this.handleDeleteQuestion(index)}>
                        <DeleteOutline style={{ color: 'red' }} />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>

          <Box display={"flex"} gap={"24px"} paddingTop={"10px"} justifyContent={"end"} borderTop="1px solid #E2E8F0">
            <Button
              style={{
                backgroundColor: '#F0F0F0',
                color: '#1C1C1C',
                padding: '16px 32px',
                borderRadius: '8px',
                textTransform: 'none', fontFamily: 'Heebo', fontWeight: '700', fontSize: '16px',
              }}
              data-testID="closeModal"
              onClick={this.handleReviewModalClose}>Cancel</Button>
            <Button style={{
              backgroundColor: '#1C1C1C',
              color: 'white',
              textTransform: 'none',
              padding: '16px 32px',
              borderRadius: '8px', fontFamily: 'Heebo', fontWeight: '700', fontSize: '16px',
            }}
            data-testID="saveBtn"
              onClick={this.handleSave}
            >
              Save
            </Button>
          </Box>
        </Container>
      </Modal>
    )
  }

  FeedbackSummaryModal = () => {
    const { summaryModal, feedback, reviewSummary } = this.state;
    return (
      <Modal open={summaryModal} onClose={this.handleSummaryModalClose}>
      <Container
        sx={{
          boxShadow: "0px 8px 32px 0px #0000000F",
          width: "600px",
          minWidth: "380px",
          padding: "24px",
          borderRadius: "8px",
          backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          height:"100%",
          overflow:"auto"
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid #E2E8F0"
          paddingBottom="10px"
        >
          <Typography
            sx={{
              fontFamily: "Heebo",
              fontSize: "20px",
              fontWeight: "700",
              letterSpacing: "0.5%",
            }}
          >
            Sarah Smith`s Review
          </Typography>
          <IconButton style={{color:"#000",fontWeight:"bold"}} onClick={this.handleSummaryModalClose}>
            <Close />
          </IconButton>
        </Box>

        <Box>
          {feedback.map((item :any , index :any) => (
            <Box key={index} mb={2}>
              <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={10}>
              <Typography
                sx={{
                  fontFamily: "Heebo",
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#000",
                }}
              >
                {item.question}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Heebo",
                  fontSize: "14px",
                  color: "#64748B",
                  marginBottom: "8px",
                }}
              >
                {index === 0
                  ? "How easy is it to navigate through the platform and find the lessons you are looking for?"
                  : index === 1
                  ? "Did the search bar help you quickly locate specific video lessons or topics?"
                  : index === 2
                  ? "Do you find the platform’s layout intuitive and user-friendly? If not, what improvements would you suggest?"
                  : "Are the video lessons clear and easy to understand?"}
              </Typography>
              </Grid>
              <Grid item xs={12} sm={2} style={{textAlign:"right"}}>
              <Typography
                sx={{
                  fontFamily: "Heebo",
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#1C1C1C",
                }}
              >
                {item.rating}
              </Typography>
              </Grid>
              </Grid>
            </Box>
          ))}
        </Box>

        <Box>
          <Typography
            sx={{
              fontFamily: "Heebo",
              fontWeight: "700",
              fontSize: "16px",
              color: "#334155",
              marginBottom: "8px",
            }}
          >
            Sarah Smith`s Review
          </Typography>
          <Typography
            sx={{
              fontFamily: "Heebo",
              fontSize: "14px",
              color: "#64748B",
            }}
          >
            {reviewSummary}
          </Typography>
        </Box>

        <Box display="flex" justifyContent="end" borderTop="1px solid #E2E8F0" pt={2}>
        <Button
          style={{ fontFamily: 'Heebo', fontWeight: '700', padding: '10px 16px', borderRadius: '4px', fontSize: '16px', lineHeight: '24px', backgroundColor: '#000', color: '#fff', textTransform: 'none',width:"100px" }}
          onClick={this.handleSummaryModalClose}
          >
            Done
          </Button>
        </Box>
      </Container>
    </Modal>
    )
  }


  tabConfigurations = [
    { label: 'User Analytics', },
    { label: 'Course Progress Analytics', },
    { label: 'Usage Metrics',},
    { label: 'Feedback Aggregation',  },
    { label: 'Reports',  },
  ];

  SelectedComponent = () => {
    switch (this.state.value) {
      case 0: return (
        <>
          {this.UserAnalytics()}
        </>
      )
      case 1: return (
          <>
            {this.CourseProgressAnalytics()}
          </>
        )
      case 2:
        return (
          <>
            {this.UsageMetrics()}
          </>
        )
      case 3:
        return (
          <>
            {this.FeedbackAggregation()}
          </>
        )
      case 4:
        return (
          <>
            {this.Reports()}
          </>
        )
    }
  };

  // Customizable Area End

  render() {
  // Customizable Area Start
  // Customizable Area End
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Box display={"flex"} overflow={'hidden'}>
            <Box>
              <Sidebar navigation={this.props.navigation} current="analytics" />
            </Box>
            <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={"16px"} padding={"24px 18px"}  sx={{backgroundColor:'#F0F0F0', overflow:'auto'}}>
              <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} minHeight={"90px"}>
                <Box display={"flex"} gap="10px" alignItems={"center"}>
                  <Typography
                    style={{ fontFamily: 'Heebo', fontWeight: '700', fontSize: '24px', lineHeight: '32px', color: '#1C1C1C' }}
                  >Analytics</Typography>
                  <>
                    <Typography
                      style={{ fontFamily: 'Heebo', fontWeight: '700', fontSize: '16px', lineHeight: '24px', color: '#475569' }}
                    >Jul 30 - Aug 5</Typography>
                    <KeyboardArrowDown />
                  </>
                </Box>

               {this.state.value == 4 ? 
               <Box display={"flex"} justifyContent={"space-between"} gap={"8px"}> 
               <Button
                onClick={()=>{this.handleCreateReport("Add")}}
                 style={{ fontFamily: 'Heebo', fontWeight: '700', padding: '10px 16px', borderRadius: '4px', fontSize: '16px', lineHeight: '24px', backgroundColor: '#000', color: '#fff', textTransform: 'none' }}>
                 <AddCircleOutline />
               <Typography
                 style={{ fontFamily: 'Heebo', fontWeight: '700', fontSize: '14px', color: '#fff' }}
               >
                 Create New Report
               </Typography></Button>
             </Box>
               :
               <Box display={"flex"} justifyContent={"space-between"} gap={"8px"}>
                  <Button endIcon={<KeyboardArrowDown />}
                    style={{ fontFamily: 'Heebo', fontWeight: '700', padding: '10px 16px', borderRadius: '4px', fontSize: '16px', lineHeight: '24px', backgroundColor: '#fff', color: '#000', textTransform: 'none' }}
                    variant="outlined"
                  >Generate report</Button>
                  <Button
                    style={{ fontFamily: 'Heebo', fontWeight: '700', padding: '10px 16px', borderRadius: '4px', fontSize: '16px', lineHeight: '24px', backgroundColor: '#000', color: '#fff', textTransform: 'none' }}
                  >Export data</Button>
                  <IconButton style={{color:"#000",fontWeight:"bold"}}><MoreHoriz /></IconButton>
               </Box>}

              </Box>
              <Box width={"fit-content"}>
                <AppBar position="static" style={{ width: "100%" }} color="secondary">
                  <Tabs
                    value={this.state.value}
                    onChange={this.handleChange}
                    data-testID="Tabs"
                    aria-label="analytics tabs"
                    textColor="secondary"
                    style={{ gap: '10px' }}
                    TabIndicatorProps={{
                      style: {
                        display: 'none'
                      },
                    }}
                  >
                    {this.tabConfigurations.map((tab, index) => (
                      <Tab
                        key={index}
                        label={
                          <Typography
                            sx={{
                              fontFamily: 'Heebo-bold',
                              fontSize: '16px',
                              fontWeight: '700',
                              lineHeight: '24px',
                              padding: '12px 16px',
                              textTransform: 'none'
                            }}
                          >
                            {tab.label}
                          </Typography>
                        }
                        sx={{
                          backgroundColor: this.state.value === index ? 'black' : 'inherit',
                          color: this.state.value === index ? 'white' : 'black',
                          borderRadius: '4px',
                          '&.Mui-selected': {
                            backgroundColor: 'black',
                            color: 'white',
                            borderBottom: 'none',
                          },
                        }}
                      />
                    ))}
                  </Tabs>
                </AppBar>
              </Box>
              <Box paddingTop={"50px"}>
                {this.SelectedComponent()}
              </Box>
            </Box>
          </Box>
          {this.FeedbackReviewModal()}
          {this.FeedbackSummaryModal()}
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: '#fff'
    }
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  filterSelect:{
    width:"100%",
    minWidth:"175px",
    backgroundColor:"#fff",
    color:"#000",
    fontWeight:"bold"
  }
};
// Customizable Area End
