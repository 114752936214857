import React from 'react';

// Customizable Area Start
import {
  Box,
} from '@mui/material';
import { ThinkSpike } from './assets';
// Customizable Area End

import CertificateController, {
  Props,
} from './CertificateController';


export default class ManageClient extends CertificateController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    
    
    return (
        <>
            <Box sx={{ padding: '1rem' }}>
                <Box sx={{  border: '2px solid black', padding: '10px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems:'start'}}>
                        <img
                            src={ThinkSpike}
                            alt="ThinkSpike Logo"
                            style={{
                                maxWidth: '150px', 
                                maxHeight: '150px',
                                width: 'auto',
                                height: 'auto',
                                objectFit: 'contain',
                            }}
                        />
                        <img
                            src={this.state.imageLink}
                            alt="Sample Image"
                            style={{
                                maxWidth: '100px', 
                                maxHeight: '100px',
                                width: 'auto',
                                height: 'auto',
                                objectFit: 'contain',
                            }}
                        />
                    </Box>

                    <Box>
                        <h1 style={{ textAlign: 'center', fontSize: '35px' }}>Certificate of Completion</h1>
                        <h3 style={{ textAlign: 'center' }}>This is to certify that</h3>
                        <h2 style={{ textAlign: 'center' }}>{this.state.userName}</h2>
                        <h3 style={{ textAlign: 'center' }}>has successfully completed the course</h3>
                        <h2 style={{ textAlign: 'center' }}>{this.state.courseName}</h2>
                        <h3 style={{ textAlign: 'center' }}>
                            Date of Completion: <strong>{new Date().toLocaleDateString()}</strong>
                        </h3>
                    </Box>
                </Box>
            </Box>

        </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start


// Customizable Area End
